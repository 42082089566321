import { Body } from '../../../components/container/Body';
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default';
import { Input } from '../../../components/input/input';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaInfoCircle, FaSearch, FaCheckCircle, FaTrash, FaEdit, FaEye } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { InputCheckBox } from '../../../components/input/input.checkBox';
import { NewTableDefault } from '../../../components/table/new.table.default';
import { FinancialBillsByConciliation } from "./financialBillsByConciliation";
import { SelectPeriod } from '../../../components/period/selectPeriod';
import { useApiContext } from '../../../contexts/ApiInterceptorContext';

export function FinancialExistingBillsByConciliation({ transaction, setShow, setTransaction, setShowModalEdit, setDataSendEdit, loadingEdit}) {

    const navigate = useNavigate()
    const { api_financial } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()     
    const [ loading, setLoading ] = useState(false)
    const [ selectedOption, setSelectedOption ] = useState(1)
    const [ newTransaction, setNewTransaction ] = useState({
        ...transaction,
        valueBeforeDiff: transaction?.value
    })
    const [ selectedSends, setSelectedSends ] = useState([])
    const [ dataIntegrations, setDataIntegrations ] = useState({
        totalValue: 0,
        linkeds: []
    })
    const [ loadingIntegrations, setLoadingIntegrations ] = useState(false)
    const [ showCreateComplete, setShowCreateComplete ] = useState(false)
    const [ filter, setFilter ] = useState({
        startDate: newTransaction?.startDate,
        finishDate: newTransaction?.finishDate,
        incomeOrExpense: newTransaction?.incomeOrExpense,
        paymentStatus: 0,
        payments_id_isNull: true
    })
    const [ searchPeriod, setSearchPeriod ] = useState(false)
    const [ selectedStartDate, setSelectedStartDate ] = useState(newTransaction?.startDate)
    const [ selectedFinishDate, setSelectedFinishDate ] = useState(newTransaction?.finishDate)
    const [ valueTransactionAndIntegration, setValueTransactionAndIntegration ] = useState(0)
    const [ totalSelected, setTotalSelected ] = useState(0)
    const [ diffValues, setDiffValues ] = useState(0)
    const [ showCreateDiff, setShowCreateDiff ] = useState(false)

    useEffect(() => {
        newTransaction.bill = undefined
        newTransaction.byGateway = undefined
        setNewTransaction({...newTransaction})
    }, [selectedOption])

    useEffect(() => {
        const total = selectedSends?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue?.finalValue), 0)
        setTotalSelected(total)
        setDiffValues(Number(Number(newTransaction?.valueBeforeDiff) - Number(total)).toFixed(2))
        newTransaction.bill = undefined
        newTransaction.valueOriginal = newTransaction?.valueBeforeDiff
        newTransaction.value = newTransaction?.valueBeforeDiff
        setNewTransaction({...newTransaction})
    }, [selectedSends])

    async function getDataIntegrations(){
        try {
            setLoadingIntegrations(true)

            let params = []
            params.push(`startDate=${selectedStartDate}`)
            params.push(`finishDate=${selectedFinishDate}`)
            newTransaction?.incomeOrExpense && params.push(`incomeOrExpense=${newTransaction?.incomeOrExpense}`)
            params = params.join('&')

            const getDataAPI = await api_financial.get(`/api/v1/financial/cashFlow/integrationSumsPerPeriod?${params}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setDataIntegrations(getDataAPI?.data?.data)
            setValueTransactionAndIntegration(Number(newTransaction?.value) - Number(getDataAPI?.data?.data?.totalValue))
            setSearchPeriod(true)

        } catch(error){
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        } finally {
            setLoadingIntegrations(false)
        }
    }

    function handleApply(){
        if (newTransaction?.bill && !newTransaction.isDiff) {
            newTransaction.gateway_startDate = selectedStartDate
            newTransaction.gateway_finishDate = selectedFinishDate
            newTransaction.byGateway = true
            newTransaction.gateway_quantity = dataIntegrations.linkeds?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue?.quantity), 0)
    
            setTransaction(newTransaction)
            setShow(false)
        } else {
            setTransaction({
                ...newTransaction,
                totalValue: newTransaction?.value,
                value: newTransaction?.valueBeforeDiff,
                selectedSends
            })
            setShow(false)
        }
    }

    function setAndSaveFilter(filterObj){
        setFilter(filterObj)
    }

    return (
        <>
            {
                showCreateComplete ?
                <FinancialBillsByConciliation
                    transaction={{
                        ...newTransaction,
                        incomeOrExpense: valueTransactionAndIntegration < 0 ? 'D' : 'R',
                        valueOriginal: newTransaction?.value,
                        value: valueTransactionAndIntegration
                    }} 
                    setShow={setShowCreateComplete} 
                    setTransaction={setNewTransaction}
                    key={'create-complete'}
                />
                :
                <>
                    <Body hasFooter={true}>
                        {
                            loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className='flex flex-row items-center justify-start w-full gap-3'>
                                    <div className='flex flex-row items-center justify-center gap-1 cursor-pointer' onClick={() => setSelectedOption(1)}>
                                        <InputCheckBox
                                            onClick={() => {}}
                                            value={selectedOption == 1 ? true : false}
                                            id={'selected_one'}                                
                                        />
                                        <p className='text-primaryDefaultLight select-none'>Lançamentos</p>
                                    </div>
                                    {
                                        newTransaction?.incomeOrExpense == 'R' &&
                                        <div className='flex flex-row items-center justify-center gap-1 cursor-pointer' onClick={() => setSelectedOption(2)}>
                                            <InputCheckBox
                                                onClick={() => {}}
                                                value={selectedOption == 2 ? true : false}
                                                id={'selected_two'}
                                            />
                                            <p className='text-primaryDefaultLight select-none'>Integração Gateway (Reservas/Inscrições)</p>
                                        </div>
                                    }
                                </div>
                                {
                                    selectedOption == 1 ?
                                    <>
                                        <div className="flex flex-col text-sm mb-2 text-zinc-500 dark:text-titleGrayTextDark w-full">                                    
                                            <p><b>Valor:</b> {newTransaction?.valueBeforeDiff?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                            <p><b>Descrição:</b> {newTransaction?.description}</p>
                                            <p><b>Valor Selecionados:</b> {totalSelected?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                            <div className='flex flex-row items-center gap-2'>
                                                <b>Diferença: </b>
                                                {
                                                    diffValues != 0 ?
                                                    <div className='flex flex-row gap-4'>
                                                        <span className={`text-red-500`}>
                                                            {Number(diffValues)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                        </span>                                                        
                                                    </div>
                                                    :                                                    
                                                    <span className={`text-green-500 flex flex-row gap-1 items-center w-full`}>
                                                        {Number(diffValues)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                        <FaCheckCircle className='text-xs'/>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <hr className='border w-full'/>
                                        <div className='w-full flex flex-col gap-4'>
                                            <div className='w-full flex items-start justify-start'>
                                                <SelectPeriod
                                                    referenceDate={filter?.startDate}
                                                    onChange={(e) => {
                                                        setAndSaveFilter({ ...filter, ...e })
                                                    }}
                                                />
                                            </div>
                                            {
                                                loadingEdit ?
                                                <p className='w-full flex items-center justify-center animate-pulse text-primaryDefaultLight'>Carregando...</p>
                                                :
                                                <NewTableDefault
                                                    key={'table-cashFlow2'}
                                                    endpoint="/api/v1/financial/cashFlow"
                                                    token={userData[0]?.token}
                                                    module="financial"
                                                    onSelect={(e) => {
                                                        setSelectedSends(e)
                                                    }}
                                                    withSelection={true}
                                                    disabledSelection={showCreateDiff}
                                                    columns={[
                                                        {
                                                            description: newTransaction?.incomeOrExpense == 'R' ? 'Cliente' : 'Fornecedor',
                                                            type: 'string',
                                                            newFieldValue: function (line) {
                                                                let name = ''
                                                                if(line?.financialClientsAndSuppliers_id){
                                                                    name = line?.financialClientsAndSuppliers_name
                                                                } 
                                                                if(line?.client_users_id) {
                                                                    name = line?.client_users_name
                                                                }
                                                                return screenX <= 750 
                                                                    ? name.length > 20 ? name?.substring(0, 20) + '...' : name
                                                                    : name
                                                            },
                                                        },
                                                        {
                                                            description: 'Valor',
                                                            field: 'finalValue',
                                                            type: 'money'
                                                        },
                                                        {
                                                            description: 'Parcela',
                                                            type: 'string',
                                                            newFieldValue: function (line) {
                                                                if(line?.paymentCondition == 1){
                                                                    return 'À vista'
                                                                }
                                                                return `${line?.installment} de ${line?.numberOfInstallments}`
                                                            }
                                                        },
                                                        {
                                                            description: 'Data de vencimento',
                                                            field: 'dueDate',
                                                            type: 'date'
                                                        },
                                                        {
                                                            description: 'Tipo',
                                                            type: 'string',
                                                            newFieldValue: function (line) {
                                                                return line.entryType == 'sales' ? 'Venda'
                                                                    : line.entryType == 'shopping' ? 'Compra'
                                                                    : 'Avulso'
                                                            }
                                                        },
                                                        {
                                                            description: 'Status',
                                                            type: 'string',
                                                            newFieldValue: function (line) {
                                                                if(line?.paymentStatus == 0){
                                                                    return <a className="text-yellow-500 text-sm">Pendente</a>
                                                                }
                                                                if (line?.paymentStatus == 1){
                                                                    return <a className="text-green-700 text-sm">Pago</a>
                                                                }
                                                                return <a className="text-red-700 text-sm">Cancelado</a>
                                                            }
                                                        },
                                                        {
                                                            description: 'Origem',
                                                            type: 'string',
                                                            newFieldValue: function (line) {
                                                                return line?.payments_id 
                                                                    ? `Integração - ${line.applicationModules_name}`
                                                                    : 'Manual'
                                                            }
                                                        },
                                                        {
                                                            description: 'Ações',
                                                            field: 'actions',
                                                            newFieldValue: item => {
                                                                return <div className='w-full flex flex-row items-center justify-center gap-3'>                                                                    
                                                                    <Tippy content={'Editar'}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div className='cursor-pointer'>
                                                                            <FaEdit
                                                                                className='text-sm text-blue-700' 
                                                                                onClick={() => {setDataSendEdit(item); setShowModalEdit(true); setShowUniversalModal(true)}}
                                                                            />
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                            }
                                                        }
                                                    ]}
                                                    filters={filter}
                                                    mapApiData={['data', 'sends']}
                                                    usePagination={true}
                                                    mapQuantityOfRegisters={['quantity']}
                                                    mapTotalPages={['totalPages']}
                                                />
                                            }
                                        </div>
                                        {
                                            newTransaction?.bill && !showCreateDiff &&
                                            <div className='flex flex-row items-center justify-center w-full font-semibold text-green-500 gap-3 mt-2 animate-[wiggle_.8s_ease-in-out]'>
                                                <p>Lançamento com a diferença criado</p>
                                                <FaCheckCircle className='text-base'/>
                                            </div>
                                        }
                                        {
                                            showCreateDiff &&
                                            <FinancialBillsByConciliation
                                                transaction={{
                                                    ...newTransaction,
                                                    incomeOrExpense: 
                                                    newTransaction?.incomeOrExpense == 'R' && diffValues > 0 ? 'R' 
                                                    : newTransaction?.incomeOrExpense == 'R' && diffValues < 0 ? 'D'
                                                    : newTransaction?.incomeOrExpense == 'D' && diffValues > 0 ? 'D'
                                                    : newTransaction?.incomeOrExpense == 'D' && diffValues < 0 && 'R',
                                                    incomeOrExpenseHistory: newTransaction?.incomeOrExpense,
                                                    valueOriginal: Math.abs(diffValues),
                                                    value: Math.abs(diffValues),
                                                    valueBeforeDiff: newTransaction?.value                                                    
                                                }}
                                                setShow={setShowCreateDiff} 
                                                setTransaction={setNewTransaction}
                                                key={'create-diff'}
                                                diffValues={Number(diffValues)}
                                                setDiffValues={setDiffValues}
                                                withoutBody
                                            />
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            loadingIntegrations ?
                                            <div className='w-full flex items-center justify-center animate-pulse text-primaryDefaultLight'>
                                                <p>Buscando valores...</p>
                                            </div>
                                            :
                                            <>
                                                <div className="flex flex-col text-sm text-zinc-500 dark:text-titleGrayTextDark w-full">
                                                    {
                                                        !searchPeriod &&
                                                        <p>Selecione um período</p>
                                                    }
                                                    <div className='flex flex-row items-center gap-2 mt-1'>
                                                        <LabelInput>
                                                            <Input
                                                                type={'date'}
                                                                value={selectedStartDate ? selectedStartDate : ''}
                                                                onChange={(e) => setSelectedStartDate(e?.target?.value)}
                                                                id={'selectedStartDate'}
                                                                width={32}
                                                                height={7}
                                                            />
                                                        </LabelInput>
                                                        <p>até</p>
                                                        <LabelInput>
                                                            <Input
                                                                type={'date'}
                                                                value={selectedFinishDate ? selectedFinishDate : ''}
                                                                onChange={(e) => setSelectedFinishDate(e?.target?.value)}
                                                                id={'selectedFinishDate'}
                                                                width={32}
                                                                height={7}
                                                            />
                                                        </LabelInput>
                                                        <FaSearch 
                                                            className='text-blue-500 cursor-pointer'
                                                            onClick={() => getDataIntegrations()}
                                                        />
                                                    </div>
                                                    <p className='mt-2'><b>Valor transação:</b> {newTransaction?.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                                <hr className='border w-full'/>
                                                {
                                                    searchPeriod ?
                                                    <>
                                                        <div className='mx-2 flex flex-row items-center justify-start w-full gap-4'>
                                                            {
                                                                dataIntegrations?.linkeds?.map(link => (
                                                                    <div className='flex flex-col w-44 h-24 rounded-md shadow-md border border-gray-300//50 p-2 justify-between'>
                                                                        <p className='text-center w-full text-primaryDefaultLight'>{link?.linked}</p>
                                                                        <div className='flex flex-col items-start justify-center gap-1 text-sm text-zinc-500'>
                                                                            <p><b>Quantidade:</b> {link?.quantity}</p>
                                                                            <p><b>Valor:</b> {link?.value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <p className='text-primaryDefaultLight w-full items-start'>Valor integrações: {dataIntegrations.totalValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                        {
                                                            valueTransactionAndIntegration != 0 && !newTransaction?.bill ?
                                                            <>
                                                                <p className='text-red-500 w-full text-start'>
                                                                    Os valores não correspondem, há uma diferença de {
                                                                        valueTransactionAndIntegration?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                    }
                                                                </p>
                                                                <div className='flex flex-row items-center justify-start w-full'>
                                                                    {
                                                                        valueTransactionAndIntegration < 0 ?
                                                                        <Button onClick={() => setShowCreateComplete(true)}>Criar uma despesa com o valor da diferença</Button>
                                                                        :
                                                                        <Button onClick={() => setShowCreateComplete(true)}>Criar uma receita com o valor da diferença</Button>
                                                                    }
                                                                </div>
                                                            </>
                                                            : newTransaction?.bill ?
                                                            <div className='flex flex-row w-full items-center justify-start gap-2'>
                                                                <p className='flex flex-row items-center justify-center gap-2'>
                                                                    <FaCheckCircle className='text-green-500'/>
                                                                    Lançamento com a diferença criado
                                                                </p>
                                                                <FaTrash 
                                                                    className="text-red-500 text-sm cursor-pointer hover:brightness-90 duration-75"
                                                                    onClick={() => {
                                                                        newTransaction.bill = undefined
                                                                        setNewTransaction({...newTransaction})
                                                                    }}
                                                                />
                                                            </div>
                                                            :
                                                            <p className='text-green-500 w-full text-center'>Os valores correspondem, aplique os lançamentos para conciliá-los</p>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                                }                                        
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Body>
                    {
                        !showCreateDiff &&
                        <Footer>
                            <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                                <Button shadow={true} approval={false} onClick={() => setShow(false)}>Cancelar</Button>
                                {
                                    selectedOption == 2 && (valueTransactionAndIntegration == 0 || !newTransaction?.bill) ?
                                    <div className="text-primaryDefaultLight flex items-center gap-2">
                                        <p>Não é possível aplicar</p>
                                        <Tippy 
                                            content={'Valores estão diferentes, não é possivel aplicar os lançamentos'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <FaInfoCircle className="cursor-help"/>
                                            </div>
                                        </Tippy>
                                    </div>
                                    : diffValues != 0 ?
                                    <div className='flex flex-row gap-4'>
                                        <Tippy 
                                            content={'Há diferença nos valores, não é possivel aplicar'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <Button shadow={true} disabled approval={true} onClick={() => {}} cursorNotAllowed={true}>Aplicar</Button>
                                            </div>
                                        </Tippy>                                        
                                        <Button onClick={() => setShowCreateDiff(true)}>Criar lançamento (diferença)</Button>
                                    </div>
                                    :
                                    <div className='flex flex-row gap-4'>
                                        <Button shadow={true} approval={true} onClick={() => handleApply()}>Aplicar</Button>
                                        <Button disabled cursorNotAllowed={true} onClick={() => {}}>Criar lançamento (diferença)</Button>
                                    </div>
                                }
                            </div>
                        </Footer>
                    }
                </>
            }
        </>
    )

}