import { responseError } from "../../../utils/responsesFunctions/error.response"
import { formattTimeToShow } from "../callAPIsFunctions/defaultCalls.api"
import { maskHours } from "../../../utils/mask/maskHours"
import moment from "moment/moment"

export class DefaultCallsSchedules {

    #api

    constructor(api, token) {
        this.#api = api
    }

    // get recurrence schedules by identifier
    async getRecurrenceSchedulesByIdentifier(tokenKey, recurrenceIdentifier) {
        try {

            const apiData = await this.#api.get(`/api/v1/schedules/recurrence/${recurrenceIdentifier}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData?.data?.data?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))?.map(s => {
                    return {
                        ...s,
                        startHourFormated: maskHours({ hour: s.startHour }),
                        finishHourFormated: maskHours({ hour: s.finishHour }),
                        startDateFormated: moment(s.startDate).format('DD/MM/YYYY'),
                        finishDateFormated: moment(s.finishDate).format('DD/MM/YYYY'),
                        wasItDone: moment(s.startDate).isSameOrBefore(moment()) ? 'Sim' : 'Não'
                    }
                })
            }

        } catch (error) {
            console.log('aquiiii', error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getCustomersAttendance
    async getCustomersAttendance(tokenKey) {

        const ccustomersAttendance = await this.#api.get(`/api/v1/customersAttendance`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: ccustomersAttendance.data.data
        }
    }

     //getLogs
    async getLogs(
        tokenKey,
        page,
        table_name,
        table_id,
        requestId,
        users_id,
        created_at,
        startDate,
        finishDate,
        orderBy,
        direction
    ) {

        let params = `/api/v1/logs?`

        table_name ? params = params.concat(`table_name=${table_name}`) : params
        page ? params = params.concat(`&page=${page}`) : params
        table_id ? params = params.concat(`&table_id=${table_id}`) : params
        requestId ? params = params.concat(`&requestId=${requestId}`) : params
        users_id ? params = params.concat(`&users_id=${users_id}`) : params
        created_at ? params = params.concat(`&created_at=${created_at}`) : params
        startDate ? params = params.concat(`&startDate=${startDate}`) : params
        finishDate ? params = params.concat(`&finishDate=${finishDate}`) : params
        orderBy ? params = params.concat(`&orderBy=${orderBy}`) : params
        direction ? params = params.concat(`&direction=${direction}`) : params

        try {
            const schedulesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                }
            })

            const formattedLogs = schedulesData.data.data.map(log => {
                return {
                    ...log,
                    created_at: formattTimeToShow(log.created_at)
                }
            })

            let formatted = {
                totalPages: schedulesData.data.totalPages,
                data: formattedLogs
            }

            return {
                error: false,
                data: formatted
            }
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getCustomersAttendance(tokenKey) {
        const ccustomersAttendance = await this.#api.get(`/api/v1/customersAttendance`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return ccustomersAttendance.data
    }

    //getServices
    async getServices(tokenKey, active) {

        let endpoint = active ? `/api/v1/services?active=${active}` : `/api/v1/services`
        const servicesData = await this.#api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: servicesData.data.data
        }
    }

    //getServicesByCategoriesId
    async getServicesByCategoriesId(tokenKey, categoriesId) {

        let endpoint = `/api/v1/services/categories_id/${categoriesId}?active=1`
        const servicesData = await this.#api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }
    }

    //getServices
    async getServicesPagination(tokenKey, filter, page, direction, active) {

        let params = active ? `/api/v1/services?active=${active}&direction=${direction ? 'asc' : 'desc'}` : `/api/v1/services?active=false&direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }

        try {
            const servicesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: servicesData.data
            }


        } catch (error) {
            return error
        }
    }

    //getServicesEspecific
    async getServicesEspecific(tokenKey, id) {

        const servicesData = await this.#api.get(`/api/v1/services/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }
    }

    //getSchedulesBlocked
    async getSchedulesBlocked(tokenKey, serviceId, filter, page, direction, active) {

        let params = `api/v1/schedulesBlock/${serviceId}?direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const scheduleBlocked = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            scheduleBlocked.data.data.data.map((blocked, index) => {
                blocked.created_at = formattTimeToShow(blocked.created_at)
                blocked.startDate = `${formattTimeToShow(blocked.startDate)} - ${maskHours({ hour: blocked.startHour })}`
                blocked.finishDate = `${formattTimeToShow(blocked.finishDate)} - ${maskHours({ hour: blocked.finishHour })} `
            })
            return {
                error: false,
                data: scheduleBlocked.data.data
            }
        } catch (error) {
            return error
        }
    }

    //getSchedulesForAnotherPerson
    async getSchedulesForAnotherPerson(tokenKey, day, serviceId, userId) {

        const servicesData = await this.#api.get(`/api/v1/schedules/availableHours/${day}/services_id/${serviceId}/schedule_users_id/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }

    }

    //getFilteredSchedules
    async getFilteredSchedules(tokenKey, filter, page, direction, orderBy) {

        let params = `/api/v1/schedules/?direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })

            if (orderBy) {
                params = params.concat(`&orderBy=${orderBy}`)
            }
        }
        try {
            const schedulesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            schedulesData.data.data.map((apiData, index) => {
                schedulesData.data.data[index].original_created_at = apiData.created_at
                schedulesData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
                schedulesData.data.data[index].original_startDate = apiData.startDate
                schedulesData.data.data[index].startDate = formattTimeToShow(apiData.startDate)
                schedulesData.data.data[index].original_finishDate = apiData.finishDate
                schedulesData.data.data[index].finishDate = formattTimeToShow(apiData.finishDate)
                schedulesData.data.data[index].original_startHour = apiData.startHour
                schedulesData.data.data[index].startHour = maskHours({ hour: apiData.startHour })
                schedulesData.data.data[index].original_finishHour = apiData.finishHour
                schedulesData.data.data[index].finishHour = maskHours({ hour: apiData.finishHour })
            })
            let newData = { data: schedulesData.data.data, totalPages: schedulesData.data.totalPages }
            return {
                error: false,
                data: newData
            }
        } catch (error) {
            console.log(error.response)
            return error
        }
    }

    //getSchedulesHours
    async getSchedulesHours(tokenKey, day, serviceId) {

        const servicesData = await this.#api.get(`/api/v1/schedules/client/availableHours/${day}/services_id/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: servicesData.data.data
        }
    }

    //getCategories
    async getCategories(tokenKey, active) {

        let endPoint = active !== undefined ? `/api/v1/categories?active=${active}` : `/api/v1/categories`

        const categoriesData = await this.#api.get(endPoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesData.data.data
        }
    }

    //getDefaultTableHours
    async getDefaultTableHours(tokenKey) {
        const defaultTableDefaultOpenHours = await this.#api.get(`/api/v1/scheduleTableDefaultOpenHours`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: defaultTableDefaultOpenHours.data.data
        }
    }

    //getServicesValuePerAssociation
    async getServicesValuePerAssociation(tokenKey, serviceId) {

        const servicesValuePerAssociation = await this.#api.get(`/api/v1/servicesValuePerAssociations/services_id/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesValuePerAssociation.data.data
        }
    }

    //getServicesHoursEspecific
    async getServicesHoursEspecific(tokenKey, serviceId) {

        const getServicesHoursEspecific = await this.#api.get(`/api/v1/servicesHours/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: Object.create(getServicesHoursEspecific.data.data)
        }
    }

    //getCategoriesEspecific
    async getCategoriesEspecific(tokenKey, id) {

        const categoriesData = await this.#api.get(`/api/v1/Categories/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesData.data.data
        }
    }


    //getCategoriesReservationPerHour
    async getCategoriesReservationPerHour(tokenKey, categorieId) {

        const categoriesReservationHour = await this.#api.get(`/api/v1/configCategoriesReservationPerHour/${categorieId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesReservationHour.data.data
        }
    }

    //get paymentMethods
    async getPaymentMethods(tokenKey) {

        const paymentMethodsData = await this.#api.get(`/api/v1/paymentMethods`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentMethodsData.data.data
        }
    }

    //get paymentGatewayOptions
    async getPaymentOptionsGateways(tokenKey) {

        const paymentGatewayOptionsData = await this.#api.get(`/api/v1/paymentGatewayOptions`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayOptionsData.data.data
        }

    }

    //get paymentPublicGateway
    async getPaymentPublicGateways(tokenKey) {

        let endpoint = `/api/v1/paymentGateway/public`

        const paymentGatewayData = await this.#api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayData?.data
        }
    }

    //get paymentGateway
    async getPaymentGateways(tokenKey, status) {

        let endpoint = `/api/v1/paymentGateway?active=${status}`

        const paymentGatewayData = await this.#api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        if (paymentGatewayData.data.data?.length > 0) {
            paymentGatewayData.data.data.map(gateway => gateway.created_at = formattTimeToShow(gateway.created_at))
        }
        return {
            error: false,
            data: paymentGatewayData.data.data
        }

    }

    //get paymentById
    async getPaymentById(tokenKey, paymentId) {

        const paymentGatewayData = await this.#api.get(`/api/v1/payments/${paymentId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayData.data
        }

    }

    //get termosOfUse
    async getTermsOfUse(tokenKey, active, id) {

        let endPoint = id ? `/api/v1/termsOfUse/${id}` : active ? `/api/v1/termsOfUse?active=true` : '/api/v1/termsOfUse'
        console.log(tokenKey)
        try {
            const termsOfUseData = await this.#api.get(endPoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            termsOfUseData.data.data.map((term, index) => {
                termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
            })
            console.log(termsOfUseData)
            return {
                error: false,
                data: termsOfUseData.data.data
            }

        } catch (error) {
            return error
        }
    }

    //get termosOfUseEspecific
    async getTermsOfUseEspecific(tokenKey, id) {

        const termsOfUseData = await this.#api.get(`/api/v1/termsOfUse/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        termsOfUseData.data.data.map((term, index) => {
            termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
        })
        return {
            error: false,
            data: termsOfUseData.data.data
        }
    }

    //getTermsOfUseByServicesId
    async getTermsOfUseByServicesId(tokenKey, id) {

        const termsOfUseData = await this.#api.get(`/api/v1/termsOfUse?servicesId=${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        termsOfUseData.data.data.map((term, index) => {
            termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
        })
        return {
            error: false,
            data: termsOfUseData.data.data
        }

    }

    //getTimezones
    async getTimeZones(tokenKey) {

        const timeZoneData = await this.#api.get(`/api/v1/timezones`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        console.log(timeZoneData)
        return {
            error: false,
            data: timeZoneData.data.data
        }

    }

    //getHolidays
    async getHolidays(tokenKey) {

        try {
            const holidays = await this.#api.get(`/api/v1/holidays`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            holidays.data.data.map((holiday) => {

                holiday?.holidayDate?.length > 0 ? holiday.holidayDate = formattTimeToShow(holiday.holidayDate) : holiday.holidayDate = holiday.holidayDate
                holiday?.created_at?.length > 0 ? holiday.created_at = formattTimeToShow(holiday.created_at) : holiday.created_at = holiday.created_at
            })

            return {
                error: false,
                data: holidays.data.data
            }
        } catch (error) {
           return error
        }
    }

    //getActivities
    async getActivities(tokenKey, status = null) {

        try {
            const activities = await this.#api.get(`/api/v1/activities${[1, 0].includes(status) ? `?active=${status}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            activities.data.data.map((plan) => {
                plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                    plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
            })

            return {
                error: false,
                data: activities.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesById
    async getActivitiesById(tokenKey, id) {

        try {
            const activities = await this.#api.get(`/api/v1/activities/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            activities.data.data.map((plan) => {
                plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                    plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
            })
            return {
                error: false,
                data: activities.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesPlans
    async getActivitiesPlans(tokenKey, status) {

        let endpoint = [1, 0].includes(status) ? `/api/v1/activitiesPlans?active=${status}` : `/api/v1/activitiesPlans`
        try {
            const plans = await this.#api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            plans.data.data.map((plan) => {
                plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                    plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
            })

            return {
                error: false,
                data: plans.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesPlans
    async getActivitiesPlansById(tokenKey, planId) {

        try {
            const plans = await this.#api.get(`/api/v1/activitiesPlans/${planId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: plans.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesPlansPerAssociations
    async getActivitiesPlansPerAssociations(tokenKey) {

        try {
            const plans = await this.#api.get(`/api/v1/activitiesPlansPerAssociations`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            plans.data.data.map((plan) => {
                plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at
            })
            return {
                error: false,
                data: plans.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesPlansPerAssociations
    async getActivitiesPlansPerAssociationsById(tokenKey, activities_id) {

        try {
            const plans = await this.#api.get(`/api/v1/activitiesPlansPerAssociations/${activities_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            plans.data.data.map((plan) => {
                plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at
            })
            return {
                error: false,
                data: plans.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesRegulation
    async getActivitiesRegulation(tokenKey, activitieId) {
        try {
            const plans = await this.#api.get(`/api/v1/activities/${activitieId}/regulation`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            return {
                error: false,
                data: plans.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesPictures
    async getActivitiesPictures(tokenKey, activitieId, picture) {
        try {
            const activitiePictures = await this.#api.get(`/api/v1/activitiesPictures/activities_id/${activitieId}/picture/${picture}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob',
                responseEncoding: 'base64',
            })

            return {
                error: false,
                data: activitiePictures.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMyBalance
    async getMyBalance(tokenKey, usersId) {
        try {
            const balance = await this.#api.get(`/api/v1/balance/`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: balance.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMyBalanceHistory
    async getMyBalanceHistory(tokenKey, usersId) {
        try {
            const balance = await this.#api.get(`/api/v1/balance/history/`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            balance.data.data.map(balance => {
                balance.original_created_at = balance.created_at,
                    balance.created_at = formattTimeToShow(balance.created_at),
                    balance.original_value = balance.value,
                    balance.value = currencyPtBr(balance.value)
            })

            return {
                error: false,
                data: balance.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getBalanceHistory
    async getBalanceHistory(tokenKey, usersId) {
        try {
            const balance = await this.#api.get(`/api/v1/balance/users_id/${usersId}/history`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            balance.data.data.map(balance => {
                balance.original_created_at = balance.created_at,
                    balance.created_at = formattTimeToShow(balance.created_at),
                    balance.original_value = balance.value,
                    balance.value = currencyPtBr(balance.value)
            })

            return {
                error: false,
                data: balance.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMyBalanceData
    async getMyBalanceData(tokenKey, usersId) {
        try {
            const balance = await this.#api.get(`/api/v1/balance/users_id/${usersId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: balance.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getGastronomy
    async getGastronomy(tokenKey) {
        try {
            const gastronomy = await this.#api.get(`/api/v1/gastronomy`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            try {
                gastronomy.data.data.map((apiData, index) => {
                    gastronomy.data.data[index].created_at = formattTimeToShow(apiData.created_at)
                })
            } catch (error) {
                console.log(error)
            }
            return {
                error: false,
                data: gastronomy.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getGastronomyByStatus
    async getGastronomyByStatus(tokenKey, active) {
        try {
            const gastronomy = await this.#api.get(`/api/v1/gastronomy/active/${active}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            try {
                gastronomy.data.data.map((apiData, index) => {
                    gastronomy.data.data[index].created_at = formattTimeToShow(apiData.created_at)
                })
            } catch (error) {
                console.log(error)
            }
            return {
                error: false,
                data: gastronomy.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getGastronomyById
    async getGastronomyById(tokenKey, gastronomyId) {
        try {
            const gastronomy = await this.#api.get(`/api/v1/gastronomy/gastronomy_id/${gastronomyId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: gastronomy.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getFilteredStructures
    async getFilteredStructures(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/structures?direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const structures = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            structures.data.data.map((apiData, index) => {
                structures.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
            let newData = { data: structures.data.data, totalPages: structures.data.totalPages, quantity: structures.data.quantity }
            return {
                error: false,
                data: newData
            }
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getStructuresById
    async getStructuresById(tokenKey, structures_id) {
        try {
            const structures = await this.#api.get(`/api/v1/structures/structures_id/${structures_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: structures.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getSelectedUserBalance
    async getSelectedUserBalance(tokenKey, users_id) {
        try {
            const balance = await this.#api.get(`/api/v1/balance/users_id/${users_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: balance.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getSelectedUserBalance
    async getStructures(tokenKey, active) {

        let endPoint = active ? `/api/v1/structures?active=true` : `/api/v1/structures`
        try {
            const structures = await this.#api.get(endPoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: structures.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getAgreements
    async getAgreements(tokenKey, filter, page, direction, categories) {

        let params = `/api/v1/agreements?direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = categories ? params.concat(`&categories=${categories}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const agreements = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            console.log(agreements)

            return {
                error: false,
                data: agreements.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getAgreementsById
    async getAgreementsById(tokenKey, id) {

        let params = `/api/v1/agreements/agreements_id/${id}`

        try {
            const agreements = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: agreements.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getAgreementsPicture
    async getAgreementsPicture(tokenKey, agreementId, picture) {

        let params = `/api/v1/agreementsPictures/agreements_id/${agreementId}/picture/${picture}`

        try {
            const picture = await this.#api.get(params, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: picture.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getAgreementsCategories
    async getAgreementsCategories(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/agreements/categories`
        try {
            const structures = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: structures.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getEvents
    async getEvents(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/events?direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }

        try {
            const events = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: events.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getEventsById
    async getEventsById(tokenKey, id) {

        let params = `/api/v1/events/events_id/${id}`

        try {
            const structures = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: structures.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getEventsPicture
    async getEventsPicture(tokenKey, agreementId, picture) {

        let params = `/api/v1/eventsPictures/events_id/${agreementId}/picture/${picture}`

        try {
            const picture = await this.#api.get(params, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: picture.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMySchedules
    async getMySchedules(tokenKey, filter, page, direction, active) {
        let params = `/api/v1/schedules/client?direction=${direction ? 'asc' : 'desc'}`


        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
        // params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)
        if (filter?.filterObject?.length > 0) {

            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const schedules = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: schedules.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getSchedulesClientsMyPendings
    async getSchedulesClientsMyPendings(tokenKey, active) {

        let endpoint = `/api/v1/schedules/client/myPendings`
        try {
            const data = await this.#api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getExternalPaymentStatus
    async getExternalPaymentStatus(tokenKey, paymentId) {

        let endpoint = `/api/v1/payments/external/consultStatus/${paymentId}`

        try {
            const data = await api.patch(endpoint, {}, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data.data.data
            }



        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //geSchedulesDraw
    async geSchedulesDraw(tokenKey) {
        let endpoint = `/api/v1/schedulesDraw`
        try {
            const data = await this.#api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get siteBanners
    async getSiteBanners(tokenKey, id, page) {

        let endpoint = page ? `/api/v1/siteBanners?page=${page}` : id ? `/api/v1/siteBanners/${id}` : `/api/v1/siteBanners/`

        try {

            const data = await this.#api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesSubscriptions
    async getActivitiesSubscriptions(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/activitiesSubscriptions?direction=${direction ? 'asc' : 'desc'}`
        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active == 1 ? params.concat(`&active=true`) : params.concat(`&active=false`)
        if (filter?.filterObject?.length > 0) {

            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesSubscriptions
    async getActivitiesSubscriptionsById(tokenKey, id) {

        let params = `/api/v1/activitiesSubscriptions/subscriptions_id/${id}`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMyActivitiesSubscriptionsById
    async getMyActivitiesSubscriptionsById(tokenKey, id) {

        let params = `/api/v1/activitiesSubscriptions/client/subscriptions_id/${id}`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getMyActivitiesSubscriptions
    async getMyActivitiesSubscriptions(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/activitiesSubscriptions/client?direction=${direction ? 'asc' : 'desc'}`
        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active == 1 ? params.concat(`&active=true`) : params.concat(`&active=false`)
        if (filter?.filterObject?.length > 0) {

            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getActivitiesSubscriptionsPendings
    async getActivitiesSubscriptionsPendings(tokenKey, id) {

        let params = `/api/v1/activitiesSubscriptions/client/myPendings`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardGeneralData
    async getDashboardGeneralData(tokenKey) {

        let params = `/api/v1/dashboards/schedules/general`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardRevenueOfYear
    async getDashboardRevenueOfYear(tokenKey, year) {

        let params = `/api/v1/dashboards/schedules/revenueOfYear/${year}`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardIncomeByCategory
    async getDashboardIncomeByCategory(tokenKey, year) {

        let params = year ? `/api/v1/dashboards/schedules/incomeByCategory?year=${year}` : `/api/v1/dashboards/schedules/incomeByCategory`

        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardSchedulesPerCategory
    async getDashboardSchedulesPerCategory(tokenKey, month) {

        let params = month ? `/api/v1/dashboards/schedules/schedulesPerCategory?month=${month}` : `/api/v1/dashboards/schedules/schedulesPerCategory`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardPaymentMethods
    async getDashboardPaymentMethods(tokenKey, year) {

        let params = year ? `/api/v1/dashboards/schedules/paymentMethods?year=${year}` : `/api/v1/dashboards/schedules/paymentMethods`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardPaymentMethodsActivities
    async getDashboardPaymentMethodsActivities(tokenKey, year) {

        let params = year ? `/api/v1/dashboards/activitiesSubscriptions/paymentMethods?year=${year}` : `/api/v1/dashboards/activitiesSubscriptions/paymentMethods`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardActivitiesSubscriptionsGeneralData
    async getDashboardActivitiesSubscriptionsGeneralData(tokenKey) {

        let params = `/api/v1/dashboards/activitiesSubscriptions/general`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardActivitiesSubscriptionsRevenueOfYear
    async getDashboardActivitiesSubscriptionsRevenueOfYear(tokenKey, year) {

        let params = `/api/v1/dashboards/activitiesSubscriptions/revenueOfYear/${year}`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardActivitiesSubscriptionsRevenueOfYear
    async getDashboardActivitiesSubscriptionsByActivitie(tokenKey, month) {

        let params = month ? `/api/v1/dashboards/activitiesSubscriptions/activitiesSubscriptionsByActivitie?month=${month}` : `/api/v1/dashboards/activitiesSubscriptions/activitiesSubscriptionsByActivitie`
        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getDashboardActivitiesSubscriptionsIncomeByActivitie
    async getDashboardActivitiesSubscriptionsIncomeByActivitie(tokenKey, year) {

        let params = year ? `/api/v1/dashboards/activitiesSubscriptions/incomeByActivitie?year=${year}` : `/api/v1/dashboards/activitiesSubscriptions/incomeByActivitie`

        try {

            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //reports - getFilteredSchedulesReports
    async getFilteredSchedulesReports(tokenKey, filter) {

        let params = `/api/v1/reports/schedules?`
        if (filter.length > 0) {
            filter.map((toFilter, index) => {
                if (typeof (toFilter?.value) !== 'object') {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
                } else {
                    toFilter?.value?.map(thisValue => {
                        params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                    })
                }
            })
        }
        try {
            const schedulesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
                responseType: 'blob'
            })
            return {
                error: false,
                data: schedulesData.status === 204 ? schedulesData : schedulesData.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //reports - getFilteredSchedulesPaymentsReports
    async getFilteredSchedulesPaymentsReports(tokenKey, filter) {

        let params = `/api/v1/reports/schedulesPayments?`
        if (filter.length > 0) {
            filter.map((toFilter, index) => {
                if (typeof (toFilter?.value) !== 'object') {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
                } else {
                    toFilter?.value?.map(thisValue => {
                        params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                    })
                }
            })
        }
        try {
            const schedulesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
                responseType: 'blob'
            })

            return {
                error: false,
                data: schedulesData.status === 204 ? schedulesData : schedulesData.data
            }
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //reports - getFilteredSchedulesBlockReports
    async getFilteredSchedulesBlockReports(tokenKey, filter) {

        let params = `/api/v1/reports/schedulesBlocks?`
        if (filter.length > 0) {
            filter.map((toFilter, index) => {
                if (typeof (toFilter?.value) !== 'object') {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
                } else {
                    toFilter?.value?.map(thisValue => {
                        params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                    })
                }
            })
        }
        try {
            const schedulesData = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
                responseType: 'blob'
            })

            return {
                error: false,
                data: schedulesData.status === 204 ? schedulesData : schedulesData.data
            }
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //reports - getFilteredActivitiesSubscriptionsReport
    async getFilteredActivitiesSubscriptionsReport(tokenKey, filter) {
        let params = `/api/v1/reports/activitiesSubscriptions?`
        if (filter.length > 0) {
            filter.map((toFilter, index) => {
                params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
                responseType: 'blob'
            })

            return {
                error: false,
                data: data.status === 204 ? data : data.data

            }
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //reports - getFilteredPaymentsReport
    async getFilteredPaymentsReport(tokenKey, filter) {
        let params = `/api/v1/reports/payments?`
        if (filter.length > 0) {
            filter.map((toFilter, index) => {
                if (typeof (toFilter?.value) !== 'object') {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
                } else {
                    toFilter?.value?.map(thisValue => {
                        params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                    })
                }
            })
        }
        try {
            const data = await this.#api.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`,
                },
                responseType: 'blob'
            })

            return {
                error: false,
                data: data.status === 204 ? data : data.data

            }
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

}