//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { FaInfoCircle } from 'react-icons/fa'
import Tippy from '@tippyjs/react'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { justNumber } from '../../../utils/validators/justNumber'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function PatrimonyCreate() {

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial} = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [productList, setProductList] = useState([])
    const [listLinks, setListLinks] = useState([])

    //selected variables
    const [patrimony, setPatrimony] = useState({
        financialProducts_id: 0,
        startDate: '',
        linked: '',
        depreciationPercentage: 0,
        depreciationUnitMeasure: '',
        shoppingPrice: 0,
        linked_id: 0
    })

    async function getData() {

        const productData = await defaultCallsFinancial.getProducts(userData[0]?.token, 1)
        if (productData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(productData.data)
        }
        setProductList(productData?.data)

        const linksData = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token, 1)
        if (linksData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(linksData.data)
        }
        setListLinks(linksData?.data)

        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {
        let requiredFields =
            [
                { "name": 'financialProducts_id', "value": patrimony?.financialProducts_id, "required": true, "type": 'string' },
                { "name": 'startDate', "value": patrimony?.startDate, "required": true, "type": 'string' },
                { "name": 'linked', "value": patrimony?.linked, "required": true, "type": 'string' },
                { "name": 'depreciationPercentage', "value": patrimony?.depreciationPercentage, "required": true, "type": 'string' },
                { "name": 'depreciationUnitMeasure', "value": patrimony?.depreciationUnitMeasure?.id, "required": true, "type": 'string' },
                { "name": 'shoppingPrice', "value": patrimony?.shoppingPrice, "required": true, "type": 'number' },
            ]

        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.post(`/api/v1/financial/patrimony`, {
                financialProducts_id: patrimony?.financialProducts_id,
                startDate: patrimony?.startDate,
                linked: patrimony?.linked?.linked,
                depreciationPercentage: patrimony?.depreciationPercentage,
                depreciationUnitMeasure: patrimony?.depreciationUnitMeasure?.id,
                shoppingPrice: patrimony?.shoppingPrice,
                linked_id: patrimony?.linked?.linked_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Patrimônio criado com sucesso!")
            navigate('/financial/patrimony')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro de patrimônio'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <div className='flex gap-1 w-full'>
                                        <LabelInput text={'Produto: *'}>
                                            <InputAutoComplete
                                                data={productList}
                                                id={'financialProducts_id'}
                                                onChange={(e) =>
                                                    setPatrimony(prev => ({ ...prev, financialProducts_id: e?.id }))
                                                }
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>{`Selecione um produto já criado.`}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <LabelInput text={'Data inicial: *'}>
                                        <Input id='startDate' type={'date'} value={patrimony?.startDate} onChange={(e) => setPatrimony(prev => ({ ...prev, startDate: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Vinculado com: *'}>
                                        <InputAutoComplete
                                            data={listLinks}
                                            id={'linked'}
                                            onChange={(e) =>
                                                setPatrimony(prev => ({ ...prev, linked: e }))
                                            }
                                            selectedLabel={'linked_name'}
                                            optionList={['linked_id', 'linked_name']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'% de depreciação: *'}>
                                        <Input id='depreciationPercentage' value={patrimony?.depreciationPercentage ? patrimony?.depreciationPercentage : undefined} onChange={(e) => setPatrimony(prev => ({ ...prev, depreciationPercentage: justNumber(e.target.value) }))}></Input>
                                    </LabelInput>
                                    <div className='flex gap-1 w-full'>
                                        <LabelInput text={'Depreciação de unidade: *'}>
                                            <InputAutoComplete
                                                data={[{ id: 'day', name: 'Diária' }, { id: 'month', name: 'Mensal' },  { id: 'year', name: 'Anual' }]}
                                                id={'depreciationUnitMeasure'}
                                                onChange={(e) => setPatrimony(prev => ({ ...prev, depreciationUnitMeasure: e }))}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>{`Selecione como será calculado a depreciação (mensalmente ou anualmente).`}</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <div className='w-96'>
                                        <LabelInput text={'Valor de compra: *'}>
                                            <CurrencyInput 
                                                id={'shoppingPrice'}
                                                placeHolder='R$ 0,00'
                                                onChange={(e) => setPatrimony(prev => ({ ...prev, shoppingPrice: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))}
                                            >
                                            </CurrencyInput>
                                        </LabelInput>
                                    </div>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/financial/patrimony')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                            handleSubmit()
                        }}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}