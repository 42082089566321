//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { Input } from '../../../components/input/input'
import { Footer } from '../../../components/footer/Footer'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { Toogle } from '../../../components/toogle/Toogle'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function PaymentGatewayEdit() {

    const { api, defaultCallsSchedules, defaultCallsAuth } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [paymentGatewayList, setPaymentGatewayList] = useState([])
    const providersData = [{ "name": "Stonee", id: 1 }, { "name": "Stone", id: 1 }]

    const [selectedProvider, setSelectedProvider] = useState([])

    const [description, setDescription] = useState('')
    const [gatewayId, setGatewayId] = useState('')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [sellerId, setSellerId] = useState('')
    const [clientPublic, setClientPublic] = useState('')
    const [gatewayStatus, setGatewayStatus] = useState(true)
    const [gatewayStatusHistory, setGatewayStatusHistory] = useState(true)

    const fields =
        [
            { "name": 'clientId', "value": clientId, "required": true, "type": 'string' },
            { "name": 'clientSecret', "value": clientSecret, "required": true, "type": 'string' },
            { "name": 'sellerId', "value": sellerId, "required": false, "type": 'string' },
            { "name": 'clientPublic', "value": clientPublic, "required": true, "type": 'string' },
        ]

    useEffect(() => {

        async function getData() {
            setLoading(true)
            let gateway = JSON.parse(localStorage.getItem('@gateway'))

            if (!gateway?.id) {
                return navigate('/paymentGatewayConfig')
            }
            localStorage.removeItem('@gateway')

            setSelectedProvider(providersData[0])
            setDescription(gateway?.description)
            setClientId(gateway?.client_id)
            setClientSecret(gateway?.client_secret)
            setGatewayId(gateway?.id)
            setSellerId(gateway?.seller_id)
            setClientPublic(gateway?.client_public)
            setGatewayStatus(gateway?.active)
            setGatewayStatusHistory(gateway?.active)

            setLoading(false)
        }
        getData()
    }, [])

    async function handleSubmit() {

        if (validateFields(fields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            return setLoading(false)
        }

        setLoading(true)
        try {

            await api.put(`/api/v1/paymentGateway/${gatewayId}`, {
                description: description?.length > 0 ? description : undefined,
                client_id: clientId,
                client_secret: clientSecret,
                seller_id: undefined,
                client_public: clientPublic
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            if (gatewayStatus !== gatewayStatusHistory) {

                const updateStatus = await api.put(`/api/v1/paymentGateway/${gatewayId}/status/${gatewayStatus ? 1 : 0}`, {

                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

            }
            navigate('/paymentGatewayConfig')
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setLoading(false)

        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className='w-full justify-start flex flex-row items-center'>
                    <Title text={'Editar gateway'}></Title>
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col w-full items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='w-full items-start justify-start flex flex-col sm:flex-row gap-4'>
                                <p className='translate-y-6 mb-4 text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Informe os dados da sua conta <strong>stone</strong> abaixo</p>
                                <LabelInput text={'Ativo'}>
                                    <Toogle status={gatewayStatus} onClick={() => userData[0]?.permissions.includes('paymentGateway:update') || userData[0].typeAccess_id < 3 ? setGatewayStatus(!gatewayStatus) : ''}  />
                                </LabelInput>
                            </div>
                            <form className='w-full flex flex-row flex-wrap items-start justify-start gap-4'>
                                {/* <LabelInput text={'Provedor'}>
                                    <InputAutoComplete
                                        disabled={false}
                                        preSelectedValue={selectedProvider.name}
                                        data={paymentGatewayList}
                                        optionList={['name']}
                                        onChange={(e) => handleSelectGateway(e)}
                                        selectedLabel={'name'} />
                                </LabelInput> */}

                                {
                                    selectedProvider.id === 2 ?
                                        <>
                                            <LabelInput text={'Descriçao'}>
                                                <Input id={'description'} charLimit={50} type={'text'} onChange={(e) => setDescription(e.target.value)} value={description}></Input>
                                            </LabelInput>
                                            <LabelInput text={'ID da conta *'}>
                                                <Input id={'clientId'} charLimit={100} type={'text'} onChange={(e) => setClientId(e.target.value)} value={clientId}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Client secret *'}>
                                                <Input id={'clientSecret'} charLimit={100} type={'text'} onChange={(e) => setClientSecret(e.target.value)} value={clientSecret}></Input>
                                            </LabelInput>
                                            {/* <LabelInput text={'Seller id *'}>
                                                <Input id={'sellerId'} charLimit={100} type={'text'} onChange={(e) => setSellerId(e.target.value)} value={sellerId}></Input>
                                            </LabelInput> */}
                                            <LabelInput text={'Chave pública *'}>
                                                <Input id={'clientPublic'} charLimit={100} type={'text'} onChange={(e) => setClientPublic(e.target.value)} value={clientPublic}></Input>
                                            </LabelInput>
                                        </>
                                        :
                                        <>
                                            <LabelInput text={'Descriçao'}>
                                                <Input id={'description'} charLimit={50} type={'text'} onChange={(e) => setDescription(e.target.value)} value={description}></Input>
                                            </LabelInput>
                                            <LabelInput text={'ID da conta *'}>
                                                <Input id={'clientId'} charLimit={100} type={'text'} onChange={(e) => setClientId(e.target.value)} value={clientId}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Chave secreta *'}>
                                                <Input id={'clientSecret'} charLimit={100} type={'text'} onChange={(e) => setClientSecret(e.target.value)} value={clientSecret}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Chave pública *'}>
                                                <Input id={'clientPublic'} charLimit={100} type={'text'} onChange={(e) => setClientPublic(e.target.value)} value={clientPublic}></Input>
                                            </LabelInput>
                                        </>
                                }

                            </form>
                        </>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button approval={false} shadow={true} onClick={() => history.back()}>Voltar</Button>
                    <Button module={"paymentGateway:update"} userData={userData} shadow={true} onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Footer>
        </Container>
    )
}