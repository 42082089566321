//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import Tippy from '@tippyjs/react'
import { FaInfoCircle } from 'react-icons/fa'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Toogle } from '../../../components/toogle/Toogle'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { ObjectSortByProperty } from '../../../utils/objectSort'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import moment from 'moment'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function AccountsEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { api, api_financial, defaultCallsAuth, defaultCallsFinancial } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [bankList, setBankList] = useState([])
    const [companiesList, setCompaniesList] = useState([])
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [bankAccount, setBankAccount] = useState({
        accountType: 'bank',
        name: undefined,
        description: undefined,
        paymentGateway_id: undefined,
        bankIspb: undefined,
        accountAgency: undefined,
        accountBalance: undefined,
        accountNumber: undefined,
        startDate: moment().utc(false).format('YYYY-MM-DD'),
        accountBalance: 0
    })

    useEffect(() => {
        setShowUniversalModal(false)
        fetchData()
    }, [])

    async function fetchData() {

        const companiesData = await defaultCallsAuth.getCompanies(userData[0]?.token)
        if (companiesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(companiesData.data)
        }
        setCompaniesList(companiesData.data)

        try {
            const apiUrl = 'https://brasilapi.com.br/api/banks/v1'

            const response = await fetch(apiUrl)

            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.status}`)
            }

            const data = await response.json()

            const bankData = await defaultCallsFinancial.getAccountsById(userData[0].token, id)
            if (bankData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(bankData.data)
            }

            setBankAccount(bankData.data[0])
            setBankList(data.sort(ObjectSortByProperty('name')).filter(bank => bank?.code))

            //filtra o banco da lista
            const filteredBank = data.filter(bank => bank.code === bankData.data[0].bankCode)
            //filtra a empresa
            const filteredCompany = companiesData.data.filter(company => company.id === bankData.data[0]?.companies_id)[0]

            setBankAccount(prev => ({ ...prev, bankIspb: filteredBank[0]?.ispb, name: filteredBank[0]?.name, companies_id: filteredCompany }))
            setLoading(false)

        } catch (error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Erro ao buscar dados de bancos, tente novamente')
        }
    }


    async function handleEdit() {
        try {
            const requiredFields = [
                { "name": 'accountType', "value": bankAccount?.accountType, "required": true, "type": 'string' },
                { "name": 'accountBalance', "value": bankAccount?.accountBalance, "required": true, "type": 'number' },
                { "name": 'startDate', "value": bankAccount?.startDate, "required": true, "type": 'string' },
            ]

            if(bankAccount?.accountType === 'cash'){
                requiredFields.push(
                    { "name": 'name', "value": bankAccount?.name, "required": true, "type": 'string' }
                )
            }

            if(bankAccount?.accountType === 'bank'){
                requiredFields.push(
                    { "name": 'bank', "value": bankAccount?.bankIspb, "required": true, "type": 'string' },
                    { "name": 'accountNumber', "value": bankAccount?.accountNumber, "required": true, "type": 'string' },
                    { "name": 'accountAgency', "value": bankAccount?.accountAgency, "required": true, "type": 'string' }
                )
            }
            
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            setLoading(true)
            console.log(bankAccount)

            await api_financial.put(`/api/v1/financial/accounts/${id}`, {
                name: bankAccount?.name ? bankAccount?.name : undefined,
                bankIspb: bankAccount?.bankIspb ? bankAccount?.bankIspb : undefined,
                bankCode: String(bankAccount?.bankCode) ? String(bankAccount?.bankCode) : undefined,
                startDate: bankAccount?.startDate ? bankAccount?.startDate : undefined,
                accountNumber: bankAccount?.accountNumber ? bankAccount?.accountNumber : undefined,
                accountAgency: bankAccount?.accountAgency ? bankAccount?.accountAgency : undefined,
                accountBalance: bankAccount?.accountBalance ? bankAccount?.accountBalance : undefined,
                description: bankAccount?.description ? bankAccount?.description : undefined,
                companies_id: bankAccount?.companies_id?.id ? bankAccount?.companies_id?.id : undefined,
                active: [true, false].includes(bankAccount?.active) ? bankAccount?.active : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Banco criado com sucesso!")
            navigate(-1)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {
        try {
            setLoading(true)

            await api.delete(`/api/v1/financial/accounts/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Banco/conta excluído com sucesso!')
            navigate(-1)
        } catch (error) {
            console.log(error?.response)
            if (responseError(error)?.length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const accountsType = [
        { id: 'bank', description: 'Banco' },
        { id: 'cash', description: 'Caixa' },
    ]

    return (
        <>
            <ScreenModal title={'Excluir Banco/Conta'} >
                <div className='flex flex-col p-8'>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark mb-8'>Deseja realmente excluir este banco/conta?</p>
                    <div className='flex flex-row justify-center gap-2 w-full'>
                        <Button shadow={true} onClick={() => handleRemove()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editação de Bancos/Contas'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-wrap gap-4 items-start justify-start sm:justify-start w-full'>
                                    <LabelInput text={'Tipo: *'}>
                                        <InputAutoComplete
                                            data={accountsType}
                                            id={'accountType'}
                                            disabled={true}
                                            preSelectedValue={accountsType.find(at => at.id == bankAccount?.accountType)?.description}
                                            onChange={(e) => setBankAccount(prev => ({ ...prev, accountType: e.description }))}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                        />
                                    </LabelInput>
                                    {
                                        bankAccount?.accountType === 'cash' &&
                                        <LabelInput text={'Nome: *'}>
                                            <Input id='name' type={'text'} value={bankAccount?.name} onChange={(e) => setBankAccount(prev => ({ ...prev, name: e.target.value }))}></Input>
                                        </LabelInput>
                                    }
                                    {
                                        bankAccount?.accountType === 'bank' &&
                                        <>
                                            <LabelInput text={'Banco: *'}>
                                                <InputAutoComplete
                                                    data={bankList}
                                                    id={'bank'}
                                                    preSelectedValue={bankAccount?.name}
                                                    onChange={(e) => setBankAccount(prev => ({ ...prev, bankIspb: String(e.ispb), bankCode: String(e.code), name: e.name }))}
                                                    selectedLabel={'name'}
                                                    optionList={['code', 'name']}
                                                />
                                            </LabelInput>
                                            <LabelInput text={"Agência *"}>
                                                <Input charLimit={30} id='accountAgency' type={'text'} value={bankAccount?.accountAgency} onChange={(e) => setBankAccount(prev => ({ ...prev, accountAgency: e.target.value }))}></Input>
                                            </LabelInput>
                                            <LabelInput text={"Conta *"}>
                                                <Input id='accountNumber' type={'text'} value={bankAccount?.accountNumber} onChange={(e) => setBankAccount(prev => ({ ...prev, accountNumber: e.target.value }))}></Input>
                                            </LabelInput>
                                        </>
                                    }
                                    <LabelInput text={"Data início *"}>
                                        <Input id='startDate' type={'date'} value={bankAccount?.startDate} onChange={(e) => setBankAccount(prev => ({ ...prev, startDate: e.target.value }))}></Input>
                                    </LabelInput>
                                    <div className='flex sm:w-auto gap-1 relative'>
                                        <LabelInput text={'Valor inicial'}>
                                            <CurrencyInput
                                                id="accountBalance"
                                                className='pl-2 border border-gray-400 border-opacity-75 
                                                            outline-none
                                                            sm:w-96 w-full
                                                            text-sm sm:text-sm
                                                            transition-all duration-200
                                                            focus:shadow-borderShadow
                                                            h-10 sm:h-8
                                                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                placeHolder={bankAccount?.accountBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bankAccount.accountBalance) : 'R$ 0,00'}
                                                onChange={(e) => {
                                                    setBankAccount(prev => ({ ...prev, accountBalance: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                }}></CurrencyInput>
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Informe o valor atual na conta para que o sistema utilize como base para realizar os cálculos, informe 0, caso seja uma nova conta.</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <div className='flex gap-1 relative'>
                                        <LabelInput text={'Empresa:'}>
                                            <InputAutoComplete
                                                data={companiesList}
                                                id={'companies_id'}
                                                preSelectedValue={bankAccount.companies_id?.name}
                                                onChange={(e) => setBankAccount(prev => ({ ...prev, companies_id: e }))}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Informe a qual empresa esta conta pertence, caso não tenha empresa vinculada, mantenha este campo vazio.</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                        >
                                            <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    <div className='flex w-full flex-row gap-4 items-start justify-start'>
                                        <div className='flex flex-col'>
                                            <a className='text-sm text-inputPlaceholderLight'>Descrição</a>
                                            <textarea id='description' wrap="hard" value={bankAccount?.description} onChange={(e) => setBankAccount(prev => ({ ...prev, description: e.target.value }))} maxLength={2000} className='
                                                    p-2 border border-gray-400 border-opacity-75 
                                                    h-64
                                                    sm:w-96 w-full
                                                    text-sm
                                                    outline-none transition-all duration-200 focus:shadow-borderShadow
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                                    '>
                                            </textarea>
                                        </div>
                                        <LabelInput text={'Ativo'}>
                                            <Toogle status={bankAccount?.active} onClick={() => setBankAccount(prev => ({ ...prev, active: !bankAccount?.active }))} />
                                        </LabelInput>
                                    </div>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button module={"financialClientsAndSuppliers:delete"} userData={userData} shadow={true} approval={false} onClick={() => setShowUniversalModal(true)}>Excluir</Button>
                            <Button module={"financialClientsAndSuppliers:update"} userData={userData} shadow={true} onClick={() => handleEdit()}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}