//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { justNumber } from '../../../utils/validators/justNumber'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { maskCpf } from '../../../utils/mask/maskCpf'
import { maskCnpj } from '../../../utils/mask/maskCnpj'
import { maskCep } from '../../../utils/mask/maskCep'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ClientsAndSuppliersEdit() {

    const navigate = useNavigate()
    const { api_financial, defaultCallsFinancial} = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [cnpjDetailsObtained, setCnpjDetailsObtained] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    //selected variables
    const [clientsAndSuppliers, setClientsAndSuppliers] = useState({
        name: '',
        fantasyName: '',
        cnpj_cpf: '',
        isClientUser: false,
        personType: { id: 0, name: 'CPF', value: "PF" },
        isSupplier: false,
        isClient: false
    })

    async function getData() {

        const clientAndSupplierData = await defaultCallsFinancial.getClientAndSupplierById(userData[0].token, id)

        setClientsAndSuppliers(clientAndSupplierData.data[0])
        setLoading(false)
    }

    useEffect(() => {
        setShowUniversalModal(false)
        setShowNotificationModal(false)
        getData()
    }, [])

    async function handleEdit() {

        let requiredFields =
            clientsAndSuppliers?.personType === "PF" ?
                [
                    { "name": 'name', "value": clientsAndSuppliers?.name, "required": true, "type": 'string' },
                    { "name": 'cnpj_cpf', "value": clientsAndSuppliers?.cnpj_cpf, "required": true, "type": 'string' },
                    { "name": 'personType', "value": clientsAndSuppliers?.personType, "required": true, "type": 'string' },
                ]
                :
                [
                    { "name": 'name', "value": clientsAndSuppliers?.name, "required": true, "type": 'string' },
                    { "name": 'cnpj_cpf', "value": clientsAndSuppliers?.cnpj_cpf, "required": true, "type": 'string' },
                    { "name": 'personType', "value": clientsAndSuppliers?.personType, "required": true, "type": 'string' },
                ]
        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }
        
            const formattedCnpj_Cpf = clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')
            await api_financial.put(`/api/v1/financial/clientsAndSuppliers/${id}`, {
                name: clientsAndSuppliers?.name,
                fantasyName: clientsAndSuppliers?.fantasyName ? clientsAndSuppliers?.fantasyName : undefined,
                personType: clientsAndSuppliers?.personType,
                cnpj_cpf: formattedCnpj_Cpf,
                isSupplier: clientsAndSuppliers.isSupplier,
                isClient: clientsAndSuppliers.isClient,
                email: clientsAndSuppliers?.email ? clientsAndSuppliers?.email : undefined,
                dddPhone: clientsAndSuppliers.dddPhone ? clientsAndSuppliers.dddPhone : undefined,
                phone: clientsAndSuppliers?.phone ? clientsAndSuppliers?.phone : undefined,
                addressCep: clientsAndSuppliers?.addressCep ? Number(removeEspecialChar(clientsAndSuppliers?.addressCep)) : undefined,
                addressRoad: clientsAndSuppliers?.addressRoad ? clientsAndSuppliers?.addressRoad : undefined,
                addressNumber: clientsAndSuppliers?.addressNumber ? clientsAndSuppliers?.addressNumber : undefined,
                addressDistrict: clientsAndSuppliers?.addressDistrict ? clientsAndSuppliers?.addressDistrict : undefined,
                addressCity: clientsAndSuppliers?.addressCity ? clientsAndSuppliers?.addressCity : undefined,
                addressState: clientsAndSuppliers?.addressState ? clientsAndSuppliers?.addressState : undefined,
                addressCountry: clientsAndSuppliers?.addressCountry ? clientsAndSuppliers?.addressCountry : undefined,
                municipalRegistration: clientsAndSuppliers?.municipalRegistration ? clientsAndSuppliers?.municipalRegistration : undefined,
                stateRegistration: clientsAndSuppliers?.stateRegistration ? clientsAndSuppliers?.stateRegistration : undefined,
                observation: clientsAndSuppliers?.observation ? clientsAndSuppliers?.observation : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Cliente/Fornecedor editado com sucesso!")
            navigate('/financial/clientsAndSuppliers')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getAddress() {
        if (clientsAndSuppliers?.addressCep) {
            setLoading(true)

            fetch(`https://viacep.com.br/ws/${clientsAndSuppliers?.addressCep}/json`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro na requisição à API Via CEP');
                    }
                    return response.json();
                })
                .then(data => {
                    setClientsAndSuppliers(prev => ({ ...prev, addressRoad: data.logradouro, addressDistrict: data.bairro, addressCity: data.localidade, addressState: data.uf, addressCountry: 'Brasil' }))
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error);
                });
        }
        await new Promise(resolve => setTimeout(resolve, 100))
        document.querySelector("#addressRoad").focus()
    }

    async function handleRemoveClientAndSupplier() {

        try {

            setLoading(true)

            await api_financial.delete(`/api/v1/financial/clientsAndSuppliers/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setLoading(false)
            setShowUniversalModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Cliente/Fornecedor excluído com sucesso!')
            navigate('/financial/clientsAndSuppliers')
        } catch (error) {
            console.log(error?.response)
            if (responseError(error)?.length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    if (clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')?.length === 14 && clientsAndSuppliers?.personType !== 'PF' && !cnpjDetailsObtained) {
        getDetailsInCnpj()
    }
    async function getDetailsInCnpj() {
        try {
            const formattedCnpj = clientsAndSuppliers?.cnpj_cpf.replace(/[^\d]/g, '')
            const response = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${formattedCnpj}`)
            const detailsCnpj = await response.json()

            const data = {
                name: detailsCnpj?.razao_social,
                addressCep: detailsCnpj?.cep,
                addressRoad: detailsCnpj?.logradouro,
                addressDistrict: detailsCnpj?.bairro,
                addressCity: detailsCnpj?.municipio,
                addressState: detailsCnpj?.uf
            }
            setClientsAndSuppliers(prev => ({
                ...prev,
                ...data
            }))
            setCnpjDetailsObtained(true)
            setLoading(false)
        } catch (error) {
            console.log('Erro ao buscar CNPJ')
            setLoading(false)
        }
    }

    function cleanByPersonType() {
        const data = {
            name: '',
            cnpj_cpf: '',
            fantasyName: '',
            email: ''
        }
        setClientsAndSuppliers(prev => ({
            ...prev,
            ...data
        }))
    }

    const personTypeOptions = [
        { id: 'PJ', name: 'Pessoa Jurídica', value: "PJ" },
        { id: 'PF', name: 'Pessoa Física', value: "PF" },
    ]

    return (
        <>
            <ScreenModal title={'Excluir cliente/fornecedor'} >
                <div className='flex flex-col p-8'>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark mb-8'>Deseja realmente excluir este cliente/fornecedor?</p>
                    <div className='flex flex-row justify-center gap-2 w-full'>
                        <Button shadow={true} onClick={() => handleRemoveClientAndSupplier()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Edição cliente/fornecedor'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-wrap gap-4 items-start justify-start sm:justify-start w-full'>
                                    <LabelInput text={'Tipo: *'}>
                                        <InputAutoComplete
                                            preSelectedValue={personTypeOptions.find((i) => i.value == clientsAndSuppliers?.personType)?.name}
                                            data={personTypeOptions}
                                            id={'personType'}
                                            onChange={(e) => { setClientsAndSuppliers(prev => ({ ...prev, personType: e.value })); cleanByPersonType() }}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={clientsAndSuppliers?.personType === "PF" ? "CPF *" : "CNPJ *"}>
                                        <Input 
                                            charLimit={30} 
                                            id='cnpj_cpf' 
                                            type={'text'} 
                                            value={
                                                clientsAndSuppliers?.personType?.value === "PF"
                                                ?
                                                maskCpf(clientsAndSuppliers?.cnpj_cpf)
                                                :
                                                maskCnpj(clientsAndSuppliers?.cnpj_cpf)
                                            }
                                            onChange={(e) => {
                                                setClientsAndSuppliers(prev => ({ ...prev, cnpj_cpf: e.target.value }));
                                                setCnpjDetailsObtained(false);
                                        }}>
                                        </Input>
                                    </LabelInput>
                                    <LabelInput text={clientsAndSuppliers.personType == 'PJ' ? 'Razão Social *' : 'Nome *'}>
                                        <Input id='name' type={'text'} value={clientsAndSuppliers?.name} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    {clientsAndSuppliers?.personType === "PJ" &&
                                        <LabelInput text={'Nome fantasia:'}>
                                            <Input id='fantasyName' type={'text'} value={clientsAndSuppliers?.fantasyName} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, fantasyName: e.target.value }))}></Input>
                                        </LabelInput>
                                    }
                                    <LabelInput text={'E-mail:'}>
                                        <Input charLimit={200} id='email' type={'text'} value={clientsAndSuppliers?.email} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, email: e.target.value }))}></Input>
                                    </LabelInput>
                                    <div className='flex gap-4'>
                                        <LabelInput text={'DDD:'}>
                                            <Input width={12} id='dddPhone' type={'text'} value={clientsAndSuppliers?.dddPhone} charLimit={2} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, dddPhone: justNumber(e.target.value) }))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone:'}>
                                            <Input 
                                                charLimit={9} 
                                                width={80} 
                                                id='phone' 
                                                type={'text'} 
                                                value={clientsAndSuppliers?.phone ? maskPhone(String(clientsAndSuppliers?.phone)) : ''} 
                                                onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, phone: justNumber(e.target.value) }))}
                                            ></Input>
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'CEP:'}>
                                        <Input 
                                            id='cep' 
                                            onBlur={() => getAddress()} 
                                            type={'text'} 
                                            value={clientsAndSuppliers?.addressCep ? maskCep(String(clientsAndSuppliers?.addressCep)) : ''} 
                                            onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressCep: e.target.value }))}
                                        ></Input>
                                    </LabelInput>
                                    <LabelInput text={'Rua:'}>
                                        <Input charLimit={255} id='addressRoad' type={'text'} value={clientsAndSuppliers?.addressRoad} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressRoad: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Número:'}>
                                        <Input id='addressNumber' type={'text'} value={clientsAndSuppliers?.addressNumber} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressNumber: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Bairro:'}>
                                        <Input charLimit={150} id='addressDistrict' type={'text'} value={clientsAndSuppliers?.addressDistrict} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressDistrict: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Cidade:'}>
                                        <Input charLimit={150} id='addressCity' type={'text'} value={clientsAndSuppliers?.addressCity} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressCity: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Estado:'}>
                                        <Input charLimit={150} id='addressState' type={'text'} value={clientsAndSuppliers?.addressState} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressState: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'País:'}>
                                        <Input charLimit={150} id='addressCountry' type={'text'} value={clientsAndSuppliers?.addressCountry} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, addressCountry: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Inscrição municipal:'}>
                                        <Input charLimit={30} id='stateRegistration' type={'text'} value={clientsAndSuppliers?.stateRegistration} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, stateRegistration: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Inscrição estadual:'}>
                                        <Input charLimit={30} id='municipalRegistration' type={'text'} value={clientsAndSuppliers?.municipalRegistration} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, municipalRegistration: e.target.value }))}></Input>
                                    </LabelInput>
                                    <div className='flex flex-col gap-4 w-full'>
                                        <a className='text-inputPlaceholderLight text-sm'>Tipo</a>
                                        <div className='flex gap-2 w-fit'>
                                            <div className='w-full flex gap-2'>
                                                <InputCheckBox insideCheck={true} onClick={() => setClientsAndSuppliers(prev => ({ ...prev, isSupplier: !clientsAndSuppliers?.isSupplier }))} value={clientsAndSuppliers?.isSupplier}></InputCheckBox>
                                                <a className='text-sm text-inputPlaceholderLight'>Fornecedor</a>
                                            </div>
                                            <div className='w-full flex gap-2'>
                                                <InputCheckBox insideCheck={true} onClick={() => setClientsAndSuppliers(prev => ({ ...prev, isClient: !clientsAndSuppliers?.isClient }))} value={clientsAndSuppliers?.isClient}></InputCheckBox>
                                                <a className='text-sm text-inputPlaceholderLight'>Cliente</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex w-full flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Obsvervação</a>
                                        <textarea id='observation' wrap="hard" value={clientsAndSuppliers?.observation} onChange={(e) => setClientsAndSuppliers(prev => ({ ...prev, observation: e.target.value }))} maxLength={2000} className='
                                                  p-2 border border-gray-400 border-opacity-75 
                                                  h-64
                                                  w-96
                                                  text-sm
                                                  outline-none transition-all duration-200 focus:shadow-borderShadow
                                                  dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                                   '>
                                        </textarea>
                                    </div>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button module={"financialClientsAndSuppliers:delete"} userData={userData} shadow={true} approval={false} onClick={() => setShowUniversalModal(true)}>Excluir</Button>
                            <Button module={"financialClientsAndSuppliers:update"} userData={userData} shadow={true} onClick={() => handleEdit()}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>

    )
}
