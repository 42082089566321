import { useState } from "react"
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { AiOutlineStop } from 'react-icons/ai'
import moment from 'moment'

export function SelectPeriod({ referenceDate, onChange, heigth }){

    const [ startDate, setStartDate ] = useState(moment(referenceDate ?? new Date()).utc(false).startOf('month').format('YYYY-MM-DD'))
    const [ finishDate, setFinishDate ] = useState(moment(referenceDate ?? new Date()).utc(false).endOf('month').format('YYYY-MM-DD'))
    const [ rangeStart, setRangeStart ] = useState('')
    const [ rangeFinish, setRangeFinish ] = useState('')
    const [ openRange, setOpenRange ] = useState(false)
    const [ openRangeEdit, setOpenRangeEdit ] = useState(false)

    return (
        <div className="flex items-center justify-center gap-2 z-50">
            <div className={`flex items-center text-white ${heigth ? `h-[${heigth}]` : 'h-6'}`}>
                <div 
                    className={`bg-primaryDefaultLight flex items-center h-full px-2 rounded-l-md ${openRange ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:opacity-70'}`}
                    onClick={() => {
                        if(!openRange){
                            const start = moment(startDate).utc(false).add(-1, 'month').startOf('month').format('YYYY-MM-DD')
                            const end = moment(startDate).utc(false).add(-1, 'month').endOf('month').format('YYYY-MM-DD')
                            setStartDate(start)
                            setFinishDate(end)
                            onChange({
                                startDate: start, 
                                finishDate: end
                            })
                        }
                    }}
                >
                    <FaAngleLeft/>
                </div>
                <div 
                    className="border-x bg-primaryDefaultLight px-6 cursor-pointer" 
                    onClick={() => {
                        if(openRange == false){
                            setRangeStart(startDate)
                            setRangeFinish(finishDate)
                        }
                        setOpenRange(true)
                        setOpenRangeEdit(true)
                    }}
                >
                    <p>
                        { 
                            !openRange ? moment(startDate).utc(false).format('MM/YYYY')
                                : `${moment(rangeStart).utc(false).format('DD/MM/YYYY')} - ${moment(rangeFinish).utc(false).format('DD/MM/YYYY')}`
                        }
                    </p>
                </div>
                <div
                    className={`bg-primaryDefaultLight flex items-center h-full px-2 rounded-r-md ${openRange ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:opacity-70' }`}
                    onClick={() => {
                        if(!openRange){
                            const start = moment(startDate).utc(false).add(1, 'month').startOf('month').format('YYYY-MM-DD')
                            const end = moment(startDate).utc(false).add(1, 'month').endOf('month').format('YYYY-MM-DD')
                            setStartDate(start)
                            setFinishDate(end)
                            onChange({
                                startDate: start, 
                                finishDate: end
                            })
                        }
                    }}
                >
                    <FaAngleRight/>
                </div>
            </div>
            {
                openRange &&
                <AiOutlineStop className='cursor-pointer dark:text-white' onClick={() => {
                    setOpenRange(false)
                    onChange({
                        startDate,
                        finishDate
                    })
                }}/>
            }
            {
                openRange && openRangeEdit &&
                <div className="absolute mt-44 mr-5 bg-white px-5 py-3 shadow-lg rounded-md flex flex-col gap-2">
                    <div className="flex items-center justify-between gap-3">
                        <p>De:</p> <input type="date" value={rangeStart} onChange={(e) => setRangeStart(e.target.value)}/>
                    </div>
                    <div className="flex items-center justify-between gap-3">
                        <p>Até:</p> <input type="date" value={rangeFinish} onChange={(e) => setRangeFinish(e.target.value)}/>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                        <button 
                            type='button' 
                            className="bg-gray-400 text-white rounded-md px-2 py-1" 
                            onClick={() => {setOpenRangeEdit(false); setOpenRange(false)}}> 
                            Cancelar 
                        </button>
                        <button 
                            type='button' 
                            className="bg-primaryDefaultLight text-white rounded-md px-2 py-1"
                            onClick={() => {
                                onChange({
                                    startDate: rangeStart,
                                    finishDate: rangeFinish
                                })
                                setOpenRangeEdit(false)
                            }}
                        > 
                            Filtrar 
                        </button>
                    </div>
                </div>
            }
        </div>
    )

}
