import {
    FaAddressBook,
    FaUserAlt,
    FaRegCalendarCheck,
    FaPalette,
    FaRunning,
    FaNetworkWired,
    FaUtensils,
    FaCity,
    FaIdBadge,
    FaCalendarAlt,
    FaCloud,
    FaBox,
    FaWrench,
    FaMoneyBill,
    FaBookmark
} from "react-icons/fa";

export const configItems = [
    {
        name: "Minha conta",
        group: "Geral",
        module: 0,
        legend: "Dados da sua empresa",
        icon: <FaAddressBook />,
        permission: [
            1,
            2,
            "customers:read", "customers:update", "customers:delete", "customers:create",
            "calendar:update", "calendar:delete", "calendar:create", "calendar:read",
            "customersAttendance:create", "customersAttendance:read", "customersAttendance:update", "customersAttendance:delete",
            "holidays:update", "holidays:delete","holidays:read", "holidays:create"
        ],
        subItems: [
            {
                path: "/customer",
                name: "Perfil",
                permission: [
                    1,
                    2,
                    "customers:read",
                    "customers:update",
                    "customers:delete",
                    'paymentMethods:create','paymentMethods:update'
                ],
            },
            {
                path: "/holidayCalendar",
                name: "Calendário de feriados",
                permission: [1, 2, "holidays:update", "holidays:delete","holidays:read", "holidays:create"],
            },
            {
                path: "/paymentMethods",
                name: "Formas de pagamento",
                permission: [1, 2, 'paymentMethods:create','paymentMethods:update'],
            },
        ],
    },
    {
        name: "Usuários",
        group: "Geral",
        module: 0,
        legend: "Gerenciar usuários",
        icon: <FaUserAlt />,
        permission: ["users:read", "users:create", 1, 2,
            "users:read", "users:create", "users:update", "users:delete",
            "associations:read", "associations:create", "associations:update", "associations:delete",
            "permissionGroups:read", "permissionGroups:create", "permissionGroups:update", "permissionGroups:delete",
            "users:import", "users:import"
        ],
        subItems: [
            {
                path: "/users",
                name: "Internos",
                permission: ["users:read", "users:create", "users:update", "users:delete", 1, 2],
            },
            {
                path: "/clients",
                name: "Clientes",
                permission: ["users:read", "users:create", "users:update", "users:delete", 1, 2],
            },
            {
                path: "/usersProfile",
                name: "Grupos de Permissões",
                permission: [
                    "permissionGroups:read",
                    "permissionGroups:create",
                    "permissionGroups:update",
                    "permissionGroups:delete",
                    1,
                    2,
                ],
            },
            {
                path: "/sectors",
                name: "Setores",
                permission: [1, 2, "sectors:create","sectors:read", "sectors:update", "sectors:delete"],
            }, 
            {
                path: "/companies",
                name: "Empresas",
                permission: ["comapny:read", "comapny:create", "comapny:update", "comapny:delete", 1, 2],
            },
            {
                path: "/associations",
                name: "Tipos / Associações",
                permission: ["associations:read", "associations:create", "associations:update", "associations:delete", 1, 2],
            },
            {
                path: "/usersImport",
                name: "Importação",
                permission: ["users:import", "users:import", 1, 2],
            },
        ],
    },
    {
        name: "Reservas",
        group: "Gerenciamento da associação",
        module: 1,
        legend: "Gerenciar reservas",
        icon: <FaRegCalendarCheck />,
        permission: [1, 2,
            "categories:read", "categories:update", "categories:create", "categories:delete",
            "services:read", "services:update", "services:create", "services:delete",
            "termsOfUse:read", "termsOfUse:update", "termsOfUse:create", "termsOfUse:delete"
        ],
        subItems: [
            {
                path: "/categories",
                name: "Categorias",
                permission: [1, 2, "categories:read", "categories:update", "categories:create", "categories:delete"]
            },
            {
                path: "/services",
                name: "Serviços e Espaços",
                permission: [1, 2, "services:read", "services:update", "services:create", "services:delete"],
            },
            {
                path: "/customerAttendance",
                name: "Regras de funcionamento",
                permission: [
                    1,
                    2,
                    "customersAttendance:create",
                    "customersAttendance:read",
                    "customersAttendance:update",
                    "customersAttendance:delete",
                ],
            },
            {
                path: "/serviceTermsOfUse",
                name: "Termos de uso",
                permission: [1, 2, "termsOfUse:read", "termsOfUse:update", "termsOfUse:create", "termsOfUse:delete"],
            }
        ],
    },
    {
        name: "Atividades",
        group: "Gerenciamento da associação",
        module: 2,
        legend: "Gerenciar atividades e planos",
        icon: <FaRunning />,
        permission: [1, 2, "activities:read", "activities:update", "activities:create", "activities:delete"],
        subItems: [
            {
                path: "/activities",
                name: "Atividades",
                permission: [1, 2, "activities:read", "activities:update", "activities:create", "activities:delete"],
            },
            {
                path: "/activitiesPlans",
                name: "Planos",
                permission: [1, 2, "activities:read", "activities:update", "activities:create", "activities:delete"],
            },
        ],
    },
    {
        name: "Integrações",
        group: "Geral",
        module: 0,
        legend: "Gerenciar gateways",
        icon: <FaNetworkWired />,
        permission: ["paymentGateway:read", "paymentGateway:create", "paymentGateway:update", "paymentGateway:delete", 1, 2],
        subItems: [
            {
                path: "/paymentGatewayConfig",
                name: "Gateway de pagamento",
                permission: ["paymentGateway:read", "paymentGateway:create", "paymentGateway:update", "paymentGateway:delete", 1, 2],
            },
        ],
    },
    {
        name: "Convênios",
        group: "Geral",
        module: 0,
        legend: "Gerenciar convênios",
        icon: <FaIdBadge />,
        permission: ["agreements:read", "agreements:create", "agreements:update", "agreements:delete", 1, 2],
        subItems: [
            {
                path: "/agreements",
                name: "Convênios",
                permission: ["agreements:read", "agreements:create", "agreements:update", "agreements:delete", 1, 2],
            },
        ],
    },
    {
        name: "Eventos",
        group: "Gerenciamento da associação",
        module: 1,
        legend: "Gerenciar eventos",
        icon: <FaCalendarAlt />,
        permission: ["events:read", "events:create", "events:update", "events:delete", 1, 2],
        subItems: [
            {
                path: "/events",
                name: "Eventos",
                permission: ["events:read", "events:create", "events:update", "events:delete", 1, 2],
            },
        ],
    },
    {
        name: "Estrutura",
        group: "Gerenciamento da associação",
        module: 1,
        legend: "Gerenciar estrutura",
        icon: <FaCity />,
        permission: ["structures:read", "sctructures:create", "structures:update", "structures:delete", 1, 2],
        subItems: [
            {
                path: "/structures",
                name: "Estrutura",
                permission: ["structures:read", "sctructures:create", "structures:update", "structures:delete", 1, 2],
            },
        ],
    },
    {
        name: "Gastronomia",
        group: "Gerenciamento da associação",
        module: 1,
        legend: "Gerenciar cadastros de gastronomias",
        icon: <FaUtensils />,
        permission: [1, 2, "gastronomy:create", "gastronomy:read", "gastronomy:update", "gastronomy:delete"],
        subItems: [
            {
                path: "/gastronomy",
                name: "Gastronomia",
                permission: [1, 2, "gastronomy:create", "gastronomy:read", "gastronomy:update", "gastronomy:delete"],
            },
        ],
    },
    {
        name: "Temas",
        group: "Geral",
        module: 0,
        legend: "Personalizar cores",
        icon: <FaPalette />,
        permission: [1, 2, 3],
        subItems: [
            {
                path: "/theme",
                name: "Meu tema",
                permission: [1, 2, 3],
            },
            {
                path: "/defaultTheme",
                name: "Tema padrão",
                permission: [1, 2],
            },
        ],
    },
    {
        name: "Integração site",
        group: "Geral",
        module: 0,
        legend: "gerenciar integração do site se disponível",
        icon: <FaCloud />,
        permission: [1, 2, "siteBanners:create", "siteBanners:red", "siteBanners:update", "siteBanners:delete"],
        subItems: [
            {
                path: "/siteBanners",
                name: "Banners e avisos",
                permission: [1, 2, "siteBanners:create", "siteBanners:red", "siteBanners:update", "siteBanners:delete"],
            },
        ],
    },
    //financeiro
    {
        name: "Gestão",
        group: "Financeiro",
        module: 3,
        legend: "Gerenciar dados para DRE e relatórios financeiros",
        icon: <FaMoneyBill />,
        permission: [1, 2, "financialCostCenter"],
        subItems: [
            {
                path: "/financial/sectors",
                name: "Dashboard",
                permission: [1, 2, "financialBills:create","financialBills:read", "financialBills:update", "financialBills:delete"],
            },
            {
                path: "/financial/cashFlow",
                name: "Fluxo de Caixa",
                permission: [1, 2, "financialCashFlow:read"],
            },
            {
                path: "/financial/bills/sales",
                name: "Vendas",
                permission: [1, 2, "financialBills:create","financialBills:read", "financialBills:update", "financialBills:delete"],
            },
            {
                path: "/financial/bills/shopping",
                name: "Compras",
                permission: [1, 2, "financialBills:create","financialBills:read", "financialBills:update", "financialBills:delete"],
            },
        ],
    }, {
        name: "Produtos e Serviços",
        group: "Financeiro",
        module: 3,
        legend: "Gerenciar dados para o módulo financeiro",
        icon: <FaBox />,
        permission: [1, 2, "financialCostCenter"],
        subItems: [
            {
                path: "/financial/products",
                name: "Produtos",
                permission: [1, 2, "financialProducts:create","financialProducts:read", "financialProducts:update", "financialProducts:delete"],
            },
            {
                path: "/financial/services",
                name: "Serviços",
                permission: [1, 2, "financialServices:create","financialServices:read", "financialServices:update", "financialServices:delete"],
            },
            {
                path: "/financial/patrimony",
                name: "Controle de patrimônios",
                permission: [1, 2, "financialPatrimony:create","financialPatrimony:read", "financialPatrimony:update", "financialPatrimony:delete"],
            }
        ],
    },
    {
        name: "Cadastros",
        group: "Financeiro",
        module: 3,
        legend: "Gerenciar dados para DRE e relatórios financeiros",
        icon: <FaBookmark />,
        permission: [1, 2, "financialCostCenter"],
        subItems: [
            {
                path: "/financial/clientsAndSuppliers",
                name: "Clientes e fornecedores",
                permission: [1, 2, "financialClientsAndSuppliers:create","financialClientsAndSuppliers:read", "financialClientsAndSuppliers:update", "financialClientsAndSuppliers:delete"],
            },
            {
                path: "/financial/categories",
                name: "Categorias de receitas e despesas",
                permission: [1, 2, "financialCategories:create","financialCategories:read", "financialCategories:update", "financialCategories:delete"],
            },  
            {
                path: "/financial/costCenter",
                name: "Centro de custos",
                permission: [1, 2, "financialCostCenter:create","financialCostCenter:read", "financialCostCenter:update", "financialCostCenter:delete"],
            },
            {
                path: "/financial/accounts",
                name: "Contas e Bancos",
                permission: [1, 2, "financialAccounts:create","financialAccounts:read", "financialAccounts:update", "financialAccounts:delete"],
            }
        ],
    },
    {
        name: "Parametrizações",
        group: "Financeiro",
        module: 3,
        legend: "Configurações de lógicas e rotinas do financeiro",
        icon: <FaWrench />,
        permission: [1, 2, "financialCostCenter", "financialRules", "financialConfigPixQrCode"],
        subItems: [
            {
                path: "/financial/config/associationsMonthlyPayment",
                name: "Mensalidade de associação",
                permission: [1, 2, "financialCharges:create","financialCharges:read", "financialCharges:update", "financialCharges:delete"],
            },
            {
                path: "/financial/config/rules",
                name: "Regras",
                permission: [1, 2, "financialRules:create","financialRules:read", "financialRules:update", "financialRules:delete"],
            },
            {
                path: "/financial/config/configPixQrCode",
                name: "Pix - Qr code",
                permission: [1, 2, "financialConfigPixQrCode:create","financialConfigPixQrCode:read", "financialConfigPixQrCode:update", "financialConfigPixQrCode:delete"],
            },
        ],
    },
]
