import React, { useEffect, useState } from "react";
import { Container } from "../../../../components/container/container";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Title } from "../../../../components/titlePages/title";
import { Body } from "../../../../components/container/Body";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/button.default";
import { useAuth } from "../../../../contexts/useAuth";
import { InputAutoComplete } from "../../../../components/input/input.autocomplete";
import { DefaultLoader } from "../../../../components/loaders/defaultLoader";
import moment from "moment";
import { useThemeContext } from "../../../../contexts/themeContext";
import { ScreenModal } from "../../../../components/modals/notification/screenModal";
import { UsersToFind } from "../../../../components/table/usersToFind.table";
import { responseError } from "../../../../utils/responsesFunctions/error.response";
import Multiselect from "../../../../components/selects/mult.select";
import { useScreenSizeContext } from "../../../../contexts/screenSizeContext";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";


export function PaymentsReport() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()

    const [loading, setLoading] = useState(false)

    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedFinishDate, setSelectedFinishDate] = useState('')
    const [selectedCreatedAt, setsSelectedCreatedAt] = useState('')
    const [selectedScheduleStatus, setSelectedScheduleStatus] = useState('')
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([])
    const [selectedReportType, setSelectedReportType] = useState({ id: 0, name: 'Excel', extension: 'xlsx' })
    const [selectedDateType, setSelectedDateType] = useState({ id: "C", name: "Pela data de criação" })

    const paymentStatusList = [
        { name: 'Aguardando pagamento', id: 3 },
        { name: 'Pago', id: 1 },
        { name: 'Cancelado', id: 2 },
    ]

    async function getReport() {
        if (moment(selectedFinishDate).diff(moment(selectedStartDate), 'days') > 31) {

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Perído máximo é de 31 dias')
        }
        try {
            setLoading(true)
            const report = await defaultCallsSchedules.getFilteredPaymentsReport(userData[0]?.token, [
                { original_name: 'startDate', value: selectedStartDate },
                { original_name: 'finishDate', value: selectedFinishDate },
                { original_name: 'created_at', value: selectedCreatedAt },
                { original_name: 'dateType', value: selectedDateType?.id },
                { original_name: 'paymentStatus', value: selectedPaymentStatus?.length > 0 ? selectedPaymentStatus : [] },
                { original_name: 'reportType', value: selectedReportType?.name ? selectedReportType.name : '' },
            ])
            if (report.data.status === 204) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Nenhum dado encontrado para esta pesquisa')
                setLoading(false)
                return
            }
            setLoading(false)
            await new Promise(resolve => setTimeout(resolve, 100))
            const blob = report.data // Seu Blob recebido
            const blobURL = URL.createObjectURL(blob);

            const downloadLink = document.getElementById('downloadLink');
            downloadLink.href = blobURL;
            downloadLink.download = `relatorio_reservas-${moment().format("DD/MM/YYYY")}_
            ${moment().hour()}_${moment().minutes()}_${moment().seconds()}.${selectedReportType?.extension}`;
            downloadLink.click();

            return URL.revokeObjectURL(blobURL);

        } catch (error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
            setLoading(false)
        }
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Relatórios de pagamentos'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-2 w-full">
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete preSelectedValue={selectedDateType?.name}
                                        data={[{ id: "C", name: 'Pela data de Criação' }, { id: "P", name: 'Pela data de pagamento' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedDateType(e)} />
                                </LabelInput>
                                <LabelInput text={'Data início'}>
                                    <Input type={'date'} value={selectedStartDate} onChange={(e) => { setSelectedStartDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data fim'}>
                                    <Input type={'date'} value={selectedFinishDate} onChange={(e) => { setSelectedFinishDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <div className="flex items-start justify-start` w-full">
                                    <LabelInput text={'Status de pagamento'}>
                                        <Multiselect
                                            width={screenX < 640 ? '[100%]' : ''}
                                            items={paymentStatusList}
                                            mult
                                            value={selectedPaymentStatus}
                                            onChange={(e) => {
                                                setSelectedPaymentStatus(e)
                                            }}
                                            selectedLabel={'name'}
                                            id={`selectedPaymentStatus`} />
                                    </LabelInput>
                                </div>
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete preSelectedValue={selectedReportType?.name} data={[{ id: 0, name: 'Excel', extension: 'xlsx' }, { id: 1, name: 'PDF', extension: 'pdf' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedReportType(e)} />
                                </LabelInput>
                                <a id="downloadLink"></a>
                                <div className="w-full sm:w-48 mt-6">
                                    <Button onClick={() => getReport()}>Download</Button>

                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}