import { useState, useEffect, useRef } from 'react'
import { useThemeContext } from "../../contexts/themeContext"
import { DefaultLoader } from "../loaders/defaultLoader";
import { FaAngleLeft, FaAngleRight, FaEdit, FaSearch } from 'react-icons/fa';
import { TbArrowsSort, TbSortAscending,  TbSortDescending } from 'react-icons/tb';
import { responseError } from '../../utils/responsesFunctions/error.response';
import { useScreenSizeContext } from '../../contexts/screenSizeContext';
import moment from 'moment';
import { useApiContext } from '../../contexts/ApiInterceptorContext';

/**
 * Função que cria tabela com totais.
 * @component
 * @param {Object} props - Objeto contendo filtros.
 * @param {string} props.endpoint // enpoint da api que deve ser chamada
 * @param {string} props.token // token de autenticação
 * @param {'financial' | 'schedules' | 'activitiesSubscritions' | 'auth'} props.module - módulo do sistema para API buscar informação
 * @param {string[]} props.mapApiData - mapeia onde está o conteudo da tabela/body no objeto da resposta
 * @param {Object[]} props.columns - Colunas da tabela
 *      @param {'number' | 'date' | 'datetime' | 'string' | 'boolean' | 'money' | 'status'} props.columns[].type - tipo da dado da coluna.
 *      @param {string} props.columns[].description - descrição da coluna.
 *      @param {string} props.columns[].field - campo da coluna (se não informado o newFieldFunctionValue, é obrigatório).
 *      @param {Function} props.columns[].newFieldValue - função que pode gerar novo valor com base em demais campos da linha. Se usado remover o "field".
 *      @param {'yellow' | 'red' | 'green' | 'blue' | 'gray'} props.columns[].color - descrição da coluna.
 *      @param {boolean} props.columns[].withOrderBy - descrição da coluna.
 * @param {Object} props.filters - campo a ser filtrado.
 * @param {Object[]} props.totals - Colunas da tabela
 *      @param {'number' | 'date' | 'datetime' | 'string' | 'boolean' | 'money' | 'status'} props.totals[].type - tipo da dado da coluna.
 *      @param {string} props.totals[].description - descrição da coluna.
 *      @param {string[]} props.totals[].mapValue - campo da coluna.
 * @param {Object[]} props.staticData
 * @param {boolean} props.usePagination
 * @param {string[]} props.mapQuantityOfRegisters
 * @param {string[]} props.mapTotalPages
 * @param {boolean} props.withSelection
 * @param {boolean} props.disabledSelection
 * @param {Function} props.onSelect
 * @param {string} props.selectValidatorField
 * @param {Function} props.onClickInTableLine
 * @returns {JSX.Element} Um componente de tabela personalizado.
 */
export function NewTableDefault({ 
    endpoint, 
    token, 
    module, 
    columns = [], 
    mapApiData = [], 
    filters, 
    totals = [], 
    staticData = [],
    usePagination,
    mapQuantityOfRegisters = [],
    mapTotalPages = [],
    onClickInTableLine,
    onSelect = [],
    selectValidatorField = 'id',
    withSelection = false,
    disabledSelection = false
}) {
    const { api, api_financial, api_auth } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenY } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const [ loading, setLoading ] = useState(false)
    const [ loadingTotal, setLoadingTotal ] = useState(false)
    const [ filterHistory, setFilterHistory ] = useState('')
    const [ page, setPage ] = useState(1)
    const [ tableData, setTableData ] = useState([])
    const [ totalData, setTotalData ] = useState([])
    const [ quantityOfRegisters, setQuantityOfRegisters ] = useState(null)
    const [ totalPages, setTotalPages ] = useState(null)
    const [ filteredDataOnTable, setFilteredDataOnTable ] = useState([])
    const [ basicFilter, setBasicFilter ] = useState('')
    const [ selecteds, setSelecteds ] = useState([])
    const [ allSelected, setAllSelected ] = useState(false)
    const [ direction, setDirection ] = useState('DESC')
    const [ orderBy, setOrderBy ] = useState('')

    useEffect(() => {
        if (filters && page && endpoint && token){
            getDataApi()
            return
        } 
        if (staticData?.length > 0) {
            usePagination = false
            setTableData(staticData)
            return
        }
    }, [page, filters, endpoint, token, orderBy, direction])

    async function getDataApi(){
        setLoading(true)
        try {
            const api_origin = identifyModule()
            const filter = formatFilterAndOrdination()
            if(filterHistory != filter && totals){
                setLoadingTotal(true)
                setFilterHistory(filter)
            }
            const api_endpoint = endpoint + '?' + (usePagination ? `page=${page}&` + filter : filter)
            const response = await api_origin.get(api_endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            mapAndSetTableData(response.data)
            mapAndSetTotalData(response.data)
            if(usePagination){
                mapAndSetQuantityOfRegisters(response.data)    
                mapAndSetTotalPages(response.data)        
            }
        } catch (error) {
            const errorResponse = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(errorResponse?.length > 0 ? errorResponse : 'Erro inesperado')
        } finally {
            setLoading(false)
            setLoadingTotal(false)
        }
    }

    function identifyModule(){
        if(module == 'activitiesSubscritions') return api
        if(module == 'schedules') return api
        if(module == 'events') return api
        if(module == 'gastronomy') return api
        if(module == 'auth') return api_auth
        if(module == 'financial') return api_financial
    }

    function formatFilterAndOrdination(){
        const formatFilter = []
        if(Object.keys(filters)?.length > 0) {
            Object.keys(filters)?.map(filter => {
                formatFilter.push(`${filter}=${filters[filter]?.toString()?.replace(' ', '%')}`)
            })
        }
        if(orderBy && ['ASC', 'DESC'].includes(direction)){
            formatFilter.push(`orderBy=${orderBy}&direction=${direction}`)
        }
        return formatFilter?.length > 0 ? formatFilter.join('&') : ''
    }

    function mapAndSetTableData(apiResponseData){
        if(mapApiData.length > 0){ // mapeia no objeto da resposta o DATA
            const value = utilsMapObjectFinder(apiResponseData, mapApiData)
            setTableData(value)
        } else { // default se não informar
            setTableData(apiResponseData.data)
        }
    }

    function mapAndSetTotalData(apiResponseData){
        if(totals.length > 0){//trativa dos totais com base no mapvalue
            try{
                const totalList = totals.map(total => {
                    if(!total.mapValue){
                        throw new Error(`Não foi mapeado a origem do total '${total.description}'.`)
                    }
                    const value = utilsMapObjectFinder(apiResponseData, total.mapValue)
                    total.value = value
                    return total
                })
                setTotalData(totalList)
            }catch(error){
                setTotalData([])
                console.error('NewTableDefault, property: totals -', error.message)
            }
        }
    }

    function mapAndSetQuantityOfRegisters(apiResponseData){
        if(mapQuantityOfRegisters.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapQuantityOfRegisters)
                setQuantityOfRegisters(value)
            } catch (error) {
                setQuantityOfRegisters(null)
                console.error('NewTableDefault, property: mapQuantityOfRegisters -', error.message)
            }
        }
    }

    function mapAndSetTotalPages(apiResponseData){
        if(mapTotalPages.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapTotalPages)
                setTotalPages(value)
            } catch (error) {
                setTotalPages(null)
                console.error('NewTableDefault, property: mapTotalPages -', error.message)
            }
        }
    }

    function utilsMapObjectFinder(object, mapSequence){
        let value = object
        for (const key of mapSequence) {
            if (value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                throw new Error(`Chave '${key}' não encontrada nos dados.`)
            }
        }
        return value
    }

    function formatColumnValue(type, value){
        switch (type) {
            case 'money':
                return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

            case 'date':
                return moment(value).format('DD/MM/YYYY')

            case 'datetime':
                return moment(value).format('DD/MM/YYYY hh:mm:ss')

            case 'boolean': 
                return value == true ? 'Sim' : 'Não'

            case 'status': 
                return value == true ? 'Ativo' : 'Inativo'

            default:
                return value
        }
    }

    function PaginationTotalPages(){
        const arrayPagesPositions = Array.apply(null, Array(totalPages))
        return (
            <div className='flex justify-center items-center gap-4' key={'pages'}>
                <FaAngleLeft 
                    className={`${page == 1 ? 'cursor-not-allowed' : 'cursor-pointer'} text-white`}
                    size={16}
                    onClick={() => setPage((prev) => (prev == 1 ? 1 : prev - 1))}
                />
                {
                    arrayPagesPositions.map((item, index) => {
                        const button = <button 
                            className={`hover:text-gray-400 ${index+1 == page && 'underline'} text-sm`}
                            onClick={() => setPage(index + 1)}
                            key={`page-${index}`}
                        >{index + 1}</button>
                        let existsPoints = false
                        let showPointers = false

                        if(totalPages < 5){
                            return button
                        }else{
                            existsPoints = true
                            if(index == 0 || index + 1 == totalPages){
                                return button
                            }else if( page + 2 > index + 1 && page - 2 < index + 1){
                                return button
                            }else{
                                if (page + 2 < index + 1 || page - 2 > index + 1){
                                    showPointers = true
                                }
                                if (existsPoints && showPointers == false){
                                    return <a className='flex items-center'>...</a>
                                }
                            }
                        }
                            
                    })
                }
                <FaAngleRight 
                    className={`${arrayPagesPositions?.length == page ? 'cursor-not-allowed' : 'cursor-pointer'} text-white`}
                    size={16}
                    onClick={() => setPage((prev) => (arrayPagesPositions?.length == page ? prev : prev + 1))}
                />
            </div>
        )
    }
    
    function BasicPagination({size}){
        return (
            <div className='flex justify-center items-center gap-4'>
                <FaAngleLeft 
                    className={`${page == 1 ? 'cursor-not-allowed' : 'cursor-pointer'} text-titleGrayTextLight dark:text-primaryDefaultLight`}
                    size={size ?? 16}
                    onClick={() => setPage((prev) => (prev == 1 ? 1 : prev - 1))}
                />
                <p className='text-sm text-titleGrayTextLight dark:text-primaryDefaultLight'>Página: {page}</p>
                <FaAngleRight 
                    className={`${tableData.length == 0 ? 'cursor-not-allowed' : 'cursor-pointer'} text-titleGrayTextLight dark:text-primaryDefaultLight`}
                    size={size ?? 16}
                    onClick={() => setPage((prev) => (tableData.length == 0 ? prev : prev + 1))}
                />
            </div>
        )
    }

    function validAllSelectedInTableData() {
        let allChecked = true;
        
        for (let i = 0; i < tableData.length; i++) {
            const data = tableData[i]
            
            if (!selecteds.find(s => s[selectValidatorField] === data[selectValidatorField])) {
                allChecked = false
                break
            }
        }
        return allChecked
    }
    
    useEffect(() => {
        if(!basicFilter){
            setFilteredDataOnTable(tableData)
        }else{
            const filtered = structuredClone(tableData).filter((data) => {
                return Object.values(data).some(value => 
                    String(value)?.toLocaleLowerCase()?.includes(basicFilter?.toLocaleLowerCase())
                )
            })
            setFilteredDataOnTable(filtered)
        }
    }, [basicFilter, tableData])

    useEffect(() => {
        if(withSelection){
            onSelect(selecteds)
            setAllSelected(validAllSelectedInTableData())
        }
    }, [selecteds, tableData])

    function Table(){
        function Thead(){
            return <thead className='w-full'>
                {loadingTotal && totals.length > 0 && // loading dos totais
                    <tr className='border-b'>
                        <th colSpan={columns.length}>
                            <div className={`w-full h-14 flex justify-center items-center`}>
                                Carregando...
                            </div>
                        </th>
                    </tr>
                }
                {totalData.length > 0 && !loadingTotal && // totais
                    <tr className='border-b dark:border-b-secondaryBorderDark'>
                        <th colSpan={withSelection ? columns.length + 1 : columns.length}>
                            <div className={`grid ${gridOptions[`${totalData.length}`]}`}>
                                {
                                    totalData.map((total, index) => {
                                        let formattedValue = '-'
                                        if(![undefined, null].includes(total?.value)){
                                            formattedValue = formatColumnValue(total?.type, total?.value)
                                        }
                                        return (
                                            <div key={'total-'+index} className='col-span-1 py-2'>
                                                <h3 className={`text-md ${textColorOptions[total?.color ?? 'default']}`}>
                                                    {total?.description}
                                                </h3>
                                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{formattedValue}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </th>
                    </tr>   
                }
                {!usePagination && // se não usar paginação exibir input filter
                    <tr className='py-2 bg-primaryDefaultLight text-white dark:text-primaryDefaultLight dark:bg-primaryDefaultDark border-b dark:border-b-secondaryBorderDark'>
                        <th colSpan={withSelection ? columns.length + 1 : columns.length}>
                            <div className='w-full h-full py-4 px-4 flex items-center gap-3 text-base'>
                                <input 
                                    tabIndex={0}
                                    autoFocus={basicFilter ? true : false}
                                    type="text" 
                                    placeholder='Pesquisar'
                                    className='p-0.5 px-1 text-black dark:text-white font-normal w-72 bg-white dark:bg-secondaryDefaultDark border dark:border-secondaryBorderDark' 
                                    value={basicFilter}
                                    onChange={(e) => {setBasicFilter(e.target.value)}}
                                />
                                <FaSearch/>
                            </div>
                        </th>
                    </tr>
                }
                {/* Geração das colunas das tabelas */}
                <tr className='py-2 bg-primaryDefaultLight text-white dark:text-primaryDefaultLight dark:bg-primaryDefaultDark border-b dark:border-b-secondaryBorderDark'>
                    {withSelection && tableData.length > 0 && // se usar seleção
                        <th >
                            <div className='flex items-center justify-center'>  
                                <input 
                                    type='checkbox'
                                    disabled={disabledSelection} 
                                    className='cursor-pointer w-[17px] h-[17px] my-1' 
                                    checked={allSelected}
                                    onChange={(e) => {
                                        const allChecked = validAllSelectedInTableData()
                                        tableData.map((data) => {
                                            const position = selecteds.findIndex(item => {
                                                return item[selectValidatorField] === data[selectValidatorField]
                                            })
                                            if(allChecked){
                                                selecteds.splice(position, 1)
                                            } 
                                            if(position == -1){
                                                selecteds.push(data)
                                            }
                                        })
                                        setSelecteds([...selecteds])
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </th>
                    }
                    {columns.map((column, index) => { //gera columns passadas por parametros
                        return (
                            <th 
                                key={'th-'+index} 
                                className='text-md' 
                                onClick={()=> {
                                    if(column.withOrderBy){
                                        setOrderBy(column.field)
                                        if(direction == 'DESC') setDirection('ASC')
                                        if(direction == 'ASC') setDirection('DESC')
                                    }
                                }}
                            >
                                <div className='w-full flex items-center justify-center'>
                                    <div className={`flex w-fit items-center justify-center gap-2 ${column?.withOrderBy && 'cursor-pointer'}`}>
                                        <p>{column.description}</p>
                                        {column?.withOrderBy && orderBy !== column.field &&
                                            <TbArrowsSort className='text-white'/>
                                        }
                                        {
                                            column?.withOrderBy && orderBy === column.field && direction === 'ASC' &&
                                            <TbSortAscending className='text-white'/>
                                        }
                                        {
                                            column?.withOrderBy && orderBy === column.field && direction === 'DESC' &&
                                            <TbSortDescending className='text-white'/>
                                        }
                                    </div>
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
        }

        function Tbody(){
            return (
                <tbody>
                    {loading && // loading dados da tabela
                        <tr>
                            <td colSpan={columns.length} className={`text-sm text-center py-1`}>
                                <div>
                                    <DefaultLoader/>
                                </div>
                            </td>
                        </tr>
                    }
                    {tableData.length > 0 && !loading && // Carregamento dos dados da tabela
                        filteredDataOnTable.map((data, row) => {
                            return (
                                <tr className={`${row%2 ? 'bg-gray-100 dark:bg-zinc-700' : ''} ${onClickInTableLine && 'cursor-pointer'}`}
                                    onClick={() => typeof onClickInTableLine == 'function' && onClickInTableLine(data)}
                                    key={`tr-${row}`} 
                                >
                                    {withSelection && // se usar seleção
                                        <td>
                                            <div className='flex items-center justify-center'>
                                                <input 
                                                    type="checkbox"
                                                    disabled={disabledSelection} 
                                                    className='cursor-pointer z-50 w-[17px] h-[17px] my-1' 
                                                    checked={selecteds.find(s => s[selectValidatorField] === data[selectValidatorField])}
                                                    onChange={(e) => {
                                                        if(e.target.checked){
                                                            return setSelecteds(prev => [...prev, data])
                                                        }
                                                        const findIndexObj = selecteds.findIndex(s => s === data)
                                                        selecteds.splice(findIndexObj, 1)
                                                        return setSelecteds([...selecteds])
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                />    
                                            </div>
                                        </td>
                                    }
                                    {columns.map((column, index) => {
                                        const field = typeof column?.newFieldValue == 'function' ? column?.newFieldValue(data)
                                            : column.field ? data[column.field] 
                                            : null 
                                        let formattedValue = '-'
                                        if(![undefined, null].includes(field)){
                                            formattedValue = formatColumnValue(column?.type, field)
                                        }
                                        return (
                                            <td key={`td-${index}`} className={`text-sm text-center py-1`}>
                                                {formattedValue}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    }
                    {tableData.length == 0 && !loading && // Se não houver nenhuma linha retornada da API
                        <tr>
                            <td colSpan={withSelection ? columns.length + 1 : columns.length} className='text-center'> 
                                Nenhum registro foi encontrado.
                            </td>
                        </tr>
                    }   
                </tbody>
            )
        }

        function Tfoot(){
            return <tfoot>
                {usePagination && loading &&
                    <tr className='bg-primaryDefaultLight'>
                        <td colSpan={withSelection ? columns.length + 1 : columns.length}>
                            <div className='w-full h-6 flex px-2 justify-between items-center'/>
                        </td>
                    </tr>
                }
                {usePagination && !loading &&
                    <tr className='bg-primaryDefaultLight dark:bg-primaryDefaultDark text-titleGrayTextLight dark:text-titleGrayTextDark border-t dark:border-t-secondaryBorderDark'>
                        <td colSpan={withSelection ? columns.length + 1 : columns.length}>
                            <div className='w-full h-full flex px-2 justify-between items-center'>
                                {
                                    quantityOfRegisters && 
                                    <p className='text-sm'>Total de Registros: {quantityOfRegisters}</p>
                                }
                                {
                                    withSelection && selecteds.length > 0 &&
                                    <p>{selecteds.length} linhas selecionadas</p>
                                }
                                {
                                    !!totalPages && 
                                    <PaginationTotalPages/>
                                }
                                {
                                    !totalPages &&
                                    <BasicPagination/>
                                }
                            </div>
                        </td>
                    </tr>
                }
            </tfoot>
        }

        return (
            <div>
                <table className='w-full select-none border-collapse table-auto border border-gray-300 dark:border dark:border-secondaryBorderDark'>
                    <Thead/>
                    <Tbody/>
                    <Tfoot/>
                </table>
            </div>
        )
    }

    function MobileList(){
        
        function Totals(){
            return (
                <div className='w-full flex items-center justify-center'>
                     {totalData.length > 0 && !loadingTotal &&
                        <div className={`grid ${gridOptions[`${totalData.length}`]} gap-3`}>
                            {
                                totalData.map((total, index) => {
                                    let formattedValue = '-'
                                    if(![undefined, null].includes(total?.value)){
                                        formattedValue = formatColumnValue(total?.type, total?.value)
                                    }
                                    return (
                                        <div key={'total-'+index} className='col-span-1 py-2'>
                                            <h3 className={`text-md ${textColorOptions[total?.color ?? 'default']}`}>
                                                {total?.description}
                                            </h3>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{formattedValue}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            )
        }

        function TableMobile(){
            return filteredDataOnTable.map(data => {
                return (
                    <table className='w-full select-none border-collapse table-auto border border-gray-300 dark:border dark:border-secondaryBorderDark rounded-md'>
                        <tbody>
                            {
                                columns.map(column => {
                                    const field = column.field ? data[column.field] 
                                        : typeof column?.newFieldValue == 'function' ? column?.newFieldValue(data) 
                                        : null 
                                    let formattedValue = '-'
                                    if(![undefined, null].includes(field)){
                                        formattedValue = formatColumnValue(column?.type, field)
                                    }
                                    return (
                                        <tr className='bg-slate-100'>
                                            <td className='border-none text-primaryDefaultLight dark:bg-primaryDefaultDark px-2 py-1 mt-1'>
                                                {column.description}
                                            </td>
                                            <td className='px-2 dark:bg-secondaryDefaultDark'>{formattedValue}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                onClickInTableLine && 
                                <tr className='bg-primaryDefaultLight dark:bg-primaryDefaultDark my-0.5 text-white'>
                                    <td className='' colSpan={2} onClick={() => onClickInTableLine(data)}>
                                        <div className='h-1 dark:border-t dark:border-secondaryBorderDark'></div>
                                        <p className='flex gap-2 items-center justify-center dark:text-primaryDefaultLight py-1'>Editar <FaEdit/></p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                )
            })
        }

        function Pagination(){
            return (
                <>
                    {
                        usePagination && !loading &&
                        <div className='bg-primaryDefaultLight dark:bg-primaryDefaultDark text-titleGrayTextLight dark:text-titleGrayTextDark border-t dark:border-t-secondaryBorderDark mt-3 mb-1'>
                            <div className='w-full h-full flex px-2 justify-between items-center py-1'>
                                {
                                    quantityOfRegisters && 
                                    <p className='text-base'>Total de Registros: {quantityOfRegisters}</p>
                                }
                                {
                                    !!totalPages && 
                                    <PaginationTotalPages/>
                                }
                                {
                                    !totalPages &&
                                    <BasicPagination/>
                                }
                            </div>
                        </div>
                    }
                </>
            )
        }
        
        return (
            <div className='flex flex-col gap-2 w-full'>
                {
                    !usePagination &&
                    <div className='flex items-center gap-2'>
                        <input 
                            tabIndex={0}
                            autoFocus={basicFilter ? true : false}
                            type="text" 
                            placeholder='Pesquisar'
                            className='p-0.5 px-1 text-black dark:text-white font-normal w-72 bg-white dark:bg-secondaryDefaultDark border dark:border-secondaryBorderDark' 
                            value={basicFilter}
                            onChange={(e) => {setBasicFilter(e.target.value)}}
                        />
                        <FaSearch/>
                    </div>
                }
                <Totals/>
                <TableMobile/>
                <Pagination/>
            </div>
        )
        
    }

    const gridOptions = {
        '1': 'grid-cols-1', 
        '2': 'grid-cols-2', 
        '3': 'grid-cols-3', 
        '4': 'grid-cols-4', 
        '5': 'grid-cols-5', 
        '6': 'grid-cols-6', 
        '7': 'grid-cols-7', 
        '8': 'grid-cols-8', 
        '9': 'grid-cols-9', 
        '10': 'grid-cols-10', 
        '11': 'grid-cols-11', 
        '12': 'grid-cols-12', 
    }

    const textColorOptions = {
        'yellow': 'text-yellow-500',
        'red': 'text-red-700',
        'green': 'text-green-700',
        'blue': 'text-sky-700',
        'gray': 'text-zinc-400',
        'default': 'text-zinc-600',
    }

    return (
        <div className='relative' style={{ maxHeight: screenY - 600 + 'px' }}>
            <div className="bg-white dark:bg-secondaryDefaultDark w-full h-full text-titleBlackTextLight dark:text-titleGrayTextDark">
                {screenX <= 750 && <MobileList/>}
                {screenX > 750 && <Table/>}
            </div>
        </div>
    )
}
