import { useNavigate, useParams } from "react-router-dom"
import { Container } from "../../../components/container/container"
import { Title } from "../../../components/titlePages/title"
import { TitlePage } from "../../../components/titlePages/title.page"
import { useAuth } from "../../../contexts/useAuth"
import { useEffect, useState } from "react"
import { Body } from "../../../components/container/Body"
import { Footer } from '../../../components/footer/Footer'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from "../../../components/label/label.input"
import { DefaultLoader } from "../../../components/loaders/defaultLoader"
import { InputAutoComplete } from "../../../components/input/input.autocomplete"
import { Input } from "../../../components/input/input"
import { Toogle } from "../../../components/toogle/Toogle"
import { validateFields } from '../../../utils/form.validator'
import { useThemeContext } from "../../../contexts/themeContext"
import PIX from "react-qrcode-pix";
import html2Canvas from 'html2canvas'
import { maskCnpj } from "../../../utils/mask/maskCnpj"
import { maskCpf } from "../../../utils/mask/maskCpf"
import { useApiContext } from "../../../contexts/ApiInterceptorContext"

export function FinancialConfigPixQrCodeEdit() {

    const { api_financial } = useApiContext()
    const navigate = useNavigate()
    const { id } = useParams()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [accounts, setAccounts] = useState([])
    const pixOptions = [
        { id: "Telefone", placeHolder: "Digite o telefone" },
        { id: "Email", placeHolder: "Digite o email" },
        { id: "CPF", placeHolder: "Digite o CPF" },
        { id: "CNPJ", placeHolder: "Digite o CNPJ" },
        { id: "Outro", placeHolder: "Digite a chave pix" },
    ]
    const [formData, setFormData] = useState({
        financialAccounts_id: undefined,
        pixType: '',
        pixTypeValue: '',
        beneficiaryName: '',
        cityOfTransaction: '',
        identifierCode: '',
        active: undefined
    })
    const [showPixQrCode, setShowPixQrCode] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState()

    useEffect(() => {
        setShowPixQrCode(false)
    }, [formData])

    useEffect(() => {
        async function getData() {
            try {
                const accountsData = await api_financial.get('/api/v1/financial/accounts', {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                const configData = (await api_financial.get(`/api/v1/financial/configPixQrCode/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })).data?.data[0]

                setSelectedAccount(configData?.financialAccounts_id)
                setAccounts(accountsData?.data?.data.filter(acc => acc?.accountType === "bank"))
                setFormData({
                    beneficiaryName: configData?.beneficiaryName,
                    cityOfTransaction: configData?.cityOfTransaction,
                    identifierCode: configData?.identifierCode || '',
                    pixType: configData?.pixType,
                    pixTypeValue: configData?.pixTypeValue,
                    active: configData?.active
                })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getData()
    }, [])

    function generatePixQRCode() {
        const requiredFields = [
            { "name": 'pixType', "value": formData?.pixType, "required": true, "type": 'string' },
            { "name": 'pixTypeValue', "value": formData?.pixTypeValue, "required": true, "type": 'string' },
            { "name": 'beneficiaryName', "value": formData?.beneficiaryName, "required": true, "type": 'string' },
            { "name": 'cityOfTransaction', "value": formData?.cityOfTransaction, "required": true, "type": 'string' },
        ]

        try {
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            setShowPixQrCode(true)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    async function downloadQrCodeImage(){
        const element = document.getElementById('pix-qrcode')
        const canvas = await html2Canvas(element)
        const data = canvas.toDataURL('image/png')
        const link = document.createElement('a')
        link.href = data
        link.download = `pix-qrCode_${formData?.pixTypeValue}.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    async function handleSubmit() {
        try {
            setLoading(true)

            await api_financial.put(`/api/v1/financial/configPixQrCode/${id}`, {
                financialAccounts_id: Number(selectedAccount),
                pixType: pixOptions.find(items => items.id == formData?.pixType)?.id,
                pixTypeValue: ['CPF', 'CNPJ'].includes(formData?.pixType) ? formData?.pixTypeValue?.replace(/[^\d]/g, '') : formData?.pixTypeValue,
                beneficiaryName: formData?.beneficiaryName,
                cityOfTransaction: formData?.cityOfTransaction,
                identifierCode: formData?.identifierCode?.toString() || undefined,
                active: formData?.active,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Configuração atualizada com sucesso.")
            navigate('/financial/config/configPixQrCode')
        } catch (error) {
            console.log(error)
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText("Erro ao atualizar configuração.")
        } finally {
            setLoading(false)
        }
    }
    
    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text='Edição de Configuração / Pix - Qr code'></Title>
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading 
                    ?
                    <div className='flex flex-col items-center justify-center w-full h-full'>
                        <DefaultLoader />
                    </div>
                    :
                    <div className="w-full flex flex-col md:flex md:flex-row items-start justify-between">
                        {
                            !!selectedAccount
                            ?
                            <>
                                <form className='w-1/2 flex flex-col gap-4 items-start justify-start sm:justify-center'>
                                    <div className="w-full flex flex-col items-start justify-start">
                                        <a 
                                            onClick={() => setSelectedAccount(undefined)}
                                            className="text-sm text-primaryDefaultLight hover:cursor-pointer hover:brightness-110"
                                        >
                                            Trocar conta
                                        </a>
                                    </div>
                                    <div className="flex flex-col items-end gap-2">
                                        <LabelInput text={'Chave Pix: *'}>
                                            <InputAutoComplete
                                                id={'pixType'}
                                                name={'pixType'}
                                                value={pixOptions.find(items => items.id == formData?.pixType)?.id || ''}
                                                data={pixOptions}
                                                optionList={['id']}
                                                selectedLabel={'id'}
                                                onChange={(e) => { 
                                                    setFormData(prev => ({ ...prev, pixTypeValue: '', pixType: e?.id }))
                                                }}
                                            />
                                        </LabelInput>
                                        {
                                            pixOptions.filter(items => items.id == formData?.pixType).map(op => {
                                                return op.id === 'CNPJ' ?
                                                <Input 
                                                    id={'pixTypeValue'}
                                                    name={'pixTypeValue'}
                                                    value={maskCnpj(formData?.pixTypeValue) || ''}
                                                    placeholder={op?.placeHolder}
                                                    onChange={(e) => setFormData(prev => ({ ...prev, pixTypeValue: e.target.value }))}
                                                /> 
                                                : op.id === "CPF" ? 
                                                <Input 
                                                    id={'pixTypeValue'}
                                                    name={'pixTypeValue'}
                                                    value={maskCpf(formData?.pixTypeValue) || ''}
                                                    placeholder={op?.placeHolder}
                                                    onChange={(e) => setFormData(prev => ({ ...prev, pixTypeValue: e.target.value }))}
                                                />
                                                : <Input 
                                                    id={'pixTypeValue'}
                                                    name={'pixTypeValue'}
                                                    value={formData?.pixTypeValue || ''}
                                                    placeholder={op?.placeHolder}
                                                    onChange={(e) => setFormData(prev => ({ ...prev, pixTypeValue: e.target.value }))}
                                                />
                                            })
                                        }
                                    </div>
                                    <LabelInput text={'Nome do beneficiário: (até 25 letras) *'}>
                                        <Input 
                                            id={'beneficiaryName'}
                                            name={'beneficiaryName'}
                                            value={formData?.beneficiaryName || ''}
                                            placeholder={"Digite o nome"}
                                            onChange={(e) => {
                                                if (e.target.value?.length <= 25) {
                                                    setFormData(prev => ({ ...prev, beneficiaryName: e.target.value }))
                                                } 
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cidade do beneficiário ou da transação: (até 15 letras) *'}>
                                        <Input 
                                            id={'cityOfTransaction'}
                                            name={'cityOfTransaction'}
                                            maxLength={15}
                                            value={formData?.cityOfTransaction || ''}
                                            placeholder={"Digite a cidade"}
                                            onChange={(e) => { 
                                                if (e.target.value?.length <= 15) {
                                                    setFormData(prev => ({ ...prev, cityOfTransaction: e.target.value }))
                                                }
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Código da transferência (opcional)'}>
                                        <Input
                                            value={formData?.identifierCode || ''}
                                            placeholder={"Digite o código"}
                                            onChange={(e) => setFormData(prev => ({ ...prev, identifierCode: e.target.value }))}
                                        />
                                    </LabelInput>
                                </form>
                                <div className="w-1/2 flex flex-col items-start justify-start mt-6 md:items-end md:justify-end md:mt-0">
                                {
                                    showPixQrCode && (
                                        <div className="flex flex-col items-center justify-center gap-4">
                                            <div id="pix-qrcode" className="p-2">
                                                <PIX
                                                    pixkey={formData?.pixTypeValue}
                                                    merchant={formData?.beneficiaryName}
                                                    city={formData?.cityOfTransaction}
                                                />
                                            </div>
                                            <Button shadow={true} approval={true} onClick={() => downloadQrCodeImage()}>Baixar QR Code</Button>
                                        </div>
                                    )}
                                </div>
                            </>
                            :
                            <LabelInput text={'Selecione a conta:'}>
                                <InputAutoComplete
                                    id={'account'}
                                    name={'account'}
                                    value={accounts?.find(acc => acc?.id == selectedAccount?.id)?.name || ''}
                                    data={accounts}
                                    optionList={['accountAgency', 'name']}
                                    selectedLabel={['accountAgency', 'name']}
                                    onChange={(e) => { 
                                        setSelectedAccount(e?.id)
                                    }}
                                />
                            </LabelInput>
                        }
                    </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate('/financial/config/configPixQrCode')}>Cancelar</Button>
                    {
                        !!showPixQrCode
                        ?
                        <Button shadow={true} approval={true} onClick={() => handleSubmit()}>Salvar</Button>
                        :
                        !!selectedAccount
                        &&
                        <Button shadow={true} approval={true} onClick={() => generatePixQRCode()}>Gerar QR code</Button>
                    }
                </div>
            </Footer>
        </Container>
    )
}
