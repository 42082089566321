import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from '../../../../components/container/container'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { Body } from '../../../../components/container/Body'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { TableDefault } from '../../../../components/table/table.default'
import { TableMobile } from '../../../../components/table/table.mobile'
import { LabelInput } from '../../../../components/label/label.input'
import { Input } from '../../../../components/input/input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { Button } from '../../../../components/buttons/button.default'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useAuth } from '../../../../contexts/useAuth'
import { useThemeContext } from '../../../../contexts/themeContext'
import { formattTimeToShow } from '../../../../services/api/callAPIsFunctions/defaultCalls.api'
import { currencyPtBr } from '../../../../utils/formatCurrency/currencyTextPTBR'
import { FaBan, FaCloudDownloadAlt, FaFilter, FaSearch } from 'react-icons/fa'
import { generateCsv } from '../../../../utils/excelFunctions/generateCsv'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function ActivitiesActions() {

    
    const { api, defaultCallsSchedules } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [subscriptionsList, setSubscriptionsList] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const tableThead = [
        {
            "name": 'Atividade',
            "original_name": "activitiesName",
            "table": "users"
        },
        {
            "name": 'Cliente',
            "original_name": 'client',
            "table": "users"
        },
        {
            "name": 'Inicio',
            "original_name": 'startDate'
        },
        {
            "name": 'Fim',
            "original_name": 'finishDate'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Status',
            "original_name": 'status'
        },

    ]

    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const [subscriptionActiveButton, setSubscriptionActiveButton] = useState(1)
    const tableFilterModal = useRef()

    const filters = [
        {
            "name": 'Nome',
            "original_name": 'firstName',
            "type": 'text',
        },
        {
            "name": 'Sobrenome',
            "original_name": 'lastName',
            "type": 'text'
        },
        {
            "name": 'Data criação',
            "original_name": 'created_at',
            "type": 'date'
        }
    ]

    async function getData(e) {

        setLoading(true)
        try {
            const data = await defaultCallsSchedules.getActivitiesSubscriptions(userData[0].token, e?.filter, e?.page, e?.direction, subscriptionActiveButton)
            if (data.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(data.data)
            }
            data?.data?.data?.length > 0 && setSubscriptionsList(data?.data?.data?.map(subscription => {
                return {
                    ...subscription,
                    client: `${subscription?.firstName} ${subscription?.lastName}`,
                    created_at: formattTimeToShow(subscription.created_at),
                    original_startDate: subscription?.startDate,
                    startDate: formattTimeToShow(subscription?.startDate),
                    original_finishDate: subscription?.finishDate,
                    finishDate: formattTimeToShow(subscription?.finishDate),
                    original_planValue: subscription?.planValue,
                    planValue: currencyPtBr(subscription.planValue),
                    original_status: subscription?.status,
                    payments_method: subscription?.paymentMethod === "P" ? "Pix" : subscription?.paymentMethod === "CE" ? "Cartão de crédito" : '-',
                    status: subscription?.status === 0 ? <div className='p-2 '><a className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</a></div> :
                        subscription?.status === 1 && subscription?.active === false ? <div className='p-2 '><a className='bg-green-300 p-1 rounded-lg text-white'>Pago | Inativo</a></div> :
                            subscription?.status === 1 ? <div className='p-2 '><a className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</a></div> :
                                subscription?.status === 2 && <div className='p-2 '><a className='bg-gray-500 p-1 rounded-lg text-white'>Cancelado</a></div>
                }
            }))

            setTotalPages(data?.data?.totalPages)
            setQuantity(data?.data?.quantity)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    //filter functions
    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }

        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    async function handleFilterActiveSubscriptions(status) {

        status > 0 ? setSubscriptionActiveButton(1) : setSubscriptionActiveButton(0)

        const subscriptionData = await defaultCallsSchedules.getActivitiesSubscriptions(userData[0].token, status?.filter, status?.page, status?.direction, status)
        setTotalPages(subscriptionData.data.totalPages)
        setQuantity(subscriptionData.data.quantity)

        if (subscriptionData.error) {
            setLoading(false)
            setShowNotication(subscriptionData.data)
            return
        }

        setSubscriptionsList(subscriptionData.data.data?.map(subscription => {
            return {
                ...subscription,
                created_at: formattTimeToShow(subscription.created_at),
                original_startDate: subscription?.startDate,
                startDate: formattTimeToShow(subscription?.startDate),
                original_finishDate: subscription?.finishDate,
                finishDate: formattTimeToShow(subscription?.finishDate),
                original_planValue: subscription?.planValue,
                planValue: currencyPtBr(subscription.planValue),
                original_status: subscription?.status,
                status: ((subscription?.status === 0 && !subscription?.active) || (subscription?.status === 3 && !subscription?.active)) ? <div className='p-2 '><a className='bg-gray-500 p-1 rounded-lg text-white'>Pagamento não realizado</a></div> : subscription?.status === 0 ? <div className='p-2 '><a className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</a></div> :
                    subscription?.status === 1 && subscription?.active === false ? <div className='p-2 '><a className='bg-green-300 p-1 rounded-lg text-white'>Pago | Inativo</a></div> :
                        subscription?.status === 1 ? <div className='p-2 '><a className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</a></div> :
                            subscription?.status === 2 && <div className='p-2 '><a className='bg-gray-500 p-1 rounded-lg text-white'>Cancelado</a></div>
            }
        }))
        setLoading(false)
    }

    function handleEdit(e) {
        localStorage.setItem('@activitiesSubscriptions', JSON.stringify(e))
        navigate(`/activitiesActionsEdit/:${e?.id}`)
    }

    function handleCsv() {

        const array = []
        subscriptionsList.map(subscription => {
            array.push({
                'Código cliente': subscription.users_id,
                'Cliente': `${subscription.firstName} ${subscription.lastName}`,
                'Atividade': subscription.activitiesName,
                'Início': formattTimeToShow(subscription.original_startDate),
                'Fim': formattTimeToShow(subscription.original_finishDate),
                'Criado em': formattTimeToShow(subscription.created_at),
                'Status': subscription.original_status === 0 ? 'Pendente pagamento' :
                    subscription.original_status === 1 ? "Pago" :
                        subscription.original_status === 2 && "Cancelado",
                'Forma de pagamento': subscription.payments_method,
                'Valor': subscription.planValue
            })
        })
        generateCsv(array);
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row justify-between items-center w-full'>
                    <div className='flex flex-col items-start justify-start'>
                        <Title text={'Inscrições'}></Title>
                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{quantity} inscricões</p>
                    </div>
                    <Button module={"activitiesSubscription:subscribe"} userData={userData} shadow={true} onClick={() => navigate('/activitiesActionsCreate')}>+ Nova</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return <div className='flex flex-row gap-4'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                column.type === 'object' ?
                                                    <LabelInput>
                                                        <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        }}></InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'date' &&
                                                    <LabelInput>
                                                        <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }} />
                                                    </LabelInput>
                                        }
                                    </div>
                                })}
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>
                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                <div className='flex flex-row w-full justify-start items-center gap-4'>
                    <div className='flex flex-row items-center justify-start'>
                        <Tippy content={'Atividades ativas'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button
                                className={`cursor-pointer font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${subscriptionActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                                onClick={() => handleFilterActiveSubscriptions(1)}
                            >
                                Ativos
                            </button>
                        </Tippy>
                        <Tippy content={'Atividades inativas'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button
                                className={`cursor-pointer font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${subscriptionActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                                onClick={() => handleFilterActiveSubscriptions(0)}
                            >
                                Inativos
                            </button>
                        </Tippy>
                    </div>
                    <Tippy content={'Filtrar'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div className={`p-1 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150 ${paginationHistFilteredFieldsArr?.length > 0 && 'bg-gray-300 rounded-lg'}`} onClick={() => setShowFilterModal(true)}>
                            <FaFilter />
                        </div>
                    </Tippy>
                    <Tippy content={'Exportar lista em excel'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => handleCsv()}>
                                    <FaCloudDownloadAlt />
                                    Exportar
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                <div id='activitiesActionsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                    <TableDefault
                        onClick={(e) => handleEdit(e)}
                        pagination={true}
                        totalPages={totalPages}
                        title={tableThead} data={subscriptionsList}
                        collumns={[
                            "activitiesName",
                            "client",
                            "startDate",
                            "finishDate",
                            "created_at",
                            "status"
                        ]}
                        loading={loading}
                        filter={(e) => orderTableData(e)}
                    />
                </div>
                <div id='activitiesActionsTableContent' className={`p-2 flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                    <TableMobile
                        onClick={(e) => e.func.execute(e.data)}
                        functions={[]}
                        pagination={true}
                        totalPages={totalPages}
                        title={tableThead}
                        data={subscriptionsList}
                        collumns={[
                            "activitiesName",
                            "client",
                            "startDate",
                            "finishDate",
                            "created_at",
                            "status"
                        ]}
                        loading={loading}
                        filter={(e) => orderTableData(e)}
                    />
                </div>


            </Body>
        </Container>
    )
}