import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body';
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from "moment";
import { FaInfo, FaMinus, FaPlus } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { justNumber } from "../../../utils/validators/justNumber";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { validateFields } from "../../../utils/form.validator";
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialAssociationsMonthlyPaymentConfigCreate() {
    
    const navigate = useNavigate()
    const { id } = useParams()
    const { api_financial, defaultCallsAuth, defaultCallsFinancial } = useApiContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [associations, setAssociations] = useState([])
    const [clientAndSupplier, setClientAndSupplier] = useState([])
    const [services, setServices] = useState([])
    const [ costCenter, setCostCenter ] = useState([])
    const [ moduleLinks, setModuleLinks ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [configsPixQrCode, setConfigsPixQrCode] = useState([])
    const [monthlyPayment, setMonthlyPayment] = useState({
        associations_id: null,
        startDate: moment().utc(false).format('YYYY-MM-DD'),
        valueBy: 'fixed',
        value: null,
        sendInvoice: false,
        dueDay: null,
        sendTo: undefined,
        financialClientsAndSuppliers_id: null,
        sendEmailToNotify: false,
        sendEmailToNotifyDaysBefore: null,
        financialServices_id: null,
        apportionmentsBillConfig: [],
        sendConfigPixQrCode: false,
        financialConfigPixQrCode_id: undefined,
    })
    const [ apportionments, setApportionments ] = useState([{
        financialCategories_id: undefined,
        financialCostCenter_id: undefined,
        percentage: undefined,
        isLinked: false,
        linked: undefined,
        linked_id: undefined,
    }])

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            setLoading(true)

            const associationsAPI = await defaultCallsAuth.getAssociations(userData[0]?.token, true)
            if(associationsAPI.error){
                throw associationsAPI.error
            }
            setAssociations(associationsAPI.data)   
            
            const clientAndSupplierAPI = await defaultCallsFinancial.getClientAndSupplier(userData[0]?.token, true)
            if(clientAndSupplierAPI.error){
                throw clientAndSupplierAPI.error
            }
            setClientAndSupplier(clientAndSupplierAPI?.data?.filter(item => item?.isClient))

            const servicesAPI = await defaultCallsFinancial.getFinancialServices(userData[0]?.token)
            if (servicesAPI.error){
                throw servicesAPI.error
            }
            setServices(servicesAPI.data)

            const costCenterData = await defaultCallsFinancial.getCostCenter(userData[0]?.token, true)
            if (costCenterData.error) {
                throw costCenterData.error
            }
            setCostCenter(costCenterData?.data?.map(cost => {
                return {
                    ...cost,
                    valueInput: `${cost?.code ? cost?.code : cost?.id} - ${cost?.name}`
                }
            }))

            const categoriesApi = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
            if (categoriesApi?.error){
                throw categoriesApi.error
            }
            setCategories(categoriesApi.data)

            const configsPixQrCode = await defaultCallsFinancial.getFinancialConfigPixQrCode(userData[0]?.token)
            if (configsPixQrCode?.error){
                throw configsPixQrCode.error
            }
            setConfigsPixQrCode(configsPixQrCode.data)

            if (id){
                const getMonthlyPayment = await api_financial.get(`/api/v1/financial/associationsMonthlyPaymentConfig/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                const monthlyPaymentData = getMonthlyPayment?.data?.data[0]
                
                setMonthlyPayment({
                    associations_id: monthlyPaymentData?.associations_id,
                    startDate: monthlyPaymentData?.startDate,
                    valueBy: monthlyPaymentData?.valueBy,
                    value: monthlyPaymentData?.value,
                    sendInvoice: monthlyPaymentData?.sendInvoice,
                    dueDay: monthlyPaymentData?.dueDay,
                    sendTo: monthlyPaymentData?.sendTo,
                    financialClientsAndSuppliers_id: monthlyPaymentData?.financialClientsAndSuppliers_id,
                    sendEmailToNotify: monthlyPaymentData?.sendEmailToNotify,
                    sendEmailToNotifyDaysBefore: monthlyPaymentData?.sendEmailToNotifyDaysBefore,
                    financialServices_id: monthlyPaymentData?.financialServices_id,
                    sendConfigPixQrCode: monthlyPaymentData?.sendConfigPixQrCode,
                    financialConfigPixQrCode_id: monthlyPaymentData?.financialConfigPixQrCode_id,
                    apportionmentsBillConfig: []
                })
                setApportionments(monthlyPaymentData?.apportionmentsBillConfig?.map(app => {
                    return {
                        ...app,
                        isLinked: app?.linked_id ? true : false
                    }
                }))
            }

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        }finally{
            setLoading(false)
        }
    }

    async function handleSubmit(){

        const requiredFields = [
            { name: 'associations_id', value: monthlyPayment?.associations_id, required: true, type: 'string' },
            { name: 'value', value: monthlyPayment?.value, required: true, type: 'string' },
            { name: 'sendInvoice', value: [true, false].includes(monthlyPayment?.sendInvoice), required: true, type: 'string' },
            { name: 'sendEmailToNotify', value: [true, false].includes(monthlyPayment?.sendEmailToNotify), required: true, type: 'string' },
            { name: 'sendConfigPixQrCode', value: [true, false].includes(monthlyPayment?.sendConfigPixQrCode), required: true, type: 'string' },
            { name: 'financialServices_id', value: monthlyPayment?.financialServices_id, required: true, type: 'string' },
            { name: 'dueDay', value: monthlyPayment?.dueDay, required: true, type: 'string' },
        ]

        // if (monthlyPayment?.sendInvoice){
        //     requiredFields.push(
        //         { name: 'sendTo', value: monthlyPayment?.sendTo, required: true, type: 'string' }
        //     )                                    
        // }

        if (monthlyPayment.sendTo === 'clientAndSupplier'){
            requiredFields.push(
                { name: 'financialClientsAndSuppliers_id', value: monthlyPayment?.financialClientsAndSuppliers_id, required: true, type: 'string' }
            )
        }

        if (monthlyPayment.sendEmailToNotify){
            requiredFields.push(
                { name: 'sendEmailToNotifyDaysBefore', value: monthlyPayment?.sendEmailToNotifyDaysBefore, required: true, type: 'string' }
            )
        }

        if (monthlyPayment.sendConfigPixQrCode){
            requiredFields.push(
                { name: 'financialConfigPixQrCode_id', value: monthlyPayment?.financialConfigPixQrCode_id, required: true, type: 'string' }
            )
        }

        apportionments?.map((app, index) => {
            requiredFields.push(
                {name: `apportionmentFinancialCategories_id-${index}`, value: app?.financialCategories_id, required: true, type: 'string'},
                {name: `apportionmentPercentage-${index}`, value: app?.percentage, required: true, type: 'string'}
            )
            if(app.isLinked){
                requiredFields.push({ name: `apportionmentLinked-${index}`, value: app.linked_id, required: true, type: 'number' },)
            }
        })

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)
        
        try {
            await api_financial.post(`/api/v1/financial/associationsMonthlyPaymentConfig`, {
                associations_id: monthlyPayment?.associations_id,
                startDate: monthlyPayment?.startDate,
                valueBy: monthlyPayment?.valueBy,
                value: monthlyPayment?.value,
                // sendInvoice: monthlyPayment?.sendInvoice,
                sendInvoice: false,
                dueDay: monthlyPayment?.dueDay || undefined,
                sendTo: monthlyPayment?.sendTo || undefined,
                financialClientsAndSuppliers_id: monthlyPayment?.financialClientsAndSuppliers_id || undefined,
                sendEmailToNotify: monthlyPayment?.sendEmailToNotify,
                sendEmailToNotifyDaysBefore: monthlyPayment?.sendEmailToNotifyDaysBefore || undefined,
                financialServices_id: monthlyPayment?.financialServices_id,
                sendConfigPixQrCode: monthlyPayment?.sendConfigPixQrCode,
                financialConfigPixQrCode_id: monthlyPayment?.financialConfigPixQrCode_id ? Number(monthlyPayment?.financialConfigPixQrCode_id) : undefined,
                apportionmentsBillConfig: apportionments.map(app => {
                    return {
                        financialCategories_id: app?.financialCategories_id,
                        financialCostCenter_id: app?.financialCostCenter_id 
                            ? app?.financialCostCenter_id 
                            : undefined,
                        percentage: Number(app?.percentage),
                        linked: app?.linked 
                            ? app?.linked 
                            : undefined,
                        linked_id: app?.linked_id 
                            ? app?.linked_id 
                            : undefined
                    }
                })
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Mensalidade ${id ? 'atualizada' : 'cadastrada'} com sucesso`)
            return navigate(-1)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    async function inactiveMonthly(){
        setLoading(true)
        setShowUniversalModal(false)
        try {
            await api_financial.put(`/api/v1/financial/associationsMonthlyPaymentConfig/${id}`, {
                active: false
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Mensalidade inativada com sucesso")
            return navigate(-1)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    const boolOptions = [
        { id: true, value: 'Sim' },
        { id: false, value: 'Não' },
    ]

    const sendInvoiceToOptions = [
        { id: 'user', value: 'Usuário' },
        { id: 'clientAndSupplier', value: 'Empresa/Cliente' },
    ]

    return (
        <>
            <ScreenModal title={'Inativar mensalidade'}>
                <div className='p-4 flex flex-col text-sm items-center justify-center gap-2 w-[30rem]'>
                    <p>Deseja realmente inativar essa mensalidade?</p>
                    <div className='flex gap-4 mt-3'>                            
                        <Button shadow={true} onClick={() => inactiveMonthly()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text='Criação de Mensalidade por Associação'></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ? 
                        <div className="w-full flex items-center justify-center"><DefaultLoader/></div>
                        :
                        <>
                            {/* Cadastro */}
                            <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                <h3 className='text-primaryDefaultLight text-xl'>Cadastro</h3>
                                <div className='flex flex-wrap gap-2 items-start justify-start w-full mt-4 mb-2'>
                                    <LabelInput text={'Associação'} required>
                                        <InputAutoComplete
                                            data={associations}
                                            id={'associations_id'}
                                            preSelectedValue={associations.find(opt => opt.id == monthlyPayment.associations_id)?.name}
                                            value={associations.find(opt => opt.id == monthlyPayment.associations_id)?.name}
                                            onChange={(e) => {
                                                setMonthlyPayment(prev => ({ ...prev, associations_id: e.id }))
                                            }}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Início'} required>
                                        <Input 
                                            id='startDate' 
                                            type={'date'} 
                                            value={monthlyPayment?.startDate} 
                                            onChange={(e) => setMonthlyPayment(prev => ({ ...prev, startDate: e.target.value }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Valor'} required>
                                        <CurrencyInput
                                            id={'value'}
                                            className='pl-2 border border-gray-400 border-opacity-75 outline-none sm:w-96 w-full text-sm sm:text-sm transition-all 
                                            duration-200 focus:shadow-borderShadow h-10 sm:h-8 dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                            placeHolder={monthlyPayment?.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(monthlyPayment?.value) : 'R$ 0,00'}
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                setMonthlyPayment(prev => ({ ...prev, value }))
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Dia de vencimento'} required>
                                        <Input 
                                            id='dueDay'
                                            value={monthlyPayment?.dueDay}
                                            charLimit={2}
                                            onChange={(e) => setMonthlyPayment(prev => ({ ...prev, dueDay: justNumber(e.target.value) }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Gerar boleto (campo indisponível no momento)'} required={false}>
                                        <InputAutoComplete
                                            disabled={true}
                                            data={boolOptions}
                                            id={'sendInvoice'}
                                            // preSelectedValue={boolOptions.find(opt => opt.id == monthlyPayment.sendInvoice)?.value}
                                            preSelectedValue={''}
                                            onChange={(e) => {
                                                setMonthlyPayment(prev => ({ 
                                                    ...prev, 
                                                    sendInvoice: e.id
                                                }))
                                            }}
                                            selectedLabel={'value'}
                                            optionList={['value']}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Enviar para'} required>
                                        <InputAutoComplete
                                            data={sendInvoiceToOptions}
                                            id={'sendTo'}
                                            preSelectedValue={sendInvoiceToOptions.find(opt => opt.id == monthlyPayment.sendTo)?.value}
                                            onChange={(e) => {
                                                setMonthlyPayment(prev => ({ 
                                                    ...prev, 
                                                    sendTo: e.id,
                                                    financialClientsAndSuppliers_id: null
                                                }))
                                            }}
                                            selectedLabel={'value'}
                                            optionList={['value']}
                                        />
                                    </LabelInput>
                                    {
                                        monthlyPayment.sendTo === 'clientAndSupplier' &&
                                        <LabelInput text={'Empresa/Cliente'} required>
                                            <InputAutoComplete
                                                data={clientAndSupplier} 
                                                id='financialClientsAndSuppliers_id'
                                                preSelectedValue={clientAndSupplier.find(opt => opt.id == monthlyPayment.financialClientsAndSuppliers_id)?.name}
                                                value={clientAndSupplier.find(opt => opt.id == monthlyPayment.financialClientsAndSuppliers_id)?.name}
                                                onChange={(e) => setMonthlyPayment(prev => ({ ...prev, financialClientsAndSuppliers_id: e.id }))}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                            />
                                        </LabelInput>
                                    }
                                    <LabelInput text={'Envia e-mail notificação'} required>
                                        <InputAutoComplete
                                            data={boolOptions}
                                            id={'sendEmailToNotify'}
                                            preSelectedValue={boolOptions.find(opt => opt.id == monthlyPayment.sendEmailToNotify)?.value}
                                            onChange={(e) => {
                                                setMonthlyPayment(prev => ({ 
                                                    ...prev, 
                                                    sendEmailToNotify: e.id,
                                                    sendEmailToNotifyDaysBefore: null
                                                }))
                                            }}
                                            selectedLabel={'value'}
                                            optionList={['value']}
                                        />
                                    </LabelInput>
                                    {
                                        monthlyPayment.sendEmailToNotify &&
                                        <LabelInput text={'Dias antes para enviar e-mail'} required>
                                            <Input 
                                                id='sendEmailToNotifyDaysBefore'
                                                charLimit={2}
                                                value={monthlyPayment?.sendEmailToNotifyDaysBefore} 
                                                onChange={(e) => setMonthlyPayment(prev => ({ ...prev, sendEmailToNotifyDaysBefore: justNumber(e.target.value) }))}
                                            />
                                        </LabelInput>
                                    }
                                    <LabelInput text={'Serviço Financeiro'} required>
                                        <InputAutoComplete 
                                            id='financialServices_id' 
                                            data={services}
                                            preSelectedValue={services.find(opt => opt.id == monthlyPayment.financialServices_id)?.description}
                                            value={services.find(opt => opt.id == monthlyPayment.financialServices_id)?.description}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            onChange={(e) => setMonthlyPayment(prev => ({ ...prev, financialServices_id: e.id }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Enviar QR CODE para pix em e-mail'} required>
                                        <InputAutoComplete
                                            data={boolOptions}
                                            id={'sendConfigPixQrCode'}
                                            preSelectedValue={boolOptions.find(opt => opt.id == monthlyPayment.sendConfigPixQrCode)?.value}
                                            onChange={(e) => {
                                                setMonthlyPayment(prev => ({ 
                                                    ...prev, 
                                                    sendConfigPixQrCode: e.id,
                                                    financialConfigPixQrCode_id: null
                                                }))
                                            }}
                                            selectedLabel={'value'}
                                            optionList={['value']}
                                        />
                                    </LabelInput>
                                    {
                                        monthlyPayment.sendConfigPixQrCode &&
                                        <LabelInput text={'Informe a configuração de pix desejada'} required>
                                            <InputAutoComplete
                                                data={configsPixQrCode}
                                                id={'financialConfigPixQrCode_id'}
                                                preSelectedValue={configsPixQrCode.find(opt => opt.id == monthlyPayment.financialConfigPixQrCode_id)?.pixTypeValue}
                                                onChange={(e) => {
                                                    setMonthlyPayment(prev => ({ 
                                                        ...prev, 
                                                        financialConfigPixQrCode_id: e.id
                                                    }))
                                                }}
                                                selectedLabel={'pixTypeValue'}
                                                optionList={['pixType', 'pixTypeValue']}
                                            />
                                        </LabelInput>
                                    }
                                </div>
                            </div>
                            <hr className='my-6 border w-full' />
                            {/* Rateios */}
                            <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                <h3 className='text-primaryDefaultLight text-xl'>Rateios</h3>
                                <div className='flex flex-row justify-start items-end mt-4 border-b border-gray-300 w-full'>
                                    <div className='flex flex-col items-start gap-2'>
                                        <div className='flex flex-row items-center gap-2'>
                                            <p className='text-titleGrayTextDark'>Restante do rateio</p>
                                            <Tippy content={'É o restante que não foi distribuído a partir do valor total desta compra. Para completar o rateio, este restante precisa estar zerado.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div 
                                                    className='cursor-help text-xs text-primaryDefaultLight hover:brightness-150'>
                                                    <FaInfo />
                                                </div>
                                            </Tippy>
                                        </div>
                                        <div className='flex flex-row justify-start items-end gap-2'>
                                            <p className='text-xl text-gray-500 font-semibold tracking-wider'>
                                                {100 - apportionments.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0)}
                                                %
                                            </p>
                                            {
                                                apportionments.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0) !== 100
                                                &&
                                                <p className='text-red-400 text-xs'>Para prosseguir com a compra o rateio precisa estar zerado.</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    apportionments.map((app, index) => {
                                        return (
                                            <>
                                                <div className={`flex flex-col gap-2 mt-3s`}>
                                                    <div className={`flex flex-wrap gap-2  ${index == 0 ? '' : 'border-t pt-4'} `}>
                                                        <LabelInput text={'Categoria: *'}>
                                                            <InputAutoComplete
                                                                data={categories}
                                                                id={`apportionmentFinancialCategories_id-${index}`}
                                                                preSelectedValue={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                value={categories.find(categorie => categorie.id === app.financialCategories_id)?.description}
                                                                onChange={(e) => {
                                                                    app.financialCategories_id = e.id
                                                                    setApportionments([ ...apportionments ])
                                                                }}
                                                                selectedLabel={'description'}
                                                                optionList={['id', 'description']}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput text={'Centro de custo:'}>
                                                            <InputAutoComplete
                                                                data={costCenter}
                                                                id={`apportionmentFinancialCostCenter_id-${index}`}
                                                                preSelectedValue={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                value={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                onChange={(e) => {
                                                                    app.financialCostCenter_id = e.id
                                                                    setApportionments([ ...apportionments ])
                                                                }}
                                                                selectedLabel={'valueInput'}
                                                                optionList={['valueInput']}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput text={'Vinculado:'}>
                                                            <InputAutoComplete
                                                                data={boolOptions}
                                                                id={`isLinked-${index}`}
                                                                preSelectedValue={boolOptions?.find(item => item?.id == app?.isLinked)?.value}
                                                                value={boolOptions?.find(item => item?.id == app?.isLinked)?.value}
                                                                onChange={(e) => {
                                                                    app.isLinked = e.id
                                                                    setApportionments([ ...apportionments ])
                                                                }}
                                                                selectedLabel={'value'}
                                                                optionList={['value']}
                                                            />
                                                        </LabelInput>
                                                        {
                                                            app?.isLinked ?
                                                            <LabelInput text={'Vinculado com: *'}>
                                                                <InputAutoComplete
                                                                    data={moduleLinks}
                                                                    id={`apportionmentLinked-${index}`}
                                                                    preSelectedValue={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                    value={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                    onChange={(e) => {
                                                                        app.linked_id = e.linked_id
                                                                        app.linked = e.linked
                                                                        setApportionments([ ...apportionments ])
                                                                    }}
                                                                    selectedLabel={'linked_name'}
                                                                    optionList={['linked_id', 'linked_name']}
                                                                />
                                                            </LabelInput>
                                                            :
                                                            <></>
                                                        }
                                                        <LabelInput text={'Porcentagem (%): *'}>
                                                            <Input
                                                                id={`apportionmentPercentage-${index}`}
                                                                type={'number'}
                                                                charLimit={3}
                                                                value={app.percentage}
                                                                onChange={(e) => {
                                                                    app.percentage = justNumber(e.target.value)
                                                                    setApportionments([ ...apportionments ])
                                                                }}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    <div className='flex items-center my-2 gap-2'>
                                                        <span onClick={() => {
                                                            setApportionments((prev) => ([...prev, {
                                                                financialCategories_id: undefined,
                                                                financialCostCenter_id: undefined,
                                                                percentage: undefined,
                                                                isLinked: false,
                                                                linked: undefined,
                                                                linked_id: undefined,
                                                            }]))
                                                        }}>
                                                            <FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                        </span>
                                                        <span
                                                            onClick={() => {
                                                                apportionments.splice(index, 1)
                                                                setApportionments([ ...apportionments ])
                                                            }}>
                                                            <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                        <div className="flex flex-row items-center justify-center gap-2">
                            {
                                id &&
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(true)}>Inativar</Button>
                            }
                            <Button shadow={true} approval={true} onClick={() => handleSubmit()}>{id ? 'Editar' : 'Cadastrar'}</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
