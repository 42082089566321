import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body'
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { Button } from '../../../components/buttons/button.default'
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useState } from "react";

export function FinancialRules() {
    
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [filter, setFilter] = useState({
        active: true
    })

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full items-center">
                    <Title text='Regras'></Title>
                    <Button onClick={() => navigate('/financial/config/rules/create')}>+ Nova</Button>
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-row items-center justify-start w-full'>
                    <button 
                        className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 
                            flex flex-col items-center justify-center 
                            ${filter?.active ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                            dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`
                        }
                        onClick={() => setFilter({...filter, active: true })}
                    >
                        Ativos
                    </button>
                    <button 
                        className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28
                            flex flex-col items-center justify-center 
                            ${!filter?.active ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                            dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`
                        }
                        onClick={() => setFilter({...filter, active: false })}
                    >
                        Inativos
                    </button>
                </div>
                <div className="w-full">
                    <NewTableDefault 
                        key={'table-rules'}
                        endpoint="/api/v1/financial/rules"
                        token={userData[0]?.token}
                        module="financial"
                        columns={[
                            {
                                description: 'Integração com outros modulos',
                                field: 'useAutoBillsByModules',
                                type: 'boolean',
                            },
                            {
                                description: 'Envio de e-mail para cobranças atrasadas',
                                field: 'useSendEmailForLateBills',
                                type: 'boolean',
                            },
                            {
                                description: 'Dias após vencimento',
                                field: 'quantityDaysAfterSendCharge',
                                newFieldValue: (item) => {
                                    return item?.quantityDaysAfterSendCharge ? item?.quantityDaysAfterSendCharge + ' dias' : ' - '
                                }
                            },
                            {
                                description: 'Criado em',
                                field: 'created_at',
                                type: 'date',
                            },
                        ]}
                        onClickInTableLine={(item) => navigate(`/financial/config/rules/create/${item?.id}`)}
                        filters={filter}
                    />
                </div>
            </Body>
        </Container>
    )
}