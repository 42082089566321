import React from 'react'
import { CalendarMain } from '../components/calendar/calendar.main'
//configuration
import { Configuration } from '../pages/onMenu/_general/configurationHome/configuration.home'
import { ThemesCenter } from '../pages/_general/themes/themes.center'
import { ThemesDefaultCenter } from '../pages/_general/themes/themes.default.center'
import { Customer } from '../pages/_general/customer/customer.profile'
import { MyProfileEdit } from '../pages/_general/myProfile/myProfile'
//home
import { Home } from '../pages/home'
//users
import { UsersTable } from '../pages/_general/users/users.table'
import { UserCreate } from '../pages/_general/users/users.creation'
import { UserEdit } from '../pages/_general/users/user.edit'
//profiles
import { UserProfile } from '../pages/_general/users/user.profile'
import { UserProfileCreate } from '../pages/_general/users/users.profile.create'
import { UserProfileEdit } from '../pages/_general/users/users.profile.edit'
//clients
import { ClientsCreate } from '../pages/_general/clients/clients.create'
import { ClientsTable } from '../pages/_general/clients/clients.table'
import { ClientEdit } from '../pages/_general/clients/clients.edit'
//associations
import { Associations } from '../pages/_general/associations/associations'
import { AssociatiosCreate } from '../pages/_general/associations/associations.create'
import { AssociatiosEdit } from '../pages/_general/associations/associations.edit'
//categories
import { Categories } from '../pages/_schedulesAndActivitiesSubs/categories/categories'
import { CategoriesCreate } from '../pages/_schedulesAndActivitiesSubs/categories/categories.create'
import { CategoriesEdit } from '../pages/_schedulesAndActivitiesSubs/categories/categories.edit'
//services
import { Services } from '../pages/_schedulesAndActivitiesSubs/services/service'
import { ServicesCreate } from '../pages/_schedulesAndActivitiesSubs/services/service.create'
import { ServicesEdit } from '../pages/_schedulesAndActivitiesSubs/services/service.edit'
//paymentGateway
import { PaymentGatewayConfig } from '../pages/_general/paymentGateways/paymentGateway.config'
import { PaymentGatewayCreate } from '../pages/_general/paymentGateways/paymentGatewayCreate'
import { PaymentGatewayEdit } from '../pages/_general/paymentGateways/paymentGatewayEdit'
//public-reservation
import { Reservation } from '../pages/onMenu/_schedulesAndActivitiesSubs/reservation/reservation'
import { PaymentMethods } from '../pages/_general/paymentMethods/paymentMethods'
import { ServiceTermsOfUse } from '../pages/_schedulesAndActivitiesSubs/servicesTermsOfUse/servicesTermsOfUse'
import { ServiceTermsCreate } from '../pages/_schedulesAndActivitiesSubs/servicesTermsOfUse/serviceTermsCreate'
import { ServiceTermsEdit } from '../pages/_schedulesAndActivitiesSubs/servicesTermsOfUse/serviceTermsEdit'
import { HolidaysCalendar } from '../pages/_general/holidaysCalendar/holidaysCalendar'
import { HolidayCalendarCreate } from '../pages/_general/holidaysCalendar/holidaysCalendar.create'
import { HolidayCalendarEdit } from '../pages/_general/holidaysCalendar/holidaysCalendarEdit'
import { CustomerAttendance } from '../pages/_schedulesAndActivitiesSubs/customerAttendance/custtomerAttendance'
import { ReservationCreate } from '../pages/onMenu/_schedulesAndActivitiesSubs/reservation/reservation.create'
import { Activities } from '../pages/_schedulesAndActivitiesSubs/activities/activities'
import { ActivitiesCreate } from '../pages/_schedulesAndActivitiesSubs/activities/activities.create'
import { ActivitiesPlans } from '../pages/_schedulesAndActivitiesSubs/activitiesPlans/activitiesPlans'
import { ActivitiesPlansCreate } from '../pages/_schedulesAndActivitiesSubs/activitiesPlans/activitiesPlans.create'
import { ActivitiesPlansEdit } from '../pages/_schedulesAndActivitiesSubs/activitiesPlans/activitiesPlans.edit'
import { ActivitiesEdit } from '../pages/_schedulesAndActivitiesSubs/activities/activities.edit'
import { ActivitiesActions } from '../pages/onMenu/_schedulesAndActivitiesSubs/activitiesActions/activitiesActions'
import { ActivitiesActionsCreate } from '../pages/onMenu/_schedulesAndActivitiesSubs/activitiesActions/activitiesActionsCreate'
import { Gastronomy } from '../pages/_schedulesAndActivitiesSubs/gastronomy/gastronomy'
import { GastronomyCreate } from '../pages/_schedulesAndActivitiesSubs/gastronomy/gastronomy.create'
import { GastronomyEdit } from '../pages/_schedulesAndActivitiesSubs/gastronomy/gastronomy.edit'
import { Structures } from '../pages/_schedulesAndActivitiesSubs/structures/structures.table'
import { StrucutresCreate } from '../pages/_schedulesAndActivitiesSubs/structures/structuresCreate'
import { StrucutresEdit } from '../pages/_schedulesAndActivitiesSubs/structures/structuresEdit'
import { Agreements } from '../pages/_general/agreements/agreements'
import { AgreementsCreate } from '../pages/_general/agreements/agreements.create'
import { AgreementsClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/agreements/agreements.client'
import { Events } from '../pages/_schedulesAndActivitiesSubs/events/events'
import { EventsCreate } from '../pages/_schedulesAndActivitiesSubs/events/events.create'
import { EventsEdit } from '../pages/_schedulesAndActivitiesSubs/events/events.edit'
import { EventsClient } from '../pages/onMenu/_schedulesAndActivitiesSubs/events/events.client'
import { UsersBlocked } from '../pages/_general/usersBlocked/usersBlocked'
import { UsersBlockedCreate } from '../pages/_general/usersBlocked/usersBlocked.create'
import { UsersBalance } from '../pages/_general/usersBalance/usersBalance'
import { UsersBlockedEdit } from '../pages/_general/usersBlocked/usersBlocked.edit'
import { SchedulesDraw } from '../pages/_schedulesAndActivitiesSubs/schedulesDraw/schedulesDraw'
import { SiteBanners } from '../pages/_general/siteIntegrations/siteBanners'
import { SiteBannerCreate } from '../pages/_general/siteIntegrations/siteBannersCreate'
import { SiteBannerEdit } from '../pages/_general/siteIntegrations/siteBanners.edit'
import { ActivitiesActionsEdit } from '../pages/onMenu/_schedulesAndActivitiesSubs/activitiesActions/activitiesActions.edit'
import { UsersImport } from '../pages/_general/usersImport/usersImport'
import { MyMembershipCard } from '../pages/_general/myMembership/myMembershipCard'
import { ValidUser } from '../pages/_general/users/validUser'
import { SchedulesReports } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/schedulesReports'
import { Reports } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/reports'
import { SchedulesPaymetsReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/schedulesPayments'
import { ActivitiesSubscriptionsReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/activitiesSubscriptionsReport'
import { PaymentsReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/paymentsReports'
import { SchedulesBlockReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/schedulesBlockReport'
import { Companies } from '../pages/_general/companies/companies'
import { CompaniesCreate } from '../pages/_general/companies/companies.create'
import { CompaniesEdit } from '../pages/_general/companies/companies.edit'
import { Sectors } from '../pages/_general/sectors/sectors'
import { SectorsCreate } from '../pages/_general/sectors/sectors.create'
import { SectorsEdit } from '../pages/_general/sectors/sectors.edit'
//Financeiro
import { CostCenter } from '../pages/_financial/financialCostCenter/costCenter'
import { CostCenterCreate } from '../pages/_financial/financialCostCenter/costCenter.create'
import { CostCenterEdit } from '../pages/_financial/financialCostCenter/costCenter.edit'
import { Products } from '../pages/_financial/financialProducts/products'
import { ProductsCreate } from '../pages/_financial/financialProducts/products.create'
import { ClientsAndSuppliers } from '../pages/_financial/financialClientsAndSuppliers/clientesAndSuppliers'
import { ClientsAndSuppliersCreate } from '../pages/_financial/financialClientsAndSuppliers/clientsAndSuppliers.create'
import { ProductsEdit } from '../pages/_financial/financialProducts/products.edit'
import { Patrimony } from '../pages/_financial/financialPatrimony/patrimony'
import { PatrimonyCreate } from '../pages/_financial/financialPatrimony/patrimony.create'
import { ClientsAndSuppliersEdit } from '../pages/_financial/financialClientsAndSuppliers/clientsAndSuppliers.edit'
import { Accounts } from '../pages/_financial/financialAccounts/accounts'
import { AccountsCreate } from '../pages/_financial/financialAccounts/accounts.create'
import { AccountsEdit } from '../pages/_financial/financialAccounts/accounts.edit'
import { FinancialCategories } from '../pages/_financial/financialCategories/financialCategories'
import { PatrimonyEdit } from '../pages/_financial/financialPatrimony/patrimony.edit'
import { FinancialCashFlow } from '../pages/_financial/financialCashFlow/financialCashFlow'
import { FinancialCashFlowEdit } from '../pages/_financial/financialCashFlow/financialCashFlow.edit'
import { FinancialBillsCreate } from '../pages/_financial/financialBills/financialBills.create'
import { FinancialServices } from '../pages/_financial/financialService/service'
import { FinancialServicesCreate } from '../pages/_financial/financialService/service.create'
import { FinancialServicesEdit } from '../pages/_financial/financialService/service.edit'
import { FinancialBillsEdit } from '../pages/_financial/financialBills/financialBills.edit'
import { FinancialBills } from '../pages/_financial/financialBills/financialBills'
import { FinancialDashboard } from '../pages/_financial/financialDashboard'
import { FinancialConciliations } from '../pages/_financial/financialConciliations/financialConciliations'
import { FinancialConciliationsCreate } from '../pages/_financial/financialConciliations/financialConciliations.create'
import { FinancialReports } from '../pages/onMenu/_financial/reports/financialReports'
import { FinancialReportsPerCategory } from '../pages/onMenu/_financial/reports/perCategory'
import { FinancialConciliationsTransactions } from '../pages/_financial/financialConciliations/financialConciliations.transactions'
import { FinancialReportsPerCostCenter } from '../pages/onMenu/_financial/reports/perCostCenter'
import { FinancialReportsCashFlowMonthly } from '../pages/onMenu/_financial/reports/cashFlowMonthly'
import { FinancialReserveMargin } from '../pages/onMenu/_financial/reports/reserveMargin'
import { FinancialAssociationsMonthlyPaymentConfig } from '../pages/_financial/financialAssociationsMonthlyPaymentConfig/financialAssociationsMonthlyPaymentConfig'
import { FinancialAssociationsMonthlyPaymentConfigCreate } from '../pages/_financial/financialAssociationsMonthlyPaymentConfig/financialAssociationsMonthlyPaymentConfig.create'
import { FinancialRules } from '../pages/_financial/financialRules/financialRules'
import { FinancialRulesCreate } from '../pages/_financial/financialRules/financialRules.create'
import { FinancialActivitiesMargin } from '../pages/onMenu/_financial/reports/activitiesMargin'
import { FinancialConfigPixQrCode } from '../pages/_financial/financialConfigPixQrCode/configPixQrCode'
import { FinancialConfigPixQrCodeEdit } from '../pages/_financial/financialConfigPixQrCode/configPixQrCode.edit'
import { FinancialConfigPixQrCodeCreate } from '../pages/_financial/financialConfigPixQrCode/configPixQrCode.create'
import { FinancialCharges } from '../pages/_financial/financialCharges/financialCharges'
import { BalanceEntriesReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/balanceEntriesReport'
import { BalanceTotalPerUserReport } from '../pages/onMenu/_schedulesAndActivitiesSubs/reports/balanceTotalPerUserReport'

/** 
 * MODULE DESCRIBE
 * 0 = GERAL
 * 1 = RESERVAS
 * 2 = INSCRIÇÕES EM ATIVIDADES
 * 3 = FINANCEIRO
**/

export const generalRoutesConfig = [
    {
        module: 0,
        path: '/',
        element: <Home/>,
        protectedRoute: [],
        name: 'Dashboard',
        helpVideo: 'pWMzW3TrXls'
        
    },
    {
        module: 0,
        path: '/associations',
        element: <Associations />,
        protectedRoute: ['associations:read', "associations:create", "associations:update", 'asssociations:delete']
    },
    {
        module: 0,
        path: '/associationsCreate',
        element: <AssociatiosCreate />,
        protectedRoute: ['associations:read', "associations:create"]
    },
    {
        module: 0,
        path: '/associationsEdit/:id',
        element: <AssociatiosEdit />,
        protectedRoute: ['associations:update', 'associations:read']
    },
    {
        module: 0,
        path: '/agreements',
        element: <Agreements />,
        protectedRoute: ['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']
    },
    {
        module: 0,
        path: '/agreementsShow',
        element: <AgreementsClient />,
        protectedRoute: ['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']
    },
    {
        module: 0,
        path: '/agreementsCreate',
        element: <AgreementsCreate />,
        protectedRoute: ['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']
    },
    {
        module: 0,
        path: '/clients',
        element: <ClientsTable />,
        protectedRoute: ['clients:read']
    },
    {
        module: 0,
        path: '/clientsCreate',
        element: <ClientsCreate />,
        protectedRoute: ['clients:create']
    },
    {
        module: 0,
        path: '/clientsEdit/:id',
        element: <ClientEdit />,
        protectedRoute: ['clients:update']
    },
    {
        module: 0,
        path: '/companies',
        element: <Companies />,
        protectedRoute: ['companies:read']
    },
    {
        module: 0,
        path: '/companiesCreate',
        element: <CompaniesCreate />,
        protectedRoute: ['companies:create']
    },
    {
        module: 0,
        path: '/companiesEdit/:id',
        element: <CompaniesEdit />,
        protectedRoute: ['companies:update']
    },
    {
        module: 0,
        path: '/configuration',
        element: <Configuration />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/sectors',
        element: <Sectors/>,
        protectedRoute: ['financialSectors:read']
    },
    {
        module: 0,
        path: '/sectorsCreate',
        element: <SectorsCreate />,
        protectedRoute: ['financialSectors:create']
    },
    {
        module: 0,
        path: '/sectorsEdit/:id',
        element: <SectorsEdit />,
        protectedRoute: ['financialSectors:update']
    },
    {
        module: 0,
        path: '/customer',
        element: <Customer />,
        protectedRoute: ["customers:read", "customers:update", "customers:delete"]
    },
    {
        module: 0,
        path: '/customerAttendance',
        element: <CustomerAttendance />,
        protectedRoute: ["customersAttendance:read", "customersAttendance:create", "customersAttendance:update", "customersAttendance:delete"]
    },
    {
        module: 0,
        path: '/defaultTheme',
        element: <ThemesDefaultCenter />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/holidayCalendar',
        element: <HolidaysCalendar />,
        protectedRoute: ["holidays:read", "holidays:update", "holidays:delete"]
    },
    {
        module: 0,
        path: '/holidayCalendarCreate',
        element: <HolidayCalendarCreate />,
        protectedRoute: ["holidays:delete"]
    },
    {
        module: 0,
        path: '/holidayCalendarEdit/id/:id',
        element: <HolidayCalendarEdit />,
        protectedRoute: ["holidays:update", "holidays:delete", "holidays:read"]
    },
    {
        module: 0,
        path: '/myMembershipCard/:id',
        element: <MyMembershipCard />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/myProfileEdit/:id',
        element: <MyProfileEdit />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/paymentMethods',
        element: <PaymentMethods />,
        protectedRoute: ['paymentMethods:create', 'paymentMethods:update']
    },
    {
        module: 0,
        path: '/paymentGatewayConfig',
        element: <PaymentGatewayConfig />,
        protectedRoute: ['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']
    },
    {
        module: 0,
        path: '/paymentGatewayConfigCreate',
        element: <PaymentGatewayCreate />,
        protectedRoute: ['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']
    },
    {
        module: 0,
        path: '/paymentGatewayEdit/:id',
        element: <PaymentGatewayEdit />,
        protectedRoute: ['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']
    },
    {
        module: 0,
        path: '/calendar',
        element: <CalendarMain />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/theme',
        element: <ThemesCenter />,
        isThemeComponent: true,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/users',
        element: <UsersTable />,
        protectedRoute: ['users:read', 'users:create']
    },
    {
        module: 0,
        path: '/userCreate',
        element: <UserCreate />,
        protectedRoute: ['users:create']
    },
    {
        module: 0,
        path: '/usersEdit/:id',
        element: <UserEdit />,
        protectedRoute: ['users:read', 'users:update']
    },
    {
        module: 0,
        path: '/usersBalance',
        element: <UsersBalance />,
        protectedRoute: ['balance:read', 'balance:create', 'balance:update', 'balance:delete']
    },
    {
        module: 0,
        path: '/usersBlocked',
        element: <UsersBlocked />,
        protectedRoute: ['blockedUsers:read', 'blockedUsers:create', 'blockedUsers:update', 'blockedUsers:delete']
    },
    {
        module: 0,
        path: '/usersBlockedEdit/:id',
        element: <UsersBlockedEdit />,
        protectedRoute: ['blockedUsers:read', 'blockedUsers:update']
    },
    {
        module: 0,
        path: '/usersBlockedCreate',
        element: <UsersBlockedCreate />,
        protectedRoute: ['blockedUsers:read', 'blockedUsers:create']
    },
    {
        module: 0,
        path: '/usersImport',
        element: <UsersImport />,
        protectedRoute: ['users:import']
    },
    {
        module: 0,
        path: '/usersProfile',
        element: <UserProfile />,
        protectedRoute: ['permissionGroups:read', 'permissionGroups:create', 'permissionGroups:update', 'permissionGroups:delete']
    },
    {
        module: 0,
        path: '/usersProfileCreate',
        element: <UserProfileCreate />,
        protectedRoute: ['permissionGroups:create']
    },
    {
        module: 0,
        path: '/usersProfileEdit/:profileData',
        element: <UserProfileEdit />,
        protectedRoute: ['permissionGroups:update', 'permissionGroups:read']
    },
    {
        module: 0,
        path: '/validUser/:usersId/:userTypeAccess',
        element: <ValidUser />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/siteBanners',
        element: <SiteBanners />,
        protectedRoute: ['siteBanners:read', 'siteBanners:delete', 'siteBanners:update']
    },
    {
        module: 0,
        path: '/siteBannerscreate',
        element: <SiteBannerCreate />,
        protectedRoute: ['siteBanners:read', 'siteBanners:create']
    },
    {
        module: 0,
        path: '/siteBannersEdit/:id',
        element: <SiteBannerEdit />,
        protectedRoute: ['siteBanners:read', 'siteBanners:create', 'siteBanners:update']
    },
]

export const activitiesAndSchedulesRoutesConfig = [
    {
        module: 1,
        path: '/activities',
        element: <Activities />,
        protectedRoute: ['activities:create', 'activities:update', 'activities:create', 'activities:delete']
    },
    {
        module: 2,
        path: '/activitiesActions',
        element: <ActivitiesActions />,
        protectedRoute: ['activitiesSubscription:read', 'activitiesSubscription:subscribe', "activitiesSubscription:unsubscribe"]
    },
    {
        module: 2,
        path: '/activitiesActionsCreate',
        element: <ActivitiesActionsCreate />,
        protectedRoute: ['activitiesSubscription:read', 'activitiesSubscription:subscribe']
    },
    {
        module: 2,
        path: '/activitiesActionsEdit/:id',
        element: <ActivitiesActionsEdit />,
        protectedRoute: ['activitiesSubscription:read', 'activitiesSubscription:subscribe', 'activitiesSubscription:unsubscribe']
    },
    {
        module: 2,
        path: '/activitiesCreate',
        element: <ActivitiesCreate />,
        protectedRoute: ['activities:create']
    },
    {
        module: 2,
        path: '/activitiesEdit/:id',
        element: <ActivitiesEdit />,
        protectedRoute: ['activities:update']
    },
    {
        module: 2,
        path: '/activitiesPlans',
        element: <ActivitiesPlans />,
        protectedRoute: ['activitiesPlans:create', 'activitiesPlans:read', 'activitiesPlans:update', 'activitiesPlans:delete']
    },
    {
        module: 2,
        path: '/activitiesPlansCreate',
        element: <ActivitiesPlansCreate />,
        protectedRoute: ['activitiesPlans:create']
    },
    {
        module: 2,
        path: '/activitiesPlansEdit/:id',
        element: <ActivitiesPlansEdit />,
        protectedRoute: ['activitiesPlans:update', 'activitiesPlans:delete']
    },
    {
        module: 1,
        path: '/categories',
        element: <Categories />,
        protectedRoute: ['categories:read', 'categories:create', 'categories:update', 'categories:delete'],
        name: 'Categorias de reserva',
        helpVideo: "5MVtUL5Os2g"
    },
    {
        module: 1,
        path: '/categoriesCreate',
        element: <CategoriesCreate />,
        protectedRoute: ['categories:create']
    },
    {
        module: 1,
        path: '/categoriesEdit/:id',
        element: <CategoriesEdit />,
        protectedRoute: ['categories:update', 'categories:delete']
    },
    {
        module: 0,
        path: '/events',
        element: <Events />,
        protectedRoute: ['events:read', 'events:update', "events:delete"]
    },
    {
        module: 0,
        path: '/eventsCreate',
        element: <EventsCreate />,
        protectedRoute: ['events:create', 'events:read', 'events:update', "events:delete"]
    },
    {
        module: 0,
        path: '/eventsEdit',
        element: <EventsEdit />,
        protectedRoute: ['events:read', 'events:update', "events:delete"]
    },
    {
        module: 0,
        path: '/eventsShow',
        element: <EventsClient />,
        protectedRoute: []
    },
    {
        module: 0,
        path: '/gastronomy',
        element: <Gastronomy />,
        protectedRoute: ['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]
    },
    {
        module: 0,
        path: '/gastronomyCreate',
        element: <GastronomyCreate />,
        protectedRoute: ['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]
    },
    {
        module: 0,
        path: '/gastronomyEdit/:id',
        element: <GastronomyEdit />,
        protectedRoute: ['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]
    },
    {
        module: 1,
        path: '/reports/schedulesPayments',
        element: <SchedulesPaymetsReport />,
        protectedRoute: ['reports:read']
    },
    {
        module: 0,
        path: '/reports',
        element: <Reports />,
        protectedRoute: ['reports:read', 'reports:create', 'reports:delete', 'reports:update']
    },
    {
        module: 2,
        path: '/reports/activitiesSubscriptions',
        element: <ActivitiesSubscriptionsReport />,
        protectedRoute: ['reports:read', 'reports:create', 'reports:delete', 'reports:update']
    },
    {
        module: 1,
        path: '/reports/paymentsReports',
        element: <PaymentsReport />,
        protectedRoute: ['reports:read', 'reports:create', 'reports:delete', 'reports:update']
    },
    {
        module: 1,
        path: '/reports/schedules',
        element: <SchedulesReports />,
        protectedRoute: ['reports:read', 'schedules:create', 'schedules:delete', 'schedules:update']
    },
    {
        module: 1,
        path: '/reports/balanceTotalPerUser',
        element: <BalanceTotalPerUserReport />,
        protectedRoute: ['reports:read', 'balance:create', 'balance:delete', 'balance:update']
    },
    {
        module: 1,
        path: '/reports/balanceEntries',
        element: <BalanceEntriesReport />,
        protectedRoute: ['reports:read', 'balance:create', 'balance:delete', 'balance:update']
    },
    {
        module: 1,
        path: '/reports/schedulesBlock',
        element: <SchedulesBlockReport />,
        protectedRoute: ['reports:read', 'schedules:create', 'schedules:delete', 'schedules:update']
    },
    {
        module: 1,
        path: '/reservation',
        element: <Reservation />,
        protectedRoute: ['schedules:read', 'schedules:create', 'schedules:delete', 'schedules:update']
    },
    {
        module: 1,
        path: '/reservationCreate',
        element: <ReservationCreate />,
        protectedRoute: ['schedules:read', 'schedules:create']
    },
    {
        module: 1,
        path: '/reservationEdit/:id',
        element: <ReservationCreate />,
        protectedRoute: ['scheduls:read', 'scheduls:update']
    },
    {
        module: 1,
        path: '/services',
        element: <Services />,
        protectedRoute: ['services:read', 'services:create', 'services:update', 'services:delete']
    },
    {
        module: 1,
        path: '/servicesCreate',
        element: <ServicesCreate />,
        protectedRoute: ['services:read', 'services:create']
    },
    {
        module: 1,
        path: '/servicesEdit/:id',
        element: <ServicesEdit />,
        protectedRoute: ['services:read', 'services:update']
    },    
    {
        module: 1,
        path: '/serviceTermsOfUse',
        element: <ServiceTermsOfUse />,
        protectedRoute: ['termsOfUse:create', 'termsOfUse:update', 'termsOfUse:read', 'termsOfUse:delete']
    },
    {
        module: 1,
        path: '/serviceTermsOfUseCreate',
        element: <ServiceTermsCreate />,
        protectedRoute: ['termsOfUse:create']
    },
    {
        module: 1,
        path: '/serviceTermsOfUseEdit/:id',
        element: <ServiceTermsEdit />,
        protectedRoute: ['termsOfUse:update', 'termsOfUse:read', 'termsOfUse:create', 'termsOfUse:delete']
    },
    {
        module: 1,
        path: '/schedulesDraw',
        element: <SchedulesDraw />,
        protectedRoute: ['schedulesDraw:read', 'schedulesDraw:delete', 'schedulesDraw:update']
    },
    {
        module: 0,
        path: '/structures',
        element: <Structures />,
        protectedRoute: ['structures:read', 'structures:create', 'structures:delete']
    },
    {
        module: 0,
        path: '/structuresCreate',
        element: <StrucutresCreate />,
        protectedRoute: ['structures:create']
    },
    {
        module: 0,
        path: '/structuresEdit/:structuresId',
        element: <StrucutresEdit />,
        protectedRoute: ['structures:update', 'structures:delete']
    },
]

export const financialRoutesConfig = [
    {
        module: 3,
        path: '/financial/costCenter',
        element: <CostCenter />,
        protectedRoute: ['financial']
    },
    {
        module: 3,
        path: '/financial/costCenterCreate',
        element: <CostCenterCreate />,
        protectedRoute: ['financialCostCenter:create']
    },
    {
        module: 3,
        path: '/financial/costCenterEdit/:id',
        element: <CostCenterEdit />,
        protectedRoute: ['financialCostCenter:update', 'financialCostCenter:delete']
    },
    {
        module: 3,
        path: '/financial/services',
        element: <FinancialServices />,
        protectedRoute: ['financialServices:update', 'financialServices:delete']
    },
    {
        module: 3,
        path: '/financial/servicesCreate',
        element: <FinancialServicesCreate />,
        protectedRoute: ['financialServices:create']
    },
    {
        module: 3,
        path: '/financial/servicesEdit/:id',
        element: <FinancialServicesEdit />,
        protectedRoute: ['financialServices:update']
    },
    {
        module: 3,
        path: '/financial/products',
        element: <Products />,
        protectedRoute: ['financialProducts:update', 'financialProducts:delete']
    },
    {
        module: 3,
        path: '/financial/productsCreate',
        element: <ProductsCreate />,
        protectedRoute: ['financialProducts:create']
    },
    {
        module: 3,
        path: '/financial/productsEdit/:id',
        element: <ProductsEdit />,
        protectedRoute: ['financialProducts:update']
    },
    {
        module: 3,
        path: '/financial/accounts',
        element: <Accounts />,
        protectedRoute: ['financialAccounts:create', 'financialAccounts:read', 'financialAccounts:update', 'financialAccounts:delete']
    },
    {
        module: 3,
        path: '/financial/AccountsCreate',
        element: <AccountsCreate />,
        protectedRoute: ['financialAccounts:create']
    },
    {
        module: 3,
        path: '/financial/AccountsEdit/:id',
        element: <AccountsEdit />,
        protectedRoute: ['financialAccounts:update', 'financialAccounts:delete']
    },
    {
        module: 3,
        path: '/financial/clientsAndSuppliers',
        element: <ClientsAndSuppliers />,
        protectedRoute: ['financialClientsAndSupplier:create']
    },
    {
        module: 3,
        path: '/financial/clientsAndSuppliers',
        element: <ClientsAndSuppliers />,
        protectedRoute: ['financialClientsAndSupplier:read']
    },
    {
        module: 3,
        path: '/financial/clientsAndSuppliersCreate',
        element: <ClientsAndSuppliersCreate />,
        protectedRoute: ['financialClientsAndSupplier:create']
    },
    {
        module: 3,
        path: '/financial/clientsAndSuppliersEdit/:id',
        element: <ClientsAndSuppliersEdit />,
        protectedRoute: ['financialClientsAndSupplier:update', 'financialClientsAndSupplier:remove']
    },
    {
        module: 3,
        path: '/financial/patrimony',
        element: <Patrimony />,
        protectedRoute: ['financialPatrimony:read']
    },
    {
        module: 3,
        path: '/financial/patrimonyCreate',
        element: <PatrimonyCreate />,
        protectedRoute: ['financialPatrimony:create']
    },
    {
        module: 3,
        path: '/financial/patrimonyEdit/:id',
        element: <PatrimonyEdit />,
        protectedRoute: ['financialPatrimonyEdit:update', 'financialPatrimonyEdit:remove']
    },
    // {
    //     module: 3,
    //     path: '/sectors',
    //     element: <Sectors/>,
    //     protectedRoute: ['financialSectors:create']
    // },
    {
        module: 3,
        path: '/sectorsCreate',
        element: <SectorsCreate/>,
        protectedRoute: ['financialSectors:create', 'financialSectors:update', 'financialSectors:delete']
    },
    {
        module: 3,
        path: '/sectorsEdit/:id',
        element: <SectorsEdit/>,
        protectedRoute: ['financialSectors:update', 'financialSectors:delete']
    },
    {
        module: 3,
        path: '/financial/cashFlow',
        element: <FinancialCashFlow />,
        protectedRoute: ['financialCashFlow:read']
    },
    {
        module: 3,
        path: '/financial/cashFlowEdit/:id',
        element: <FinancialCashFlowEdit />,
        protectedRoute: ['financialCashFlow:update']
    },
    {
        module: 3,
        path: '/financial/bills/:entryType',
        element: <FinancialBills />,
        protectedRoute: ['financialBills:read']
    },
    {
        module: 3,
        path: '/financial/bills/create/:entryType',
        element: <FinancialBillsCreate />,
        protectedRoute: ['financialBills:create']
    },
    {
        module: 3,
        path: '/financial/bills/create/:entryType/:incomeOrExpense',
        element: <FinancialBillsCreate />,
        protectedRoute: ['financialBills:create']
    },
    {
        module: 3,
        path: '/financial/bills/edit/:id',
        element: <FinancialBillsEdit />,
        protectedRoute: ['financialBills:update']
    },
    {
        module: 3,
        path: '/financial/charges',
        element: <FinancialCharges />,
        protectedRoute: ['financialCharges:read']
    },
    {
        module: 3,
        path: '/financial/categories',
        element: <FinancialCategories/>,
        protectedRoute: ['financialCategories:read','financialCategories:create','financialCategories:update', 'financialCategories:delete']
    },
    {
        module: 3,
        path: '/financial',
        element: <FinancialDashboard/>,
        protectedRoute: ['financialDashboards:read']
    },
    {
        module: 3,
        path: '/financial/accounts/:account_id/conciliations',
        element: <FinancialConciliations/>,
        protectedRoute: ['financialConciliations:read']
    },
    {
        module: 3,
        path: '/financial/accounts/:account_id/conciliations/create',
        element: <FinancialConciliationsCreate/>,
        protectedRoute: ['financialConciliations:create']
    },
    {
        module: 3,
        path: '/financial/conciliations/:conciliations_id/transactions',
        element: <FinancialConciliationsTransactions/>,
        protectedRoute: ['financialConciliations:create']
    },
    {
        module: 3,
        path: '/financialReports',
        element: <FinancialReports />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/reportsPerCategory/:incomeOrExpense',
        element: <FinancialReportsPerCategory />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/reportsPerCostCenter/:incomeOrExpense',
        element: <FinancialReportsPerCostCenter />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/reportsCashFlowMonthly',
        element: <FinancialReportsCashFlowMonthly />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/reserveMargin',
        element: <FinancialReserveMargin />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/activitiesMargin',
        element: <FinancialActivitiesMargin />,
        protectedRoute: ['financialReports:read']
    },
    {
        module: 3,
        path: '/financial/config/associationsMonthlyPayment',
        element: <FinancialAssociationsMonthlyPaymentConfig/>,
        protectedRoute: ['financialConfig:read']
    },
    {
        module: 3,
        path: '/financial/config/associationsMonthlyPayment/create',
        element: <FinancialAssociationsMonthlyPaymentConfigCreate/>,
        protectedRoute: ['financialConfig:read']
    },
    {
        module: 3,
        path: '/financial/config/associationsMonthlyPayment/create/:id',
        element: <FinancialAssociationsMonthlyPaymentConfigCreate/>,
        protectedRoute: ['financialConfig:read']
    },
    {
        module: 3,
        path: '/financial/config/rules',
        element: <FinancialRules/>,
        protectedRoute: ['financialRules:read']
    },
    {
        module: 3,
        path: '/financial/config/rules/create',
        element: <FinancialRulesCreate/>,
        protectedRoute: ['financialRules:read']
    },
    {
        module: 3,
        path: '/financial/config/rules/create/:id',
        element: <FinancialRulesCreate/>,
        protectedRoute: ['financialRules:read']
    },
    {
        module: 3,
        path: '/financial/config/configPixQrCode',
        element: <FinancialConfigPixQrCode />,
        protectedRoute: ['financialConfigPixQrCode:read']
    },
    {
        module: 3,
        path: '/financial/config/configPixQrCode/create',
        element: <FinancialConfigPixQrCodeCreate />,
        protectedRoute: ['financialConfigPixQrCode:create']
    },
    {
        module: 3,
        path: '/financial/config/configPixQrCode/:id',
        element: <FinancialConfigPixQrCodeEdit />,
        protectedRoute: ['financialConfigPixQrCode:update']
    }
]
