import {
    FaChartPie,
    FaShoppingCart,
    FaSearchDollar,
    FaMoneyBillWave,
    FaUserTie,
    FaCog,
    FaBook
} from 'react-icons/fa'

export const financialMenuItems = [
    {
        "icon": <FaChartPie />,
        "name": "Dashboard",
        "type": "normal",
        "path": "/financial",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['dashboard:read']
    },
    {
        "icon": <FaMoneyBillWave />,
        "module": 3,
        "name": "Fluxo de caixa",
        "type": "normal",
        "path": "/financial/cashFlow",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['cashFlow:read','cashFlow:update','cashFlow:delete','cashFlow:create']
    },
    {
        "icon": <FaSearchDollar />,
        "module": 3,
        "name": "Vendas",
        "type": "normal",
        "path": "/financial/bills/sales",
        "permissions": [1, 2, 3],
        "permissionsGroup":  ['sales:read','sales:update','sales:delete','sales:create']
    },
    {
        "icon": <FaShoppingCart />,
        "name": "Compras",
        "module": 3,
        "type": "normal",
        "path": "/financial/bills/shopping",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['shopping:read','shopping:update','shopping:delete','shopping:create']
    },
    {
        "icon": <FaUserTie />,
        "name": "Cobranças",
        "module": 3,
        "type": "normal",
        "path": "/financial/charges",
        "permissions": [1, 2, 3, 4],
        "permissionsGroup": ['charges:read','charges:update','charges:delete','charges:create']
    },
    {
        "icon": <FaBook />,
        "name": "Relatórios",
        "type": "normal",
        "path": "/financialReports",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['financialReports:read']
    },
    {
        "icon": <FaCog />,
        "name": "Configuração",
        "module": 3,
        "type": "normal",
        "path": "/configuration",
        "permissions": [1, 2, 3, 4]
    },
]