import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body'
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { Button } from '../../../components/buttons/button.default'
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from "moment";
import { FaCloudDownloadAlt, FaSearch } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { FiSlash } from "react-icons/fi";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";
import { SelectPeriod } from "../../../components/period/selectPeriod";
import { InputDinamicAutoComplete } from "../../../components/input/input.dinamic.autocomplete";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialBills() {
    
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { defaultCallsFinancial } = useApiContext()
    const { entryType } = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext() 
    const { screenX } = useScreenSizeContext()
    const [ incomeOrExpense, setIncomeOrExpense ] = useState()
    const [ filter, setFilter ] = useState({})
    const [ filterConstructor, setFilterConstructor ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ loadingFilter, setLoadingFilter ] = useState(false)
    const [ usersAndClientsList, setUsersAndClientsList ] = useState([])
    const [ suppliersList, setSuppliersList ] = useState([])

    useEffect(() => {
        async function getDataForFilter() {
            const initialFilter = getInitialFilter()
            setLoading(true)
            setFilter(initialFilter)
            setIncomeOrExpense(initialFilter?.incomeOrExpense ?? 'R')
            
            const usersAndClientsAPI = await defaultCallsFinancial.getUsersClientsAndSuppliersWithoutPagination(userData[0].token)
            if (usersAndClientsAPI?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(usersAndClientsAPI.data) 
            }
            setUsersAndClientsList(usersAndClientsAPI.data)

            const suppliersAPI = await defaultCallsFinancial.getClientAndSupplier(userData[0].token)
            if (suppliersAPI?.error){
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(suppliersAPI.data)
            }
            setSuppliersList(suppliersAPI.data?.filter(sup => sup?.isSupplier))

            setLoading(false)
    
        }
        getDataForFilter()
    }, [entryType])

    function getInitialFilter(){
        const defaultFilter = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            finishDate: moment().endOf('month').format('YYYY-MM-DD'),
            entryType,
            incomeOrExpense: entryType  == 'sales' ? 'R'
                : entryType == 'shopping' ? 'D'
                : 'R' 
        }
        try {
            const lastFilter = sessionStorage.getItem(`lastFilter:bills:${entryType}`)
            if(lastFilter){
                const filter = JSON.parse(lastFilter)
                if(filter?.startDate && filter?.finishDate){
                    return filter
                }
            }
            return defaultFilter
        } catch (error) {
            return defaultFilter
        }
    }

    function setAndSaveFilter(filterObj){
        setFilter(filterObj)
        sessionStorage.setItem(`lastFilter:bills:${entryType}`, JSON.stringify(filterObj))
    }

    async function exportTable(){
        setLoading(true)
        const exportAPI = await defaultCallsFinancial.exportBills(userData[0].token, filter)
        if (exportAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(exportAPI.data)
        }

        let fileObj = URL.createObjectURL(exportAPI?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Faturas_${Number((new Date))}`)
        myElement.click()
        setLoading(false)
    }

    const paymentStatusList = [
        { value: 0, description: 'Pendente' },
        { value: 1, description: 'Pago' },
        { value: 2, description: 'Cancelado' },
    ]

    const paymentCondition = [
        { id: 1, description: 'À vista' },
        { id: 2, description: 'Parcelado' },
    ]

    return (
        <>
            <ScreenModal>
                <div className={`p-6 boxShadow duration-300 flex flex-col`}>
                    <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center'>
                        <FaSearch />
                        <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                    </div>
                    {
                        loadingFilter ?
                        <></>
                        :
                        <>
                            <form className="flex flex-col justify-center items-center">
                                {
                                    incomeOrExpense == 'R' ?
                                    <div className="flex flex-row gap-3 items-center justify-center">
                                        <a className="text-gray-400 text-sm w-24">Cliente </a>
                                        <LabelInput>
                                            <InputDinamicAutoComplete
                                                width={72}
                                                height={96}
                                                id={'usersAndClients'}
                                                value={
                                                    filterConstructor?.financialClientsAndSuppliers_id ?
                                                        usersAndClientsList?.find(item => item?.linked == 'financialClientsAndSuppliers' && item?.linked_id == filterConstructor?.financialClientsAndSuppliers_id)?.name
                                                    : filterConstructor?.client_users_id ?
                                                        usersAndClientsList?.find(item => item?.linked == 'client_users_id' && item?.linked_id == filterConstructor?.client_users_id)?.name
                                                    :
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    if (e.linked == "financialClientsAndSuppliers"){
                                                        setFilterConstructor({ ...filterConstructor, financialClientsAndSuppliers_id: e?.linked_id })
                                                    } else {
                                                        setFilterConstructor({ ...filterConstructor, client_users_id: e?.linked_id })
                                                    }
                                                }}
                                                endpoint={'/api/v1/financial/utils/usersClientsAndSuppliers'}
                                                module={'financial'}
                                                selectedLabel={'name'}
                                                optionList={['linked_id', 'name']}
                                                filters={{
                                                    isClient: true
                                                }}
                                                token={userData[0]?.token}
                                                mapTotalPages={['totalPages']}
                                                objectChange='name'
                                            />
                                        </LabelInput>
                                    </div>
                                    :
                                    <div className="flex flex-row gap-3 items-center justify-center">
                                        <a className="text-gray-400 text-sm w-24">{entryType == 'sales' ? 'Cliente' : 'Fornecedor'}</a>
                                        <LabelInput>
                                            <InputAutoComplete
                                                width={72}
                                                data={suppliersList}
                                                id={'financialClientsAndSuppliers_id'}
                                                value={filterConstructor?.financialClientsAndSuppliers_id ? suppliersList?.find(item => item?.id == filterConstructor?.financialClientsAndSuppliers_id)?.name : ''}
                                                onChange={(e) => setFilterConstructor({ ...filterConstructor, financialClientsAndSuppliers_id: e?.id })}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                            />
                                        </LabelInput>
                                    </div>
                                }
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Status do pag. </a>
                                    <LabelInput>
                                        <InputAutoComplete
                                            width={72}
                                            data={paymentStatusList}
                                            id={'paymentStatus'}
                                            value={filterConstructor?.paymentStatus ? paymentStatusList.filter(ps => ps.id == filterConstructor?.paymentStatus)[0]?.description : ''}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, paymentStatus: e?.value })}
                                            selectedLabel={'description'}
                                            optionList={['value', 'description']}
                                        />
                                    </LabelInput>
                                </div>
                                <div className="flex flex-row gap-3 items-center justify-center">
                                    <a className="text-gray-400 text-sm w-24">Forma de pag. </a>
                                    <LabelInput>
                                        <InputAutoComplete
                                            width={72}
                                            data={paymentCondition}
                                            id={'paymentCondition'}
                                            value={filterConstructor?.paymentCondition ? paymentCondition.filter(ps => ps.id == filterConstructor?.paymentCondition)[0]?.description : ''}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, paymentCondition: e?.id })}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                        />
                                    </LabelInput>
                                </div>            
                            </form>
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => {
                                    setAndSaveFilter({ ...filter, filterConstructor })
                                    setFilter({ ...filter, ...filterConstructor })
                                    setShowUniversalModal(false)
                                }} shadow={true}>Pesquisar</Button>
                            </div>
                        </>
                    }
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text={
                            entryType == 'sales' ? 'Vendas'
                                : entryType == 'shopping' ? 'Compras'
                                : entryType == 'single' && incomeOrExpense == 'R' ? 'Receitas Avulsas'
                                : entryType == 'single' && incomeOrExpense == 'D' ? 'Despesas Avulsas'
                                : 'Receitas/Despesas'
                        }/>
                        <Button onClick={() => navigate(`/financial/bills/create/${entryType}`)}>
                            {
                                entryType == 'sales' ? 'Nova venda'
                                    : entryType == 'shopping' ? 'Nova compra'
                                    : entryType == 'single' ? 'Receitas/Despesas Avulsas'
                                    : 'Lançamentos'
                            }
                        </Button>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <div className="flex flex-row w-full justify-center items-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div className="w-full h-full overflow-auto">
                            <div className={`w-full grid ${entryType == 'single' ? 'sm:grid-cols-3' : 'sm:grid-cols-2'} justify-between mb-6`}>
                                {
                                    entryType == 'single' &&
                                    <div className='w-full flex flex-row items-center sm:justify-start justify-center'>
                                        <Tippy content={'Contas a receber'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <button 
                                                className={`
                                                    font-bold shadow-md text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center 
                                                    ${incomeOrExpense === 'R' ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} 
                                                    dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25
                                                `}
                                                onClick={() => {
                                                    setIncomeOrExpense('R')
                                                    setAndSaveFilter({ ...filter, filterConstructor })
                                                }}
                                            >
                                                Receber
                                            </button>
                                        </Tippy>
                                        <Tippy content={'Contas a pagar'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}
                                        >
                                            <button 
                                                className={`
                                                    font-bold shadow-md text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center 
                                                    ${incomeOrExpense === 'D' ? 'bg-red-500 hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-red-300'} 
                                                    dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25
                                                `} 
                                                onClick={() => {
                                                    setIncomeOrExpense('D')
                                                    setAndSaveFilter({...filter, incomeOrExpense: 'D'})
                                                }}
                                            >
                                                Pagar
                                            </button>
                                        </Tippy>
                                    </div>
                                }
                                <div className={`w-full flex ${entryType === 'single' ? 'items-center justify-center' : ''}`}>
                                    <SelectPeriod 
                                        referenceDate={filter?.startDate}
                                        onChange={(e) => {
                                            setAndSaveFilter({ ...filter, ...e })
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row sm:justify-end justify-center items-center gap-3 w-full">
                                    <Tippy content={'Exportar em excel'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaCloudDownloadAlt onClick={() => exportTable()} className="text-xl cursor-pointer text-primaryDefaultLight"/>                                            
                                        </div>
                                    </Tippy>
                                    <Button onClick={() => setShowUniversalModal(true)}>Filtros</Button>
                                    {
                                        Object.keys(filterConstructor).length > 0 &&
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoadingFilter(true)
                                                    setFilterConstructor({})
                                                    setAndSaveFilter({
                                                        incomeOrExpense,
                                                        startDate: filter.startDate,
                                                        finishDate: filter.finishDate,
                                                    })
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoadingFilter(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                            <NewTableDefault 
                                key={'table-bills'}
                                endpoint="/api/v1/financial/bills"
                                token={userData[0]?.token}
                                module="financial"
                                columns={[
                                    {
                                        description: 'ID',
                                        field: 'id',
                                        type: 'number',
                                    },
                                    {
                                        description: 'Data do lançamento',
                                        field: 'entryDate',
                                        type: 'date',
                                    },
                                    {
                                        description: entryType == 'single' && incomeOrExpense == 'R' ? 'Cliente'
                                            : entryType == 'single' && incomeOrExpense == 'D' ? 'Fornecedor' 
                                            : entryType == 'shopping' ? 'Fornecedor'
                                            : 'Cliente',
                                        field: 'clientOrSupplier_name',
                                        type: 'string',
                                        newFieldValue: (line) => {
                                            if(line.financialClientsAndSuppliers_name){
                                                return line.financialClientsAndSuppliers_name
                                            }
                                            if(line.client_users_name){
                                                return line.client_users_name
                                            }
                                            return '-'
                                        }
                                    },
                                    {
                                        description: 'Condição',
                                        field: 'paymentCondition',
                                        type: 'string',
                                        newFieldValue: (line) => {
                                            if(line.paymentCondition == 1){
                                                return 'A vista'
                                            }
                                            return 'Parcelado'
                                        }
                                    },
                                    {
                                        description: 'Qtd. Parcelas',
                                        field: 'numberOfInstallments',
                                        type: 'number',
                                    },
                                    {
                                        description: 'Valor total',
                                        field: 'totalValue',
                                        type: 'money',
                                    }
                                ]}
                                mapApiData={['data']}
                                filters={filter}
                                usePagination={true}
                                mapQuantityOfRegisters={['quantity']}
                                mapTotalPages={['totalPages']}
                                onClickInTableLine={(row) => navigate(`/financial/bills/edit/${row?.id}`)}
                            />
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}