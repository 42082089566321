import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Footer } from '../../../components/footer/Footer'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { LabelInput } from '../../../components/label/label.input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { justNumber } from '../../../utils/validators/justNumber'
import { FaQuestionCircle } from 'react-icons/fa'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { maxNumber } from '../../../utils/mask/maxNumber'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function ActivitiesPlansEdit() {

    const { api, defaultCallsSchedules, defaultCallsAuth } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const plan = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(true)

    const [activities, setActivities] = useState([])
    const [selectedActivities, setSelectedActivities] = useState('')
    const [planName, setPlanName] = useState('')
    const [typeOfCharge, setTypeOfCharge] = useState('P')
    const [numberOfMonth, setNumberOfMonth] = useState(0)
    const [numberOfInstallments, setNumberOfInstallments] = useState(0)
    const [cancelFee, setCancellationFee] = useState(0)
    const [activitiesPlansValue, setActivitiesPlansValue] = useState([])
    const [active, setActive] = useState(true)
    const [activeHistory, setActiveHistory] = useState(true)

    useEffect(() => { getData() }, [])

    async function getData() {

        const associations = await defaultCallsAuth.getAssociations(userData[0].token, 1)
        const planData = await defaultCallsSchedules.getActivitiesPlansById(userData[0].token, plan.id)

        if (!location?.state?.selectedActivities) {
            const activities = await defaultCallsSchedules.getActivities(userData[0].token)
            setActivities(activities.data)
            setSelectedActivities(activities.data.filter(activity => activity.id == planData.data[0].activities_id)[0])
        } else {
            setSelectedActivities(location?.state?.selectedActivities)
        }

        setPlanName(planData.data[0]?.planName)
        setTypeOfCharge(planData.data[0]?.typeOfCharge)
        setNumberOfMonth(planData.data[0]?.numberOfMonth)
        setNumberOfInstallments(planData.data[0]?.numberOfInstallments)
        setCancellationFee(planData.data[0]?.cancellationFee)
        setActiveHistory(planData.data[0]?.active)
        setActive(planData.data[0]?.active)
        setActivitiesPlansValue(associations.data.map((association) => {
            const exists = planData.data[0]?.valuesPerAssociations.filter(value => value.associations_id == association.id)
            if (exists.length > 0) {
                return {
                    associations_id: association.id,
                    name: association.name,
                    value: exists[0].value,
                    enabled: true
                }
            }
            return {
                associations_id: association.id,
                name: association.name,
                value: 0,
                enabled: false
            }
        }))

        setLoading(false)

    }

    async function handleSubmitEdit() {

        const fields =
            [
                { "name": 'planName', "value": planName, "required": true, "type": 'string' },
                { "name": 'numberOfMonth', "value": numberOfMonth, "required": true, "type": 'string' },
                { "name": 'numberOfInstallments', "value": numberOfInstallments, "required": true, "type": 'string' },
            ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        if (parseInt(numberOfInstallments) === 0 || parseInt(numberOfMonth) === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Os campos número de meses e número de parcelas, devem ser maior que zero')
        }

        setLoading(true)

        try {
            await api.put(`/api/v1/activitiesPlans/${plan.id}`, {
                activities_id: selectedActivities.id,
                planName,
                typeOfCharge: 'P',
                numberOfMonth,
                numberOfInstallments: numberOfInstallments,
                cancellationFee: cancelFee > 0 ? cancelFee : 0,
                valuesPerAssociations: activitiesPlansValue.map(planVal => {
                    return {
                        associations_id: planVal.associations_id,
                        value: planVal.value,
                        enabled: planVal.enabled
                    }
                })
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (active !== activeHistory) {
                await api.put(`/api/v1/activitiesPlans/${plan.id}/active/${active ? 1 : 0}`, {

                },
                    {
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`
                        }
                    })
            }

            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText(`Plano alterado com sucesso!`)
            history.back()
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Editar plano'}></Title>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ? (
                        <div className='flex flex-col w-full items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                    ) : (
                        <div className='flex flex-col items-start justify-start w-full gap-8'>
                            <LabelInput text={'Atividade'}>
                                <InputAutoComplete
                                    data={activities}
                                    selectedLabel={['activitiesName']}
                                    optionList={['activitiesName']}
                                    id='selectActivities'
                                    placeHolder={'-'}
                                    preSelectedValue={selectedActivities?.activitiesName}
                                    onChange={(e) => setSelectedActivities(e)}
                                />
                            </LabelInput>
                            <div className='flex gap-4'>
                                <LabelInput text={'Nome'}>
                                    <Input id='planName' type={'text'} charLimit={40} onChange={(e) => setPlanName(e.target.value)} value={planName} />
                                </LabelInput>
                                <LabelInput text={'Ativo'}>
                                    <Toogle status={active} onClick={() => setActive(!active)} />
                                </LabelInput>
                            </div>
                            {/* <div className='flex flex-col gap-2'>
                                <p className='text-sm text-inputPlaceholderLight'>Tipo de cobrança:</p>
                                <div className='flex flex-col sm:flex-row gap-2'>
                                    <div className='flex flex-col sm:flex-row gap-2'>
                                        <InputCheckBox value={typeOfCharge == 'P' ? true : false} onClick={() => setTypeOfCharge('P')} />
                                        <a className='text-sm text-inputPlaceholderLight'>Parcelamento</a>
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-2'>
                                        <InputCheckBox value={typeOfCharge == 'R' ? true : false} onClick={() => setTypeOfCharge('R')} />
                                        <a className='text-sm text-inputPlaceholderLight'>Recorrente</a>
                                    </div>
                                </div>
                            </div>
                            <p className='bg-gray-200 text-sm text-gray-400 p-4 rounded-md'>
                                {
                                    typeOfCharge == 'P' 
                                        ? `Parcelamento: Ao realizar o pagamento o cliente terá o saldo do cartão consumido 1 vez pelo valor total informado.`
                                        :  `Recorrente: Ao realizar o pagamento o cliente irá mensalmente receber o desconto do valor total dívido pela quantidade de parcelas que ele deseja.`
                                }
                            </p> */}
                            <div className='flex flex-col w-full items-start justify-start gap-4'>
                                <div className='flex flex-col sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center'>
                                        <Tippy content={'Corresponde ao número de meses de duração do plano.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Número de meses</a>
                                    </div>
                                    <Input charLimit={2} id="numberOfMonth" width={40} type={'text'} onChange={(e) => setNumberOfMonth(justNumber(e.target.value))} value={numberOfMonth} />
                                </div>
                                <div className='flex flex-col sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center'>
                                        <Tippy content={'Permite parcelamento em até x vezes.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Número de parcelas</a>
                                    </div>
                                    <Input charLimit={2} id='numberOfInstallments' width={40} type={'text'} onChange={(e) => setNumberOfInstallments(maxNumber(12,justNumber(e.target.value)))} value={numberOfInstallments} />
                                </div>
                                <div className='flex flex-col sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center justify-start'>
                                        <Tippy content={'Informe a porcentagem de multa a ser cobrada sobre o valor dos meses vincendos. Valor zero não vai cobrar multa.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Multa de cancelamento</a>
                                    </div>
                                    <Input charLimit={3} width={40} type={'text'} onChange={(e) => setCancellationFee(justNumber(e.target.value))} value={cancelFee} />
                                    <a className='text-sm text-inputPlaceholderLight'>%</a>
                                </div>
                            </div>
                            <div className='flex flex-col w-full items-start justify-start gap-4'>
                                <div className='flex flex-col w-full border-b border-gray-300'>
                                    <h1 className='text-primaryDefaultLight text-base'>Valores por associação</h1>
                                </div>
                                {
                                    activitiesPlansValue.map((association, index) => {
                                        return (
                                            <div className='flex flex-col sm:flex-row gap-2 items-center justify-center'>
                                                <div className='w-48 flex flex-col sm:flex-row gap-2 items-center'>
                                                    <InputCheckBox value={[true, false].includes(activitiesPlansValue[index].enabled) ? association.enabled : false} onClick={async () => {
                                                        activitiesPlansValue[index].enabled = [true, false].includes(activitiesPlansValue[index].enabled) ? !activitiesPlansValue[index].enabled : true
                                                        if (!activitiesPlansValue[index].enabled) {
                                                            activitiesPlansValue[index].value = 0
                                                        }
                                                        setActivitiesPlansValue([...activitiesPlansValue])
                                                    }}></InputCheckBox>
                                                    <a className='text-sm text-inputPlaceholderLight font-bold'>{association.name}</a>
                                                </div>
                                                {
                                                    activitiesPlansValue[index]?.enabled ?
                                                        <div className='w-40'>
                                                            <LabelInput>
                                                                <CurrencyInput
                                                                    id={`association-${activitiesPlansValue[index]?.associations_id}`}
                                                                    placeHolder={`R$ ${(activitiesPlansValue[index]?.value ? activitiesPlansValue[index]?.value : '0').toString()?.replace('.', ',')}`}
                                                                    width={40}
                                                                    onChange={(e) => {
                                                                        association.value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                        setActivitiesPlansValue([...activitiesPlansValue])
                                                                    }}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        : <LabelInput><Input disabled={true} placeholder={'R$'} width={40} /></LabelInput>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                    <Button shadow={true} onClick={() => {
                        handleSubmitEdit()
                    }} >Editar</Button>
                </div>
            </Footer>
        </Container>
    )
}