import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
//components
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Footer } from '../../../components/footer/Footer'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { LabelInput } from '../../../components/label/label.input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { justNumber } from '../../../utils/validators/justNumber'
import { FaQuestionCircle } from 'react-icons/fa'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { maxNumber } from '../../../utils/mask/maxNumber'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'


export function ActivitiesPlansCreate() {

    
    const { defaultCallsSchedules, api, defaultCallsAuth } = useApiContext()
    const navigate = useNavigate()
    const location = useLocation()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(true)

    const [associationList, setAssociationList] = useState([])
    const [activitiesList, setActivitiesList] = useState([])

    const [planName, setPlanName] = useState('')
    const [typeOfCharge, setTypeOfCharge] = useState({ id: 1, description: 'P' })
    const [numberOfMonth, setNumberOfMonth] = useState(null)
    const [numberOfInstallments, setNumberOfInstallments] = useState(null)
    const [cancelFee, setCancelFee] = useState(null)
    const [selectedActivities, setSelectedActivities] = useState({})

    async function getData() {
        if(!location?.state?.selectedActivities){
            const activitiesData = await defaultCallsSchedules.getActivities(userData[0].token, 1)
            if (activitiesData.error) {
                setLoading(false)
                setShowNotificationModal(activitiesData.data)
                return
            }
            setActivitiesList(activitiesData.data)
        }else{
            setSelectedActivities(location?.state?.selectedActivities)
        }

        const associationData = await defaultCallsAuth.getAssociations(userData[0].token, 1)
        if (associationData.error) {
            setLoading(false)
            setShowNotificationModal(associationData.data)
            return
        }
        setAssociationList(associationData.data.map(association => { return {...association, enabled: false, value: 0} }))
        setLoading(false)
    }

    useEffect(() => {

        getData()

    }, [])

    async function handleSubmit() {

        const fields =
            [
                { "name": 'planName', "value": planName, "required": true, "type": 'string' },
                { "name": 'numberOfMonth', "value": numberOfMonth, "required": true, "type": 'string' },
                { "name": 'numberOfInstallments', "value": numberOfInstallments, "required": true, "type": 'string' },
            ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        if (parseInt(numberOfInstallments) === 0 || parseInt(numberOfMonth) === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Os campos número de meses e número de parcelas, devem ser maior que zero')
        }

        setLoading(true)

        try {

            const valuesPerAssociations = associationList.map(association => {
                return {
                    associations_id: association.id,
                    value: association.value,
                    enabled: association.enabled
                }
            })
            await api.post(`/api/v1/activitiesPlans`, {
                activities_id: selectedActivities.id,
                planName,
                typeOfCharge: 'P', //Decidido que terá apenas parcelamentos no dia 12/04/2023 - mantido recorrencia em caso de updates futuros
                numberOfMonth,
                numberOfInstallments: numberOfInstallments,
                cancellationFee: cancelFee,
                valuesPerAssociations: valuesPerAssociations.length > 0 ? valuesPerAssociations : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText(`Plano cadastrado com sucesso!`)
            history.back()
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo plano'}></Title>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col w-full items-center justify-center h-96'>

                        </div>
                        :
                        <div className='flex flex-col items-start justify-start w-full gap-6'>
                            {
                                !location?.state?.selectedActivities ? (
                                    <LabelInput text={'Atividade'}>
                                        <InputAutoComplete 
                                            data={activitiesList}
                                            selectedLabel={['activitiesName']}
                                            optionList={['activitiesName']}
                                            id='selectActivities'
                                            placeHolder={'-'}
                                            preSelectedValue={selectedActivities?.activitiesName}
                                            onChange={(e) => setSelectedActivities(e)}
                                        />
                                    </LabelInput>
                                ) : (
                                    <p><b>Atividade:</b> {location?.state?.selectedActivities?.id} - {location?.state?.selectedActivities?.activitiesName}</p>
                                )
                            }
                            <div className='flex flex-col'>
                                <LabelInput text={'Nome'}>
                                    <Input id='planName' type={'text'} charLimit={40} onChange={(e) => setPlanName(e.target.value)} value={planName}></Input>
                                </LabelInput>
                                <div className='flex flex-row gap-1'>
                                    <a className='text-sm text-inputPlaceholderLight'>{planName?.length}</a>
                                    <a className='text-sm text-inputPlaceholderLight'>de 40.</a>
                                </div>
                            </div>
                            {/* <div className='flex flex-col gap-2'>
                                <p className='text-sm text-inputPlaceholderLight'>Tipo de cobrança:</p>
                                <div className='flex-col flex sm:flex-row gap-2'>
                                    <div className='flex-col flex sm:flex-row gap-2'>
                                        <InputCheckBox value={typeOfCharge.id === 1 ? true : false} onClick={() => setTypeOfCharge({ id: 1, description: 'P' })}></InputCheckBox>
                                        <a className='text-sm text-inputPlaceholderLight'>Parcelamento</a>
                                    </div>
                                    <div className='flex-col flex sm:flex-row gap-2'>
                                        <InputCheckBox value={typeOfCharge.id === 0 ? true : false} onClick={() => setTypeOfCharge({ id: 0, description: 'R' })}></InputCheckBox>
                                        <a className='text-sm text-inputPlaceholderLight'>Recorrente</a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <p className='bg-gray-200 text-sm text-gray-400 p-4 rounded-md'>
                                {
                                    typeOfCharge.id == 1 
                                        ? `Parcelamento: Ao realizar o pagamento o cliente terá o saldo do cartão consumido 1 vez pelo valor total informado.`
                                        :  `Recorrente: Ao realizar o pagamento o cliente irá mensalmente receber o desconto do valor total dívido pela quantidade de parcelas que ele deseja.`
                                }
                            </p> */}
                            <div className='flex flex-col w-full items-start justify-start gap-4'>
                                <div className='flex-col flex sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center'>
                                        <Tippy content={'Corresponde ao número de meses de duração do plano.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Número de meses</a>
                                    </div>
                                    <Input charLimit={2} id='numberOfMonth' width={40} type={'text'} onChange={(e) => setNumberOfMonth(justNumber(e.target.value))} value={numberOfMonth}></Input>
                                </div>
                                <div className='flex-col flex sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center'>
                                        <Tippy content={'Permite parcelamento em até x vezes.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Número de parcelas</a>
                                    </div>
                                    <Input charLimit={2} max={12} id='numberOfInstallments' width={40} type={'text'} onChange={(e) => setNumberOfInstallments(maxNumber(12,justNumber(e.target.value)))} value={numberOfInstallments}></Input>
                                </div>
                                <div className='flex-col flex sm:flex-row gap-2'>
                                    <div className='w-48 flex flex-row gap-1 items-center justify-start'>
                                        <Tippy content={'Informe a porcentagem de multa a ser cobrada sobre o valor dos meses vincendos. Valor zero não vai cobrar multa.'}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='dark:text-titleGrayTextDark'>
                                                <FaQuestionCircle />
                                            </div>
                                        </Tippy>
                                        <a className='text-sm text-inputPlaceholderLight'>Multa de cancelamento</a>
                                    </div>
                                    <Input charLimit={3} width={40} type={'text'} onChange={(e) => setCancelFee(justNumber(e.target.value))} value={cancelFee}></Input>
                                    <a className='text-sm text-inputPlaceholderLight'>%</a>
                                </div>
                            </div>
                            <div className='flex flex-col w-full items-start justify-start gap-4'>
                                <div className='flex flex-col w-full border-b border-gray-300'>
                                    <h1 className='text-primaryDefaultLight text-base'>Valores por associação</h1>
                                </div>
                                {
                                    associationList.map((association, index) => {
                                        return (
                                            <>
                                                <div className='flex flex-row gap-0 items-center w-fit'>
                                                    <div className='flex-col flex sm:flex-row gap-2 w-40'>
                                                        <InputCheckBox value={[true, false].includes(associationList[index].enabled) ? association.enabled : false} 
                                                        onClick={async () => {
                                                            associationList[index].enabled = [true, false].includes(associationList[index].enabled) ? !associationList[index].enabled : true
                                                            if(!associationList[index].enabled){
                                                                associationList[index].value = 0
                                                            }
                                                            setAssociationList([...associationList])
                                                        }}></InputCheckBox>
                                                        <a className='text-sm text-inputPlaceholderLight'>{association.name}</a>
                                                    </div>
                                                    {
                                                        associationList[index]?.enabled ? 
                                                            <div className='w-40'>
                                                                <LabelInput>
                                                                    <CurrencyInput 
                                                                        id={`association-${associationList[index].id}`}
                                                                        placeHolder={`R$ ${(associationList[index]?.value ? associationList[index]?.value: '0').toString()?.replace('.', ',')}`} 
                                                                        width={40}
                                                                        onChange={(e) => {
                                                                            associationList[index].value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                            setAssociationList([...associationList])
                                                                        }}
                                                                    />
                                                                </LabelInput>
                                                            </div>
                                                        : <LabelInput><Input disabled={true} placeholder={'R$'} width={40}/></LabelInput>
                                                    }
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                    <Button shadow={true} onClick={() => {
                        handleSubmit()
                    }} >Cadastrar</Button>
                </div>
            </Footer>
        </Container>
    )
}