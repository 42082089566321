import React, { createContext, useContext, useEffect, useState } from 'react';

const GlobalContext = createContext();

export function GlobalContextProvider({ children }) {

    const [userData, setUserData] = useState('')
    const [showUniversalModal, setShowUniversalModal] = useState(false)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showNotificationModalText, setShowNotificationModalText] = useState('')
    const [showNotificationModalSuccess, setShowNotificationModalSuccess] = useState(false)
    const [signed, setSigned] = useState()
    const [isFreeTrial, setIsFreeTrial] = useState(false)
    const [hasExpiredPlan, setHasExpiredPlan] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(false)

    return (
        <GlobalContext.Provider value={{
            userData, setUserData,
            showUniversalModal, setShowUniversalModal,
            showNotificationModal, setShowNotificationModal,
            showNotificationModalText, setShowNotificationModalText,
            showNotificationModalSuccess, setShowNotificationModalSuccess,
            signed, setSigned,
            isFreeTrial, setIsFreeTrial,
            hasExpiredPlan, setHasExpiredPlan,
            loadingAuth, setLoadingAuth
        }}>
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobalContext() {
    return useContext(GlobalContext);
}

