import React, { useEffect, useState } from "react";
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Button } from "../../../components/buttons/button.default";
import { FaFile } from "react-icons/fa";
import { permittedFiles } from "../../../utils/permittedFiles/permittedFiles";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { useAuth } from "../../../contexts/useAuth";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { TableDefault } from "../../../components/table/table.default";
import { LoginLoader } from "../../../components/loaders/loginLoader";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { ProgressBar } from "../../../components/progressBar/progressBar";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function UsersImport() {

    const { defaultCallsAuth, api_auth } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [dragging, setDragging] = useState(false);
    const [loading, setLoading] = useState(false)
    const [resultImport, setResultImport] = useState('')
    const [file, setFile] = useState('')
    const [startLoadingImport, setStartLoadingImport] = useState(false)
    const [progress, setProgress] = useState(0.50)
    const [showErros, setShowErrors] = useState(false)

    useEffect(() => {
        setShowUniversalModal(false)
    }, [])

    useEffect(() => {
        let intervalId;
        let quantity = 0
        let newProgress = 1
        let diference = 0;

        const fetchData = async () => {
            try {
                const response = await defaultCallsAuth.getImportProgress(userData[0]?.token);

                if (response.data[0]?.runned > 0) {

                    quantity = response.data[0]?.total
                    newProgress = response.data[0]?.runned
                    diference = (newProgress / quantity) * 100

                    setProgress((prevProgress) =>
                        prevProgress < 90 ? diference : prevProgress < 99 ? prevProgress + 0.5 : prevProgress
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (startLoadingImport) {
            intervalId = setInterval(() => {
                fetchData();
            }, 8000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [startLoadingImport]);

    const handleDownload = () => {
        const originalUrl = window.location

        const url = `${originalUrl}/layout-importação.csv`;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'layout-importação.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const newUsersThead = [
        {
            "name": 'Nome',
            "original_name": 'firstName'
        },
        {
            "name": 'Sobre nome',
            "original_name": "lastName"
        },
        {
            "name": 'CPF',
            "original_name": "cpf"
        },
        {
            "name": 'E-mail',
            "original_name": 'email'
        },
        {
            "name": 'Matricula',
            "original_name": 'registrationCode'
        },

    ]

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const files = [...e.dataTransfer.files];

        if (files[0]?.type !== 'text/csv') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Somente arquivo do tipo .csv são permitidos!')
        } else {
            setShowNotificationModal(false)
            setFile(files[0])
        }
    }

    function handleInputFile() {

        let input = document.getElementById('importFile')
        input.click()
    }

    function handleNewFile(e) {
        if (e.type !== 'text/csv') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Somente arquivo do tipo .csv são permitidos!')
        } else {
            setShowNotificationModal(false)
            setFile(e)
        }
    }

    async function handleImportUsers() {

        if (!file) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Nenhum arquivo selecionado')
        }
        setLoading(true)

        const formData = new FormData()
        formData.append('file', file)

        setStartLoadingImport(true)
        try {
            const newImport = await api_auth.post(`/api/v1/auth/users/import`, formData, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setResultImport(newImport.data)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Importacão realizada com sucesso')
            setFile('')
            setLoading(false)
            setShowUniversalModal(true)
            setStartLoadingImport(false)
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setStartLoadingImport(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)                
                setStartLoadingImport(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className="p-4 min-w-[80vw] min-h-[80vh] max-h-[80vh] flex flex-col gap-2 items-center justify-start overflow-auto">
                    <p className="text-sm text-green-600 dark:text-green-500 font-bold">Importacão concluída!</p>
                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Detalhes da importacão</p>
                    <div className="flex gap-2 items-start justify-start" >
                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Novos: </strong> {resultImport?.totalNewUsers}</p>
                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Demitidos: </strong> {resultImport?.totalDismissedUsers}</p>
                        <a onClick={() => setShowErrors(true)} className="cursor-pointer hover:underline text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Erros: </strong> {resultImport?.totalErrors}</a>
                    </div>
                    {
                        showErros ?
                            resultImport?.errorsDetails?.length > 0 &&
                            <div className="w-full flex flex-col mb-8 gap-2">
                                <div className="w-44">
                                    <Button shadow={true} onClick={() => setShowErrors(false)}>Voltar</Button>
                                </div>
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mb-2 font-bold">Novos usuários</p>
                                <div className="max-h-[300px] overflow-auto shadow-md flex flex-col gap-2 p-1">
                                    {
                                        resultImport?.errorsDetails?.map(error => {
                                            return <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{error}</a>
                                        })
                                    }
                                </div>
                            </div>
                            :
                            <>
                                {
                                    resultImport?.newUsers?.length > 0 &&
                                    <div className="w-full flex flex-col mb-8">
                                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mb-2 font-bold">Novos usuários</p>
                                        <div className="max-h-[300px] overflow-auto shadow-md">
                                            <TableDefault
                                                data={resultImport?.newUsers}
                                                collumns={["firstName", "lastName", "cpf", "email", "registrationCode"]}
                                                onClick={(e) => ''}
                                                title={newUsersThead}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    resultImport?.dismissedUsers?.length > 0 &&
                                    <div className="w-full flex flex-col mb-8">
                                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mb-2 font-bold">Usuários demitidos</p>
                                        <div className="max-h-[300px] overflow-auto shadow-md">
                                            <TableDefault
                                                data={resultImport?.dismissedUsers}
                                                collumns={["firstName", "lastName", "cpf", "email", "registrationCode"]}
                                                onClick={(e) => ''}
                                                title={newUsersThead}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                    }
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Importação/atualização usuários clientes'} />
                </TitlePage>
                <Body>
                    <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                        <h1 className="text-base text-red-600 p-1 mb-2 ">Atenção as regras de importacão</h1>
                        <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'><strong>Regras de importacão: </strong>Utilizar o CSV Base para importacão, o arquvio poderá ser baixado no botão abaixo ou clicando <a href="/files/importadores/layout-importação.csv" download={true} className="cursor-pointer hover:text-primaryDefaultLight underline">aqui</a></p>
                        <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'><strong>Novos Clientes: </strong>Os clientes que vierem pela importacão e ainda não existirem no sistema, serão criados automaticamente, todos entrarão na associaçao "Funcionários"</p>
                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'><strong>Atualizaçao Clientes: </strong>Os clientes que existem no sistema, mas não vierem no arquivo, serão automaticamente movidos para associação "Comunidade"</p>
                        <p className='mb-2 text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'><strong>Campos obrigatórios: </strong>Os campos "CPF", "E-mail" e "Matricula" são obrigatórios, no entanto, pode preencher apenas 1 deles, o login do cliente será o campo preenchido, e a senha o mesmo campo, por exemplo: Preencheu-se o e-mail como: exemplo@teste.com o login sera este e a senha também.</p>
                        <Button shadow={true}><a href="/files/importadores/layout-importação.csv" download={true}>Baixar</a></Button>

                    </div>
                    {
                        startLoadingImport ?
                            <div className="w-full h-[300px] p-2 gap-2 border border-zinc-200 bg-zinc-50 dark:bg-thirdDefaultDark rounded-lg flex flex-col items-center justify-center">
                                <LoginLoader />
                                <div className="w-[300px]">
                                    <ProgressBar progress={progress} />
                                </div>
                            </div>
                            :
                            loading ?
                                <div className="w-full h-[300px] p-2 gap-2 border border-zinc-200 bg-zinc-50 dark:bg-thirdDefaultDark rounded-lg flex flex-col items-center justify-center">
                                    <LoginLoader />
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Importando</p>
                                </div>
                                :
                                <>
                                    <div
                                        className={` w-full p-2 gap-2 border border-zinc-200 dark:border-primaryBorderDark ${dragging ? 'bg-zinc-300  dark:bg-zinc-700' : 'dark:bg-thirdDefaultDark bg-zinc-50'} rounded-sm flex flex-col items-center justify-center`}
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                        onDragOver={handleDragEnter}
                                        onDrop={handleDrop}
                                        style={{
                                            height: 300,
                                        }}
                                    >
                                        <FaFile className="text-3xl text-primaryDefaultLight" />
                                        {dragging && file === '' ? <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Solte para enviar este arquivo</p> : file === '' && <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Arraste e solte um arquivo aqui</p>}
                                        {
                                            file !== '' && <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{file.name}</p>

                                        }
                                        <input id='importFile' type="file" className="hidden" onChange={(e) => handleNewFile(e.target.files[0])}></input>
                                        <Button onClick={() => handleInputFile()}>{file === '' ? 'Selecionar arquivo' : 'Alterar arquivo'}</Button>

                                    </div>
                                    <Button onClick={() => handleImportUsers()}>Importar</Button>
                                </>
                    }

                </Body>
            </Container>
        </>

    )
}