import React, { useEffect, useState } from 'react'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { FaArrowCircleLeft, FaArrowAltCircleRight, FaPlus, FaInfoCircle } from 'react-icons/fa'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Button } from '../../../components/buttons/button.default'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
//UTILS
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { Footer } from '../../../components/footer/Footer'
import { validateFields } from '../../../utils/form.validator'
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { useNavigate } from 'react-router-dom'
import { verifyFileSize } from '../../../utils/validators/verifyFileSize'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'
import { Toogle } from '../../../components/toogle/Toogle'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function AgreementsCreate() {

    const {api, defaultCallsSchedules, defaultCallsFinancial} = useApiContext()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)
    const [showCategorieCreate, setShowCategorieCreate] = useState(false)
    const [isFeatured, setIsFeatured] = useState(false)
    const [newCategorieTitle, setNewCategorieTitle] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [selectedCategorie, setSelectedCategorie] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [link, setLink] = useState('')
    const [email, setEmail] = useState('')
    const [categoriesList, setCategoriesList] = useState([])
    //photos
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [agreementAppendPhotos, setAgreementAppendPhotos] = useState([])

    async function getData() {
        setLoading(true)
        const data = await defaultCallsSchedules.getAgreementsCategories(userData[0]?.token)
        setCategoriesList(data.data)
        if (data.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(data.data)
        }
        setLoading(false)
    }

    useEffect(() => {

        getData()

    }, [])

    //pictures
    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        if (agreementAppendPhotos.length < 3) {
            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(e, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })
            const photoObj = URL.createObjectURL(e.target.files[0])
            setAgreementAppendPhotos([{ photoObj, file: formattedImg }, ...agreementAppendPhotos])
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto - 1].photoObj
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto + 1].photoObj
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo.photoObj + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        if (photo.isMaster) {
            let photos = []
            agreementAppendPhotos.filter(photoToRemove => photoToRemove.file.name !== photo.file.name).map((newPhotos, index) => {
                if (index === 0) {
                    photos.push({
                        ...newPhotos,
                        isMaster: true,
                        isMasterName: newPhotos.file.name
                    })
                } else {
                    photos.push({
                        ...newPhotos,
                        isMaster: false
                    })
                }
            })
            setAgreementAppendPhotos(photos)
        } else {
            setAgreementAppendPhotos(agreementAppendPhotos.filter(photoToRemove => photoToRemove.file.name !== photo.file.name))
        }

        document.getElementById(photo.photoObj + '-' + indexPhoto).style.transform = 'scale(1)'
    }

    async function handleSubmit() {

        let categorie = ''
        if (typeof (selectedCategorie) === 'string' && selectedCategorie?.trim() !== '') {
            categorie = selectedCategorie
        } else if (selectedCategorie?.categories) {
            categorie = selectedCategorie.categories
        }

        const fields = [
            { "name": 'title', "value": title, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
            { "name": 'categories', "value": categorie, "required": true, "type": 'string' },
        ]

        if (validateFields(fields)?.length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)

        try {
            const newAgreement = await api.post(`/api/v1/agreements`, {
                "title": title,
                "description": description,
                "categories": categorie,
                isFeatured,
                "contacts": {
                    "phone": phone,
                    "address": address,
                    "link": link,
                    "email": email
                }
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            let agreementId = newAgreement.data.data[0]?.id

            if (agreementAppendPhotos.length > 0) {
                const formData = new FormData();
                agreementAppendPhotos.map((photo) => {
                    formData.append('images', photo.file)
                })
                await api.post(`/api/v1/agreementsPictures/agreements_id/${agreementId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            }
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setLoading(false)
            navigate('/agreements')
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleNewCategorie() {

        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 30))
        let newCategories = []
        newCategories = categoriesList
        newCategories = newCategories.concat({ categories: newCategorieTitle, id: 0 })
        setCategoriesList(newCategories)
        setSelectedCategorie(newCategories[newCategories?.length - 1])
        await new Promise(resolve => setTimeout(resolve, 30))
        setShowCategorieCreate(false)
        setLoading(false)
    }

    function createNewCategorie() {

        setCategoriesList(categoriesList.filter(categorie => categorie.id !== 0))
        setNewCategorieTitle('')
        setShowCategorieCreate(true)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo convênio'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col h-96 w-full items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='w-full items-start justify-start flex flex-col gap-4'>
                                <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                    <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                        {
                                            showCategorieCreate ?
                                                <div className='flex flex-col gap-2'>
                                                    <LabelInput text={'Titulo da nova categoria'}>
                                                        <Input onChange={(e) => setNewCategorieTitle(e.target.value)} value={newCategorieTitle} />
                                                    </LabelInput>
                                                    <div className='w-96 flex flex-row gap-2'>
                                                        <Button onClick={() => handleNewCategorie()} shadow={true}>Salvar</Button>
                                                        <Button approval={false} onClick={() => { setNewCategorieTitle(''); setShowCategorieCreate(false) }} shadow={true}>Cancelar</Button>
                                                    </div>
                                                </div>
                                                :
                                                <div className='flex flex-col items-start justify-start'>
                                                    <LabelInput text={'Categoria'}>
                                                        <InputAutoComplete id={'categories'} preSelectedValue={selectedCategorie?.categories} data={categoriesList} onChange={(e) => setSelectedCategorie(e)} optionList={['categories']} selectedLabel={'categories'} />
                                                    </LabelInput>
                                                    <Tippy content={<p>Criar nova categoria</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div onClick={() => createNewCategorie()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Nova</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                        }
                                        <div className='flex flex-col items-start justify-start'>
                                            <LabelInput text={'Titulo'}>
                                                <Input charLimit={80} id='title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                            </LabelInput>
                                            <a className='text-xt text-inputPlaceholderLight'>{title?.length} de 80</a>
                                        </div>
                                    </div>
                                    <Tippy content={'Ative caso esta convênio seja destaque (para integração com site).'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                    >
                                        <div>
                                            <LabelInput text={'Destaque?'}>
                                                <Toogle value={isFeatured} status={isFeatured} onClick={() => setIsFeatured(!isFeatured)} />
                                            </LabelInput>
                                        </div>
                                    </Tippy>
                                    <a className='text-inputPlaceholderLight text-sm'>Descrição</a>
                                    <textarea 
                                        maxLength={1000} 
                                        id='description' 
                                        value={description} 
                                        onChange={(e) => setDescription(e.target.value)} 
                                        wrap="hard" 
                                        className="p-2 border border-gray-400 border-opacity-75 h-64 w-full text-sm
                                                   outline-none transition-all duration-200 focus:shadow-borderShadow
                                                   dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark" 
                                    />
                                    <a className='text-xt text-inputPlaceholderLight'>{description?.length} de 1000</a>
                                </div>
                                <div className='flex flex-row flex-wrap gap-4 items-start justify-start'>
                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                        <LabelInput text={'Telefone'}>
                                            <Input 
                                                charLimit={20} 
                                                value={phone ? maskPhone(String(phone)) : ''} 
                                                onChange={(e) => setPhone(e.target.value)} 
                                            />
                                        </LabelInput>
                                        <a className='text-xt text-inputPlaceholderLight'>{phone?.length} de 20</a>
                                    </div>
                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                        <LabelInput text={'Endereço'}>
                                            <Input charLimit={100} value={address} onChange={(e) => setAddress(e.target.value)} />
                                        </LabelInput>
                                        <a className='text-xt text-inputPlaceholderLight'>{address?.length} de 100</a>
                                    </div>
                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                        <LabelInput text={'Site'}>
                                            <Input charLimit={100} value={link} onChange={(e) => setLink(e.target.value)} />
                                        </LabelInput>
                                        <a className='text-xt text-inputPlaceholderLight'>{link?.length} de 100</a>
                                    </div>
                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                        <LabelInput text={'E-mail'}>
                                            <Input charLimit={100} value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </LabelInput>
                                        <a className='text-xt text-inputPlaceholderLight'>{email?.length} de 100</a>
                                    </div>
                                </div>
                            </div>
                            <div id='agreementCreatePictures' className='w-full flex flex-col  items-start justify-start gap-4 user-select-none'>
                                <div className="flex flex-col gap-4">
                                    <div className='flex flex-col '>
                                        <h1 className='text-primaryDefaultLight text-base'>Foto</h1>
                                        <a className='text-xt text-inputPlaceholderLight'>{agreementAppendPhotos.length} de 3</a>
                                    </div>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                            <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                {
                                                    showPhoto > 0 ?
                                                        <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                        :
                                                        <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                }
                                                <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                    <div className='flex flex-row justify-end w-full'>
                                                        <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                    </div>
                                                    <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                    </div>
                                                </div>
                                                {
                                                    showPhoto < agreementAppendPhotos.length - 1 ?
                                                        <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                        :
                                                        <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>
                                        <h1 className='text-sm text-inputPlaceholderLight'>Foto</h1>
                                        {
                                            agreementAppendPhotos?.length < 3 &&
                                            <Tippy content={'Adicionar foto'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='right'
                                                delay={0}>
                                                <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                            </Tippy>
                                        }
                                    </div>

                                    {
                                        !loadingFile &&
                                        <input id='pictureAddInput' type='file' accept='.png,.jpeg,.jpg' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                    }
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            agreementAppendPhotos.map((photo, indexPhoto) => {
                                                return <div id={photo.photoObj + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={0}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>
                                                    <img onClick={() => showThisPhoto(photo.photoObj, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo.photoObj}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>

                }
            </Body>
            <Footer>
                {
                    !loading &&
                    <div div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelAgreementCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => history.back()}
                        >Voltar
                        </Button>

                        <Button
                            module={"agreements:create"}
                            userData={userData}
                            id='approvalAgreementsCreate'
                            approval={true}
                            shadow={true}
                            onClick={() => handleSubmit()}
                        >Cadastrar
                        </Button>

                    </div>
                }
            </Footer>
        </Container >
    )
}
