import React, { useEffect, useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import { Body } from "../../../../components/container/Body";
import { FaBan, FaCloudDownloadAlt, FaFilter, FaSearch } from "react-icons/fa";
import { Container } from "../../../../components/container/container";
import { Title } from "../../../../components/titlePages/title";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Button } from "../../../../components/buttons/button.default";
import { TableDefault } from "../../../../components/table/table.default";
import { TableMobile } from "../../../../components/table/table.mobile";
import { useAuth } from "../../../../contexts/useAuth";
import { formattTimeToShow } from "../../../../services/api/callAPIsFunctions/defaultCalls.api";
import { generateCsv } from "../../../../utils/excelFunctions/generateCsv";
import { LabelInput } from "../../../../components/label/label.input";
import { Input } from "../../../../components/input/input";
import { InputAutoComplete } from "../../../../components/input/input.autocomplete";
import { useNavigate } from "react-router-dom";
import { currencyPtBr } from "../../../../utils/formatCurrency/currencyTextPTBR";
import { formatPaymentMethods } from "../../../../utils/formatObjects/formatPaymentMethods";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function MySubscriptions() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [subscriptionsActiveButton, setSubscriptionsActiveButton] = useState(1)
    const [activitiesSubscriptions, setActivitiesSubscriptions] = useState([])

    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)
    const [quantity, setQuantity] = useState(0)

    useEffect(() => {
        getData()
    }, [])

    async function getData(e) {
        setLoading(true)

        const subscriptions = await defaultCallsSchedules.getMyActivitiesSubscriptions(userData[0].token, e?.filter, e?.page, e?.direction, subscriptionsActiveButton)


        setTotalPages(subscriptions?.data?.totalPages)
        setQuantity(subscriptions?.data?.quantity)
        setActivitiesSubscriptions(subscriptions?.data?.data?.length > 0 ? subscriptions?.data?.data.map(sub => {
            return {
                ...sub,
                payments_method: formatPaymentMethods(sub.paymentMethod),
                planValue: currencyPtBr(sub.planValue),
                created_at: formattTimeToShow(sub.created_at),
                startDate: formattTimeToShow(sub.startDate),
                finishDate: formattTimeToShow(sub.finishDate),
                original_active: sub.active,
                original_status: sub.status,
                active: sub.active ? <a className="text-green-600">Ativo</a>
                    : <a className="text-red-700">Inativo</a>,
                status: sub.status == 1 ? <p className="text-white rounded-md bg-green-700 w-fit m-auto px-2"> Pago </p>
                    : sub.status == 2 ? <p className="text-white rounded-md bg-gray-700 w-fit m-auto px-2"> Cancelado </p>
                        : sub.status == 3 ? <p className="text-white rounded-md bg-yellow-500 w-fit m-auto px-2"> Aguardando Pagamento </p>
                            : <p className="text-white rounded-md bg-yellow-500 w-fit m-auto px-2"> Pendente </p>
            }
        }) : [])

        setLoading(false)
    }

    const tableThead = [
        {
            "name": 'Atividade',
            "original_name": "activitiesName",
            "table": "activities"
        },
        {
            "name": 'Inicio',
            "original_name": 'startDate'
        },
        {
            "name": 'Fim',
            "original_name": 'finishDate'
        },
        {
            "name": 'Status',
            "original_name": 'status',
            "table": "payments"
        },
        {
            "name": 'Inscrição',
            "original_name": 'active',
        },
    ]

    const statusList = [
        { id: 0, description: 'Inativo' },
        { id: 1, description: 'Ativo' },
    ]

    const filters = [
        {
            "name": 'Atividade',
            "original_name": 'activitiesName',
            "type": 'text'
        },
        {
            "name": 'ID do Pagamento',
            "original_name": 'payments_id',
            "type": 'text'
        },
        {
            "name": 'Inicio',
            "original_name": 'startDate',
            "type": 'date'
        },
        {
            "name": 'Fim',
            "original_name": 'finishDate',
            "type": 'date'
        }
    ]

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {
        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }

        getData({ filter: { filterObject: ToFilterData }, direction: false, subscriptionsActiveButton })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    async function handleFilterActiveSubscriptions(status) {

        setLoading(true)
        status > 0 ? setSubscriptionsActiveButton(1) : setSubscriptionsActiveButton(0)

        const subscriptions = await getMyActivitiesSubscriptions(userData[0].token, status?.filter, status?.page, status?.direction, status > 0 ? 1 : 0)
        setTotalPages(subscriptions.data.totalPages)
        setQuantity(subscriptions.data.quantity)

        if (subscriptions.error) {
            setLoading(false)
            setShowNotication(subscriptions.data)
            return
        }


        setActivitiesSubscriptions(
            subscriptions?.data?.data?.length > 0 ? subscriptions?.data?.data.map(sub => {
                return {
                    ...sub,
                    created_at: formattTimeToShow(sub.created_at),
                    startDate: formattTimeToShow(sub.startDate),
                    finishDate: formattTimeToShow(sub.finishDate),
                    active: sub.active ? <a className="text-green-700">Ativo</a>
                        : <a className="text-red-700">Inativo</a>,
                    status: sub.status == 1 ? <p className="text-white rounded-md bg-green-700 w-fit m-auto px-2"> Pago </p>
                        : sub.status == 2 ? <p className="text-white rounded-md bg-gray-700 w-fit m-auto px-2"> Cancelado </p>
                            : sub.status == 3 ? <p className="text-white rounded-md bg-yellow-500 w-fit m-auto px-2"> Aguardando Pagamento </p>
                                : <p className="text-white rounded-md bg-yellow-500 w-fit m-auto px-2"> Pendente </p>
                }
            }) : []
        )
        setLoading(false)
    }


    function handleCsv() {

        const array = []
        activitiesSubscriptions.map(subscription => {
            array.push({
                'Atividade': subscription.activitiesName,
                'Inicio': subscription.startDate,
                'Fim': subscription?.finishDate,
                'Criado em': subscription?.created_at
            })
        })
        generateCsv(array);
    }

    //filter functions
    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }

    function handleEdit(e) {
        localStorage.setItem('@mySubscriptions', JSON.stringify(e))
        navigate(`/mySubscriptionsDetails/:${e?.id}`)
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Minhas inscrições'} />
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return column.original_name !== 'dateStart' && column.original_name !== 'endDate' &&
                                        <div className='flex flex-col sm:flex-row gap-4'>
                                            <div className='sm:w-28'>
                                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                            </div>
                                            {
                                                column.type === 'text' ?
                                                    <LabelInput>
                                                        <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }}></Input>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'object' ?
                                                        <LabelInput>
                                                            <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            }}></InputAutoComplete>
                                                        </LabelInput>
                                                        :
                                                        column.type === 'date' &&
                                                        <LabelInput>
                                                            <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            }} />
                                                        </LabelInput>
                                            }
                                        </div>
                                })}
                            <div className='flex w-full flex-col items-start sm:items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>
                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                <div className='flex flex-row w-full justify-start items-center gap-4'>
                    <div className='flex flex-row items-center justify-start'>
                        <Tippy content={'Inscriçoes ativas'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${subscriptionsActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveSubscriptions(1)}>Ativos</button>
                        </Tippy>
                        <Tippy content={'Inscriçoes inativas'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${subscriptionsActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveSubscriptions(0)}>Inativos</button>
                        </Tippy>
                    </div>
                    <Tippy content={'Filtrar'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div className={`p-1 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150 ${paginationHistFilteredFieldsArr?.length > 0 && 'bg-gray-300 rounded-lg'}`} onClick={() => setShowFilterModal(true)}>
                            <FaFilter />
                        </div>
                    </Tippy>
                    <Tippy content={'Exportar lista em excel'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => handleCsv()}>
                                    <FaCloudDownloadAlt />
                                    Exportar
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                {

                    <>
                        <div id='mySchedulesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                            <TableDefault
                                pagination={true}
                                totalPages={totalPages}
                                onClick={(e) => handleEdit(e)}
                                title={tableThead}
                                data={activitiesSubscriptions}
                                collumns={['activitiesName', "startDate", "finishDate", 'status', 'active']}
                                loading={loading}
                                filter={(e) => orderTableData(e)}
                            />
                        </div>
                        <div id='reservationMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                            <TableMobile
                                // onClick={(e) => e.func.execute(e.data)}
                                title={tableThead}
                                loading={loading}
                                pagination={true}
                                totalPages={totalPages}
                                filter={(e) => getData(e)}
                                data={activitiesSubscriptions}
                                collumns={['activitiesName', "startDate", "finishDate", 'status', 'active']}
                            />
                        </div>
                    </>

                }
            </Body>
        </Container>
    )
}