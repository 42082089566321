import { Container } from "../../../components/container/container";
import { Body } from '../../../components/container/Body';
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { useEffect, useState } from "react";
import { Button } from '../../../components/buttons/button.default';
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from "moment";
import { FaInfo, FaMinus, FaPlus } from "react-icons/fa";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Footer } from "../../../components/footer/Footer";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { justNumber } from "../../../utils/validators/justNumber";
import { Input } from "../../../components/input/input";
import { validateFields } from "../../../utils/form.validator";
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialRulesCreate(){

    const navigate = useNavigate()
    const { defaultCallsFinancial, api_financial } = useApiContext()
    const { id } = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const [ rule, setRule ] = useState({
        startDate: moment().utc(false).format('YYYY-MM-DD'),
        useAutoBillsByModules: null,
        useSendEmailForLateBills: null,
        quantityDaysAfterSendCharge: null
    })

    useEffect(() => {
        if (id) getData()
    }, [])

    async function getData(){
        try {
            setLoading(true)

            const getDataAPI = await defaultCallsFinancial.getFinancialRulesPerId(userData[0]?.token, id)
            if (getDataAPI.error){
                throw getDataAPI.error
            }

            setRule({
                startDate: getDataAPI?.data[0]?.startDate,
                useAutoBillsByModules: getDataAPI?.data[0]?.useAutoBillsByModules,
                useSendEmailForLateBills: getDataAPI?.data[0]?.useSendEmailForLateBills,
                quantityDaysAfterSendCharge: getDataAPI?.data[0]?.quantityDaysAfterSendCharge
            })

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        }finally{
            setLoading(false)
        }
    }

    async function handleSubmit(){

        const requiredFields = [
            { name: 'startDate', value: rule?.startDate, required: true, type: 'string' },
            { name: 'useAutoBillsByModules', value: [true, false].includes(rule?.useAutoBillsByModules), required: true, type: 'string' },
            { name: 'useSendEmailForLateBills', value: [true, false].includes(rule?.useSendEmailForLateBills), required: true, type: 'string' }
        ]

        if (rule?.useSendEmailForLateBills){
            requiredFields.push(
                { name: 'quantityDaysAfterSendCharge', value: rule?.quantityDaysAfterSendCharge, required: true, type: 'string' }
            )
        }

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)
        
        try {
            await api_financial.post(`/api/v1/financial/rules`, {
                startDate: rule.startDate,
                useAutoBillsByModules: rule.useAutoBillsByModules,
                useSendEmailForLateBills: rule.useSendEmailForLateBills,
                quantityDaysAfterSendCharge: rule.quantityDaysAfterSendCharge ?? undefined
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Regra ${id ? 'atualizada' : 'cadastrada'} com sucesso`)
            return navigate(-1)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    async function inactiveRule(){
        setLoading(true)
        setShowUniversalModal(false)
        try {
            await api_financial.put(`/api/v1/financial/rules/${id}`, {
                active: false
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Mensalidade inativada com sucesso")
            return navigate(-1)

        } catch (error) {
            const formatedError = responseError(error)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(formatedError || 'Erro inesperado')
        } finally {
            setLoading(false)
        }
    }

    const boolOptions = [
        { id: true, value: 'Sim' },
        { id: false, value: 'Não' },
    ]

    return (
        <>
            <ScreenModal title={'Inativar regra'}>
                <div className='p-4 flex flex-col text-sm items-center justify-center gap-2 w-[30rem]'>
                    <p>Deseja realmente inativar essa regra?</p>
                    <div className='flex gap-4 mt-3'>                            
                        <Button shadow={true} onClick={() => inactiveRule()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text='Criação de Regra'></Title>
                    </div>
                </TitlePage>
                <Body>
                {
                    loading ? 
                    <div className="w-full flex items-center justify-center"><DefaultLoader/></div>
                    :
                    <div className='flex flex-col gap-2 items-start justify-start w-full'>
                        <form className='flex flex-col items-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                            <LabelInput text={'Início'} required>
                                <Input 
                                    id='startDate' 
                                    type={'date'} 
                                    value={rule?.startDate} 
                                    onChange={(e) => setRule(prev => ({ ...prev, startDate: e.target.value }))}
                                />
                            </LabelInput>
                            <LabelInput text={'Usa controle entre modulos'} required> 
                                <InputAutoComplete
                                    data={boolOptions}
                                    id={'useAutoBillsByModules'}
                                    preSelectedValue={boolOptions.find(opt => opt.id == rule.useAutoBillsByModules)?.value}
                                    onChange={(e) => {
                                        setRule(prev => ({ ...prev, useAutoBillsByModules: e.id }))
                                    }}
                                    selectedLabel={'value'}
                                    optionList={['value']}
                                />
                            </LabelInput>
                            <LabelInput text={'Usa envio de e-mail para recebimentos atrasados'} required> 
                                <InputAutoComplete
                                    data={boolOptions}
                                    id={'useSendEmailForLateBills'}
                                    preSelectedValue={boolOptions.find(opt => opt.id == rule.useSendEmailForLateBills)?.value}
                                    onChange={(e) => {
                                        setRule(prev => ({ 
                                            ...prev, 
                                            useSendEmailForLateBills: e.id,
                                            quantityDaysAfterSendCharge: null
                                        }))
                                    }}
                                    selectedLabel={'value'}
                                    optionList={['value']}
                                />
                            </LabelInput>
                            {
                                rule.useSendEmailForLateBills &&
                                <LabelInput text={'Dias após o vencimento'} required>
                                    <Input 
                                        id='quantityDaysAfterSendCharge'
                                        value={rule.quantityDaysAfterSendCharge}
                                        charLimit={2}
                                        onChange={(e) => setRule(prev => ({ ...prev, quantityDaysAfterSendCharge: justNumber(e.target.value) }))}
                                    />
                                </LabelInput>
                            }
                        </form>
                    </div>
                }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                        <div className="flex flex-row items-center justify-center gap-2">
                            {
                                id &&
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(true)}>Inativar</Button>
                            }
                            <Button shadow={true} approval={true} onClick={() => handleSubmit()}>{id ? 'Editar' : 'Cadastrar'}</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )

}