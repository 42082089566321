import React, { useEffect, useState } from "react";
import { Container } from "../../../../components/container/container";
import { Title } from "../../../../components/titlePages/title";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { useAuth } from "../../../../contexts/useAuth";
import { formattTimeToShow } from "../../../../services/api/callAPIsFunctions/defaultCalls.api";
import { Body } from "../../../../components/container/Body";
import { FaAddressBook, FaAddressCard, FaEdgeLegacy, FaMap, FaPhone } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { v4 as uuid } from 'uuid'
import { Button } from "../../../../components/buttons/button.default";
import { DefaultLoader } from "../../../../components/loaders/defaultLoader";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function EventsClient() {

    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [loadingPictures, setLoadingPictures] = useState(true)
    const [eventsList, setEventsList] = useState([])
    const [eventsListLoading, setEventsListLoading] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [page, setActivePage] = useState(1)

    async function getData() {

        const eventsData = await defaultCallsSchedules.getEvents(userData[0].token)
        setTotalPages(eventsData.data.totalPages)
        setEventsList(eventsData.data.data)
        setLoading(false)

        setEventsListLoading(Array(eventsData.data.data.length).fill(0))
        await Promise.all(eventsData.data.data.map(async event => {
            if (event.picture) {
                const picture = await defaultCallsSchedules.getEventsPicture(userData[0].token, event.id, event.picture)
                event.picture = URL.createObjectURL(picture.data)
            }
        }))
        setEventsList(eventsData.data.data)
        setLoadingPictures(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function getMorePage(actualPage) {

        setLoading(true)
        setLoadingPictures(true)
        const eventsData = await defaultCallsSchedules.getEvents(userData[0].token, '', actualPage, '', '')
        setEventsList([...eventsList.concat(eventsData.data.data)])
        setActivePage(actualPage)
        setLoading(false)
        await Promise.all(eventsData.data.data.map(async event => {
            if (event.picture) {
                const picture = await defaultCallsSchedules.getEventsPicture(userData[0].token, event.id, event.picture)
                event.picture = URL.createObjectURL(picture.data)
            }
        }))
        setLoadingPictures(false)
    }

    9,8,10

    return (
        <Container>
            <TitlePage>
                <Title text={'Eventos'} />
            </TitlePage>
            <Body>
                <div className="py-12 w-full flex flex-col gap-8 flex-wrap rounded-lg justify-center items-center">
                    <h1 className="text-lg text-primaryDefaultLight">Eventos e notícias</h1>
                    {
                        eventsList?.length === 0 && !loading &&
                        <h1 className="text-sm text-titleBlackTextLight">Nenhum evento cadastrado ainda, volte mais tarde</h1>
                    }
                    <div className="w-full flex flex-row gap-8 flex-wrap rounded-lg justify-center">
                        {
                            loading ?
                                eventsListLoading.map(load => {
                                    return <div className="shadow-lg relative flex flex-col bg-gray-100 rounded-lg gap-2 w-[600px] sm:max-h-96 border border-gray-100 ">
                                        <div className="w-full flex flex-col items-center sm:items-start sm:flex-row gap-4 p-4">
                                            <div className='bg-gray-300 animate pulse rounded-md flex flex-col w-48 h-48 bg-no-repeat bg-center bg-cover'>
                                            </div>
                                            <div className="flex flex-col gap-2 sm:max-w-[352px] sm:h-72 sm:max-h-96 overflow-x-hidden">
                                                <span className="text-base w-full h-6 animate-pulse bg-gray-300 text-primaryDefaultLight font-extrabold break-words"></span>
                                                <p wrap="hard" className="animate-pulse text-sm text-titleBlackTextLight h-10 w-80" disabled ></p>
                                                <span className="text-base w-full h-6 animate-pulse bg-gray-300 text-primaryDefaultLight font-extrabold break-words"></span>
                                                <span className="text-base w-full h-6 animate-pulse bg-gray-300 text-primaryDefaultLight font-extrabold break-words"></span>
                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                eventsList.map((event, index) => {
                                    return <div key={uuid()} id={uuid()} className="shadow-lg relative flex flex-col bg-gray-100 dark:bg-thirdDefaultDark rounded-lg gap-2 w-[600px] sm:max-h-96 border border-gray-100 dark:border-primaryBorderDark">
                                        <div className="w-full flex flex-col items-center sm:items-start sm:flex-row gap-4 p-4">
                                            {
                                                loadingPictures ?
                                                    <div className='rounded-md flex flex-col w-48 h-48 bg-no-repeat bg-center bg-cover'>
                                                        <span className="w-full roundd-md h-48 bg-gray-300 animate-pulse">

                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="gap-2 flex flex-col items-center justify-start">
                                                        <div style={{
                                                            backgroundImage: `url(${event.picture})`,
                                                        }} className='rounded-md flex flex-col w-48 h-48 bg-no-repeat bg-contain bg-center border border-zinc-200 shadow-lg bg-zinc-50 '>
                                                        </div>
                                                        <div className="p-2 rounded-lg bg-primaryDefaultLight">
                                                            <a className="text-sm text-white dark:text-titleGrayTextDark">Ocorre em: {formattTimeToShow(event?.starts_in)?.replace('00:00',' ')}</a>
                                                        </div>
                                                    </div>

                                            }

                                            <div className="flex flex-col gap-2 sm:max-w-[352px] sm:h-72 sm:max-h-96 overflow-x-hidden">
                                                <h1 className="text-base text-primaryDefaultLight font-extrabold break-words">{event.title}</h1>
                                                <p wrap="hard" className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark h-48 w-80" disabled >{event.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                    </div>
                    {
                        page < totalPages &&
                        <Button shadow={true} onClick={() => getMorePage(page + 1)}>Mais...</Button>
                    }
                </div>

            </Body>
        </Container>
    )
}