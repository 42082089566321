//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaPlus, FaQuestionCircle } from 'react-icons/fa'
//components
import { FaEdit } from "react-icons/fa";
import { Container } from "../../../components/container/container";
import { Toogle } from '../../../components/toogle/Toogle'
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Quillmodules } from "../../../components/richText/quill.modules";
import { v4 as uuid } from 'uuid'
import { Button } from "../../../components/buttons/button.default";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import { Footer } from "../../../components/footer/Footer";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { validateFields } from "../../../utils/form.validator";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { formattTimeToShow } from "../../../services/api/callAPIsFunctions/defaultCalls.api"
//utils
import { permittedFiles } from "../../../utils/permittedFiles/permittedFiles";
import { UrlToImage } from "../../../utils/formatImages/urlToImgFile";
import moment from "moment";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { verifyFileSize } from "../../../utils/validators/verifyFileSize";
import { resizeImageTest } from "../../../utils/formatImages/resizeImageTest";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function GastronomyEdit() {

    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const gastronomyId = useParams()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [showCreateHyperLink, setShowCreateHyperLink] = useState(false)

    //gastronomy
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [hyperLink, setHyperLink] = useState('')
    const [hyperLinkText, setHyperLinkText] = useState('')
    const [gastronomyActive, setGastronomyActive] = useState(false)
    const [gastronomyActiveHistory, setGastronomyActiveHistory] = useState(false)
    //values
    const [showValuesCreate, setShowValuesCreate] = useState(false)
    const [valueTitle, setValueTitle] = useState('')
    const [valueDescription, setValueDescription] = useState('')
    const [valueDeltaDescription, setValueDeltaDescription] = useState({})
    const [valueMenu, setValueMenu] = useState('')
    const [valueMenuDelta, setValueMenuDelta] = useState({})
    const [gastronomyValuesData, setGastronomyValuesData] = useState([])
    const [gastronomyNewValuesData, setGastronomyNewValuesData] = useState([])
    const [editValue, setEditValue] = useState({ edit: false, id: null })
    const [gastronomyValueList, setGastronomyValueList] = useState([])
    //products
    const [gastronomyNewProductTitle, setGastronomyNewProductTitle] = useState('')
    const [gastronomyNewProductPrice, setGastronomyNewProductPrice] = useState('')
    const [showProductsCreate, setShowProductsCreate] = useState(false)
    const [editProducts, setEditProducts] = useState({ edit: false, id: null })
    const [gastronomyProductList, setGastronomyProductList] = useState([])
    //photos
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [gastronomysPictures, setGastronomysPictures] = useState([])
    const [gastronomysAppendPictures, setGastronomysAppendPictures] = useState([])
    const [gastronomysAppendTDeletePictures, setGastronomysAppendTDeletePictures] = useState([])
    const tableTheadProducts = [
        {
            "name": 'Produto',
            "original_name": 'name'
        },
        {
            "name": 'Preço',
            "original_name": 'price'
        }
    ]

    const tableTheadValues = [
        {
            "name": 'Nome',
            "original_name": 'title'
        }
    ]

    async function formatAPIPhoto(apiData) {
        if (apiData.gastronomyPictures?.length > 0) {
            let photosArray = []
            let photosAppendArray = []
            await Promise.all(apiData.gastronomyPictures.map(async (picture) => {
                const file = await api.get(`api/v1/gastronomyPictures/gastronomy_id/${gastronomyId.id}/picture/${picture.picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(picture.picture)
                photosAppendArray.push({ photosAppendArray: fileObj, isMaster: picture.isMaster, photosArray: picture.picture, file: { name: picture.originalName } })
            }))
            setGastronomysPictures(photosArray)
            setGastronomysAppendPictures(photosAppendArray)
        }
    }

    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        if (gastronomysPictures.length < 5) {

            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(e, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })
            const photoObj = URL.createObjectURL(formattedImg)
            setGastronomysAppendPictures([{ photosAppendArray: photoObj, file: formattedImg, isMaster: gastronomysAppendPictures.filter(photo => photo?.isMaster)?.length === 0 ? true : false, isMasterName: e.target.files[0].name }, ...gastronomysAppendPictures])
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = gastronomysAppendPictures[showPhoto - 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = gastronomysAppendPictures[showPhoto + 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)
        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = gastronomysAppendPictures[indexPhoto].photosAppendArray
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)

        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        if (photo.isMaster) {
            let photos = []
            gastronomysAppendPictures.filter(photoToRemove => photoToRemove.file.name !== photo.file.name).map((newPhotos, index) => {
                if (index === 0) {
                    photos.push({
                        ...newPhotos,
                        isMaster: true,
                        isMasterName: newPhotos.file.name
                    })
                } else {
                    photos.push({
                        ...newPhotos,
                        isMaster: false
                    })
                }
            })
            setGastronomysAppendPictures(photos)
        } else {
            setGastronomysAppendPictures(gastronomysAppendPictures.filter(photoToRemove => photoToRemove.file.name !== photo.file.name))
        }

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(1)'
        setGastronomysAppendTDeletePictures([...gastronomysAppendTDeletePictures, photo.photosArray])

    }

    async function getData() {

        setLoading(true)
        const gastronomyData = await defaultCallsSchedules.getGastronomyById(userData[0].token, gastronomyId.id)
        let formattedGastronomyValues = []
        let formattedGastronomyProducts = []

        try {
            if (gastronomyData.data?.values) {
                gastronomyData.data.values.map(value => formattedGastronomyValues.push({ id: uuid(), ...value }))

                setGastronomyValueList(formattedGastronomyValues)
            }
            if (gastronomyData.data?.products) {
                gastronomyData.data.products.map(product => formattedGastronomyProducts.push({ id: uuid(), productName: product.productName, productPrice: product.productValue, priceFormatted: product?.productValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), }))
                setGastronomyProductList(formattedGastronomyProducts)
            }

            setHyperLinkText(gastronomyData.data?.hyperLinkDescription)
            setHyperLink(gastronomyData.data?.hyperLink)

        } catch (error) {
            console.log(error)
            setGastronomyValuesData(gastronomyData?.data?.values)
            setGastronomyProductList(gastronomyData?.data?.products)
            setTitle(gastronomyData.data?.title)
            setDescription(gastronomyData.data?.description)
            setGastronomyActive(gastronomyData?.data?.active)
            setGastronomyActiveHistory(structuredClone(gastronomyData?.data.active))
            setHyperLinkText(gastronomyActive.data?.hyperLinkDescription)
            setHyperLink(gastronomyActive.data?.hyperLink)
            setLoading(false)
        }
        setTitle(gastronomyData.data?.title)
        setDescription(gastronomyData.data?.description)
        setGastronomyActive(gastronomyData?.data?.active)
        setGastronomyActiveHistory(structuredClone(gastronomyData?.data?.active))
        await formatAPIPhoto(gastronomyData.data)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {

        const fields = [
            { "name": 'title', "value": title, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
        ]

        if (validateFields(fields)?.length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        let formattedProducts = []
        gastronomyProductList.map(product => formattedProducts.push({ productName: product.productName, productValue: product.productPrice }))

        let formattedValues = []
        gastronomyValueList.map(value => formattedValues.push({ title: value.title, description: value.description, menu: value.menu }))
        try {
            setLoading(true)
            await api.put(`/api/v1/gastronomy/gastronomy_id/${gastronomyId.id}`, {
                title,
                description,
                hyperLinkDescription: hyperLinkText?.length > 0 ? hyperLinkText : '',
                hyperLink: hyperLink?.length > 0 ? hyperLink : '',
                products: formattedProducts?.length > 0 ? formattedProducts : undefined,
                values: formattedValues?.length > 0 ? formattedValues : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (gastronomysAppendPictures.length > 0) {
                const formData = new FormData();
                await Promise.all(gastronomysAppendPictures.map(async (photo, index) => {
                    const file = await UrlToImage(photo.photosAppendArray, photo.file.name)
                    formData.append('images', file);
                    if (photo.isMaster) {
                        formData.append('isMasterName', photo?.file.name)
                    }
                }))

                await api.post(`/api/v1/gastronomyPictures/gastronomy_id/${gastronomyId.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            } else if (gastronomysAppendPictures.length === 0) {
                await Promise.all(gastronomysAppendTDeletePictures.map(async (photo, index) => {
                    await api.delete(`/api/v1/gastronomyPictures/gastronomy_id/${gastronomyId.id}/picture/${photo}`, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`
                        }
                    })
                }))
            }

            if (gastronomyActive !== gastronomyActiveHistory) {
                await api.put(`/api/v1/gastronomy/gastronomy_id/${gastronomyId.id}/active/${gastronomyActive ? 1 : 0}`, {

                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            navigate('/gastronomy')
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleChangeValueText(content, delta, source, editor) {
        setValueDescription(content)
        setValueDeltaDescription(editor.getContents());
    }

    function handleChangeMenuText(content, delta, source, editor) {
        setValueMenu(content)
        setValueMenuDelta(editor.getContents());
    }

    function handleNewValue() {
        if (valueTitle === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Preencha o título para esta tabela de valores')
        }

        if (!editValue.edit) {
            setGastronomyValueList(prev => ([...prev, {
                id: uuid(),
                title: valueTitle,
                description: valueDeltaDescription,
                menu: valueMenuDelta,
                created_at: moment().format('DD/MM/YYYY HH:MM')
            }]))
        } else {
            let formattedValues = gastronomyValueList.filter(value => value.id !== editValue.id)

            gastronomyValueList.map(value => {
                if (value.id === editValue.id) {
                    formattedValues.push(
                        value = {
                            id: value.id,
                            title: valueTitle,
                            description: valueDeltaDescription,
                            menu: valueMenuDelta,
                            created_at: value?.created_at
                        })
                }
            })
            setGastronomyValueList(formattedValues)
        }
        setValueTitle('')
        setValueDescription('')
        setValueDeltaDescription('')
        setValueMenu('')
        setValueMenuDelta('')
        setEditValue({ edit: false, id: null })
        setShowValuesCreate(false)
    }

    function handleNewProduct() {

        if (gastronomyNewProductTitle === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Prencha o titulo do produto')
        }
        if (gastronomyNewProductPrice === '') {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Prencha o valor do produto')
        }
        setShowNotificationModal(false)
        if (!editProducts.edit) {
            setGastronomyProductList(prev => ([...prev, {
                id: uuid(),
                productName: gastronomyNewProductTitle,
                new: true,
                productPrice: gastronomyNewProductPrice,
                priceFormatted: gastronomyNewProductPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            }]))
        } else {
            let formattedProducts = gastronomyProductList.filter(product => product.id !== editProducts.id)
            gastronomyProductList.map(product => {
                if (product.id === editProducts.id) {
                    formattedProducts.push(
                        product = {
                            id: product.id,
                            productName: gastronomyNewProductTitle,
                            edit: true,
                            new: false,
                            productPrice: gastronomyNewProductPrice,
                            priceFormatted: gastronomyNewProductPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                        })
                }
            })
            setGastronomyProductList(formattedProducts)
        }
        setGastronomyNewProductTitle('')
        setGastronomyNewProductPrice('')
        setShowProductsCreate(false)
        setEditProducts({ edit: false, id: null })
    }

    function handleEditValue(e) {
        setEditValue({ edit: true, id: e.id, value: e })
        setValueTitle(e.title)
        setValueDeltaDescription(e.description)
        setValueDescription(e.description)
        setValueMenu(e.menu)
        setValueMenuDelta(e.menu)
        setShowValuesCreate(true)
    }

    function handleEditProduct(e) {
        setEditProducts({ edit: true, id: e.id, product: e })
        setGastronomyNewProductTitle(e.productName)
        setGastronomyNewProductPrice(e.productPrice)
        setShowProductsCreate(true)
    }

    const handleRemoveValue = (e) => {

        setGastronomyValueList(gastronomyValueList.filter(value => value.id !== editValue.id))
        setValueTitle('')
        setValueDescription('')
        setValueDeltaDescription('')
        setValueMenu('')
        setValueMenuDelta('')
        setEditValue({ edit: false, id: null })
        setShowValuesCreate(false)
    }

    const handleRemoveProduct = (e) => {
        let formattedProducts = gastronomyProductList.filter(product => product.id !== editProducts.id)
        setEditProducts({ edit: false, id: null })
        setGastronomyNewProductPrice('')
        setGastronomyNewProductTitle('')
        setGastronomyProductList(formattedProducts)
        setShowProductsCreate(false)
    }

    useEffect(() => {
        let formattedValuesData = []

        gastronomyNewValuesData.map(value => formattedValuesData.push(value))
        gastronomyValuesData.map(value => formattedValuesData.push(value))

        setGastronomyValueList(formattedValuesData)
    }, [gastronomyNewValuesData, gastronomyValuesData])

    function handleMasterPhoto(photo) {

        let newFoto = []

        gastronomysAppendPictures.map(gastronomy => {
            if (gastronomy.file.name !== photo) {
                newFoto.push(
                    {
                        ...gastronomy,
                        isMaster: false
                    }
                )
            } else {
                newFoto.push(
                    {
                        ...gastronomy,
                        isMaster: true,
                        isMasterName: gastronomy.file.name
                    }
                )
            }
        })
        setGastronomysAppendPictures(newFoto)
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Editar'} />
            </TitlePage>
            <Body hasFooter={true}>
                <div id='gastronomnyCreate' className="w-full flex flex-col items-start justify-start gap-4">
                    {
                        loading ?
                            <div className="w-full flex flex-col h-full sm:h-96 items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className="w-full p-2 gap-4">
                                    <div className="flex flex-col sm:flex-row gap-4 items-start justify-start">
                                        <div className="flex flex-col items-start justify-start">
                                            <LabelInput text={'Título'}>
                                                <Input charLimit={50} id='title' type={'text'} value={title} onChange={(e) => setTitle(e.target.value)} />
                                            </LabelInput>
                                            <p className="text-xs mt-1 text-inputPlaceholderLight dark:text-titleGrayTextDark mb-2">{title?.length} de 50</p>
                                        </div>
                                        <LabelInput text={'ativo'}>
                                            <Toogle status={gastronomyActive} onClick={() => setGastronomyActive(!gastronomyActive)} />
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'Descrição'}>
                                        <textarea maxLength={1000} id='description' value={description} onChange={(e) => setDescription(e.target.value)} wrap="hard" className=" p-2 border border-gray-400 border-opacity-75 
                            h-64
                            w-full
                            text-sm
                            outline-none transition-all duration-200 focus:shadow-borderShadow
                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark" ></textarea>
                                    </LabelInput>
                                    <p className="text-xs mt-1 text-inputPlaceholderLight dark:text-titleGrayTextDark mb-2">{description?.length} de 10000</p>
                                    {
                                        showCreateHyperLink ?
                                            <div className="flex flex-col gap-4 mt-5">
                                                <div className="flex flex-col">
                                                    <LabelInput text={'Texto descritivo'}>
                                                        <Input charLimit={20} value={hyperLinkText} onChange={(e) => setHyperLinkText(e.target.value)}></Input>
                                                    </LabelInput>
                                                    <p className="text-xs mt-1 text-inputPlaceholderLight dark:text-titleGrayTextDark mb-2">{hyperLinkText?.length} de 20</p>
                                                </div>
                                                <LabelInput text={'Link'}>
                                                    <Input value={hyperLink} onChange={(e) => setHyperLink(e.target.value)}></Input>
                                                </LabelInput>
                                                <div className="flex flex-row gap-2">
                                                    <Button module={"gastronomy:update"} userData={userData} shadow={true} onClick={() => setShowCreateHyperLink(false)} >Salvar</Button>
                                                    <Button approval={false} shadow={true} onClick={() => { setShowCreateHyperLink(false) }} >Cancelar</Button>
                                                    {
                                                        hyperLinkText !== '' && hyperLink !== '' &&
                                                        <Button approval={false} shadow={true} onClick={() => { setHyperLink(''); setHyperLinkText(''); setShowCreateHyperLink(false) }} >Remover</Button>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className=" w-full gap-4 flex flex-col items-start justify-start mt-5">
                                                <div className='flex flex-col w-full mb-4'>
                                                    <div className="flex flex-row gap-2">
                                                        <h1 className='text-primaryDefaultLight text-base'>Link</h1>
                                                        <Tippy content={'Links para contatos ou pagina especifica'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='right'
                                                            delay={300}>
                                                            <div>
                                                                <FaQuestionCircle />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                    {
                                                        hyperLinkText !== '' && hyperLink !== '' &&
                                                        <div className="flex flex-col">
                                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{hyperLinkText}</p>
                                                            <a href={`https://${hyperLink}`} target="__blank" className="underline text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{hyperLink}</a>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    hyperLinkText === '' && hyperLink === '' ?
                                                        <Button shadow={true} onClick={() => setShowCreateHyperLink(true)} >Novo</Button>
                                                        :
                                                        <Button shadow={true} onClick={() => setShowCreateHyperLink(true)} >Editar</Button>
                                                }
                                            </div>
                                    }
                                </div>
                                {
                                    showValuesCreate ?
                                        <div className="w-full p-2 gap-4 flex flex-col items-start justify-start">
                                            <div className='flex flex-col w-full mb-4'>
                                                <h1 className='text-primaryDefaultLight text-base'>Valores</h1>
                                            </div>
                                            <LabelInput text={'Título'}>
                                                <Input type={'text'} value={valueTitle} onChange={(e) => setValueTitle(e.target.value)} />
                                            </LabelInput>
                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={valueDescription} onChange={handleChangeValueText} />
                                            <h2>Cardápio</h2>
                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={valueMenu} onChange={handleChangeMenuText} />
                                            <div className="flex flex-row w-full items-start justify-start gap-2">
                                                <Button shadow={true} onClick={handleNewValue} >Salvar</Button>
                                                <Button shadow={true} approval={false} onClick={() => { setShowNotificationModal(false); setEditValue({ edit: false, id: null }); setShowValuesCreate(false) }}>Cancelar</Button>
                                                {
                                                    editValue?.edit &&
                                                    <Button shadow={true} approval={false} onClick={handleRemoveValue}>Remover</Button>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="w-full p-2 gap-4 flex flex-col items-start justify-start">
                                            <div className='flex flex-row w-full mb-4 items-center justify-center gap-2'>
                                                <h1 className='text-primaryDefaultLight text-base'>Valores</h1>
                                                <div className="flex flex-row items-start justify-start w-full">
                                                    <Tippy content={<p>Novo valor</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div onClick={() => {setShowValuesCreate(true); setValueTitle(''); setValueDescription(''); setValueMenu(''); }} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                            <div id='gastronomyValuesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                <TableDefault data={gastronomyValueList} title={tableTheadValues} collumns={["title"]} onClick={(e) => handleEditValue(e)} />
                                            </div>
                                            <div id='gastronomyValuesTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                                <TableMobile
                                                    onClick={(e) => e.func.execute(e.data)}
                                                    title={tableTheadValues}
                                                    data={gastronomyValueList}
                                                    collumns={["valueTitle", "created_at"]}
                                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditValue }]}
                                                />
                                            </div>
                                        </div>
                                }
                                {
                                    showProductsCreate ?
                                        <div className="w-full p-2 gap-4 flex flex-col items-start justify-start">
                                            <h2>Produtos</h2>
                                            <LabelInput text={'Produto'}>
                                                <Input type={'text'} value={gastronomyNewProductTitle} onChange={(e) => setGastronomyNewProductTitle(e.target.value)} />
                                            </LabelInput>
                                            <LabelInput text={'Preço'}>
                                                <CurrencyInput placeHolder={gastronomyNewProductPrice > 0 ? `R$ ${gastronomyNewProductPrice?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                    setGastronomyNewProductPrice(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                }}></CurrencyInput>
                                            </LabelInput>
                                            <div className="flex flex-row w-full items-start justify-start gap-2">
                                                <Button shadow={true} onClick={handleNewProduct} >Salvar</Button>
                                                <Button shadow={true} approval={false} onClick={() => { setGastronomyNewProductPrice(''); setGastronomyNewProductTitle(''); setShowProductsCreate(false) }}>Cancelar</Button>
                                                {
                                                    editProducts?.edit &&
                                                    <Button shadow={true} approval={false} onClick={handleRemoveProduct}>Remover</Button>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="w-full p-2 gap-4 flex flex-col items-start justify-start">
                                            <div className="flex flex-row w-full justify-between items-start">
                                                <div className='flex flex-row gap-4 items-center justify-center w-full mb-4'>
                                                    <h1 className='text-primaryDefaultLight text-base'>Produtos</h1>
                                                    <div className="flex flex-row items-start justify-start w-full">
                                                        <Tippy content={<p>Novo produto</p>}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}>
                                                            <div onClick={() => setShowProductsCreate(true)} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                                <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='gastronomyProductsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                <TableDefault onClick={(e) => handleEditProduct(e)} data={gastronomyProductList} title={tableTheadProducts} collumns={["productName", "priceFormatted"]} />
                                            </div>
                                            <div id='gastronomyProductsTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                                <TableMobile
                                                    onClick={(e) => e.func.execute(e.data)}
                                                    title={tableTheadProducts}
                                                    data={gastronomyProductList}
                                                    collumns={["productName", "priceFormatted"]}
                                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditProduct }]}
                                                />
                                            </div>
                                        </div>
                                }
                                <div id='gastronomyCreatePictures' className='w-full flex flex-col items-start justify-start gap-4 user-select-none'>
                                    <div className='flex flex-col w-full'>
                                        <h1 className='text-primaryDefaultLight text-base'>Galeria de fotos</h1>
                                    </div>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-[101]`}>
                                            <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                {
                                                    showPhoto > 0 ?
                                                        <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                        :
                                                        <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                }
                                                <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-[95%] md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                    <div className='flex flex-row justify-end w-full'>
                                                        <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                    </div>
                                                    <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                    </div>
                                                </div>
                                                {
                                                    showPhoto < gastronomysPictures.length - 1 ?
                                                        <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                        :
                                                        <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-[100] left-0 top-0`}></div>
                                        <h1 className='text-sm text-inputPlaceholderLight'>Fotos</h1>
                                        <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>{gastronomysPictures.length} de 5</a>
                                        <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                    </div>
                                    <input id='pictureAddInput' type='file' accept=".png, .jpeg, .jpg" className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            gastronomysAppendPictures.map((photo, indexPhoto) => {
                                                return <div id={photo.photosAppendArray + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={300}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>

                                                    <img onClick={() => showThisPhoto(photo.photosAppendArray, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={gastronomysAppendPictures[indexPhoto].photosAppendArray}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className='flex flex-col gap-4'>
                                            <h1 className='text-primaryDefaultLight text-base'>Foto de capa</h1>
                                            <select onChange={(e) => handleMasterPhoto(e.target.value)}>
                                                {
                                                    gastronomysAppendPictures.map(gastronomy => {
                                                        return <option selected={gastronomy?.isMaster ? true : false} className="text-sm text-inputPlaceholderLight">{gastronomy?.file.name}</option>
                                                    })
                                                }
                                            </select>
                                            <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>Foto exibida em destaque</a>
                                        </div>
                                        <div className='flex flex-row gap-2 items-center justify-start'>
                                            {
                                                gastronomysAppendPictures.map((photo, indexPhoto) => {
                                                    if (photo?.isMaster) {
                                                        return <div id={photo.photosAppendArray + '-isMaster' + indexPhoto} className='transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                            <Tippy content={'Remover foto'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='right'
                                                                delay={300}>

                                                            </Tippy>

                                                            <img className='border border-gray-500 rounded-xl w-20 h-20' src={photo.photosAppendArray}></img>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </Body>
            {
                !loading &&
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelGastronomyCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => { history.back(); }}
                        >Voltar
                        </Button>
                        {
                            !showProductsCreate && !showValuesCreate &&
                            <Button
                                module={'gastronomy:update'}
                                userData={userData}
                                id='approvalGastronomyCreate'
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Salvar
                            </Button>
                        }
                    </div>
                </Footer>
            }
        </Container>
    )
}