import {
    FaChartPie,
    FaCalendarCheck,
    FaRunning,
    FaCalendarAlt,
    FaCog,
    FaHandsHelping,
    FaUserSlash,
    FaFeather,
    FaBook
} from 'react-icons/fa'

import { MdAccountBalanceWallet } from 'react-icons/md'

export const menuItems = [

    {
        "icon": <FaChartPie />,
        "name": "Dashboard",
        "type": "normal",
        "path": "/",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['dashboard:read'],
        "helpVideo": "https://youtube.com"
    },

    {
        "icon": <FaCalendarCheck />,
        "module": 1,
        "name": "Reservas",
        "type": "normal",
        "path": "/reservation",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['schedules:read','schedules:update','schedules:delete','schedules:create']
    },
    {
        "icon": <FaRunning />,
        "module": 2,
        "name": "Incrições",
        "type": "normal",
        "path": "/activitiesActions",
        "permissions": [1, 2, 3],
        "permissionsGroup":  ['activitiesSubscription:read','activitiesSubscription:update','activitiesSubscription:delete','activitiesSubscription:create']
    },
    {
        "icon": <FaCalendarAlt />,
        "name": "Eventos",
        "type": "normal",
        "path": "/eventsShow",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['events:read','events:update','events:delete','events:create']
    },
    {
        "icon": <FaHandsHelping />,
        "name": "Convênios",
        "type": "normal",
        "path": "/agreementsShow",
        "permissions": [1, 2, 3, 4],
        "permissionsGroup": ['agreements:read','agreements:update','agreements:delete','agreements:create']
    },
    {
        "icon": <FaUserSlash />,
        "name": "Lista de bloqueios",
        "type": "normal",
        "path": "/usersBlocked",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['blockedUsers:read','blockedUsers:update','blockedUsers:delete','blockedUsers:create']
    },
    {
        "icon": <FaFeather />,
        "name": "Sorteios",
        "type": "normal",
        "path": "/schedulesDraw",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['schedulesDraw:read','schedulesDraw:update','schedulesDraw:delete','schedulesDraw:create']
    },
    {
        "icon": <MdAccountBalanceWallet />,
        "name": "Saldo cliente",
        "type": "normal",
        "path": "/usersBalance",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['balance:read','balance:update','balance:delete','balance:create']
    },
    {
        "icon": <FaBook />,
        "name": "Relatórios",
        "type": "normal",
        "path": "/reports",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['reports:read','reports:update','reports:delete','reports:create']
    },
    {
        "icon": <FaCog />,
        "name": "Ajustes",
        "type": "normal",
        "path": "/configuration",
        "permissions": [1, 2, 3],
        "permissionsGroup": ['']
    },
]