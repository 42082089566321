import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export const Button = ({ children, height, width, shadow, onClick, action, approval, id, module, userData, type, cursorNotAllowed, disabled = false }) => {
    const widthPixel = width > 0 ? `w-[${width}px]` : 'px-4 2xl:px-5'
    const widthDefault = width > 0 ? `w-${width}` : 'px-4 2xl:px-5'

    const buttonID = id !== undefined ? id : uuidv4()
    const heightDefault = height > 0 ? `h-${height}` : 'py-1'

    if (module) {
        if (userData[0]?.permissions?.includes(module) || userData[0]?.typeAccess_id < 3) {
            return (
                <button type={type} id={buttonID} onClick={() => onClick !== undefined && onClick(action)}
                    disabled={disabled}
                    className={`text-sm sm:text-sm rounded-lg sm:rounded-lg 
                ${approval === false ? 'bg-red-800 hover:bg-red-900' : 'bg-primaryDefaultLight hover:bg-secondaryDefaultLight'}   
                ${disabled ? 'disabled:opacity-60' : ''}
                transition-all duration-300 text-titleGrayTextLight ${cursorNotAllowed ? 'cursor-not-allowed' : ''} 
                ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''} 
                ${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightDefault : heightDefault}`}>
                    {children}
                </button>
            )
        }
    }
    else {
        return (
            <button type={type} id={buttonID} onClick={() => onClick !== undefined && onClick(action)}
                disabled={disabled}
                className={`text-sm sm:text-sm rounded-lg sm:rounded-lg 
            ${approval === false ? 'bg-red-800 hover:bg-red-900' : 'bg-primaryDefaultLight hover:bg-secondaryDefaultLight'}   
            ${disabled ? 'disabled:opacity-60' : ''}
            transition-all duration-300 text-titleGrayTextLight ${cursorNotAllowed ? 'cursor-not-allowed' : ''} 
            ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''} 
            ${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightDefault : heightDefault}`}>
                {children}
            </button>
        )
    }
}
