import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Body } from "../../../components/container/Body";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { FaCheck, FaExclamationTriangle, FaExternalLinkAlt } from "react-icons/fa";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { Footer } from "../../../components/footer/Footer";
import { Button } from "../../../components/buttons/button.default";
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { InputCheckBox } from "../../../components/input/input.checkBox";
import { FiAlertTriangle } from "react-icons/fi";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function FinancialCashFlowEdit() {

    const { id } = useParams()
    const { userData } = useAuth()
    const { api_financial, defaultCallsFinancial } = useApiContext()
    const navigate = useNavigate()
    const {setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal} = useThemeContext() 
    const [loading, setLoading] = useState(true)
    const [cashFlow, setCashFlow] = useState({})
    const [cashFlowHistory, setCashFlowHistory] = useState({})
    const [bill, setBill] = useState({})
    const [paymentMethodList, setPaymentMethodList] = useState([])
    const [banks, setBanks] = useState([])
    const [usersAndClientsList, setUsersAndClientsList] = useState([])
    const [suppliersList, setSuppliersList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [deleteFutureSends, setDeleteFutureSends] = useState(false)
    const [modalReopen, setModalReopen] = useState(false)

    useEffect(() => {        
        getData()
    }, [])

    async function getData(){
            
        const getCashFlowAPI = await defaultCallsFinancial.getFinancialCashFlowPerId(userData[0].token, id)
        if (getCashFlowAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getCashFlowAPI.data)
        }        
        setCashFlow(getCashFlowAPI?.data[0])
        setCashFlowHistory(structuredClone(getCashFlowAPI?.data[0]))
        setBill(getCashFlowAPI?.data[0]?.bills)

        const paymentMethodAPI = await defaultCallsFinancial.getFinancialPaymentMethod(userData[0]?.token)
        if (paymentMethodAPI.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(paymentMethodAPI.data)
        }
        setPaymentMethodList(paymentMethodAPI?.data)

        const getAccountsAPI = await defaultCallsFinancial.getAccounts(userData[0]?.token)
        if (getAccountsAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getAccountsAPI.data)
        }
        setBanks(getAccountsAPI?.data?.map(bank => {
            return {
                ...bank,
                valueInput: bank?.accountNumber ? `${bank?.accountNumber} - ${bank?.name}` : bank?.name 
            }
        }))

        const usersAndClientsAPI = await defaultCallsFinancial.getUsersClientsAndSuppliersWithoutPagination(userData[0].token)
        if (usersAndClientsAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(usersAndClientsAPI.data) 
        }
        setUsersAndClientsList(usersAndClientsAPI.data)

        const suppliersAPI = await defaultCallsFinancial.getClientAndSupplier(userData[0].token)
        if (suppliersAPI?.error){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(suppliersAPI.data)
        }
        setSuppliersList(suppliersAPI.data?.filter(sup => sup?.isSupplier))

        setLoading(false)
    }

    useEffect(() => {
        if (!showUniversalModal){
            setDeleteFutureSends(false)
            setModalReopen(false)
        }
    }, [showUniversalModal])

    async function handleSubmit(){
        setLoading(true)
        setShowUniversalModal(false)

        const requiredFields = [
            { name: 'paymentStatus', value: [0,1,2].includes(cashFlow?.paymentStatus), required: true, type: 'number' },
        ]

        if (cashFlow?.paymentStatus == 1) {
            requiredFields.push(
                { name: 'paymentDate', value: cashFlow?.paymentDate, required: true, type: 'string' },
                { name: 'bankAccount', value: cashFlow?.financialAccounts_id, required: true, type: 'string' },
                { name: 'paymentMethod', value: cashFlow?.financialPaymentMethod_id, required: true, type: 'string' },
                { name: 'dueDate', value: cashFlow?.dueDate, required: true, type: 'string' }
            )
        }

        if (validateFields(requiredFields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        try {

            await api_financial.put(`/api/v1/financial/cashFlow/${id}`, {
                paymentDate: cashFlow?.paymentDate ?? undefined,
                dueDate: cashFlow?.dueDate,
                isDeleteFutureSends: deleteFutureSends ?? false,
                paymentStatus: cashFlow?.paymentStatus,
                financialAccounts_id: cashFlow?.financialAccounts_id ? cashFlow?.financialAccounts_id : undefined,
                financialPaymentMethod_id: cashFlow?.financialPaymentMethod_id ? cashFlow?.financialPaymentMethod_id : undefined,
                discount: cashFlow?.discount ? Number(cashFlow?.discount) : undefined,
                fees: cashFlow?.fees ? Number(cashFlow?.fees) : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Lançamento atualizado com sucesso")
            return navigate(-1)

        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleOpenSend(){
        setLoading(true)
        try {

            await api_financial.patch(`/api/v1/financial/cashFlow/reopen/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Lançamento aberto com sucesso")
            getData()

        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const paymentStatusList = [
        {
            description: 'Pendente',
            id: 0
        },
        {
            description: 'Pago',
            id: 1
        },
        {
            description: 'Cancelado',
            id: 2
        }
    ]

    const paymentConditionList = [
        { id: 1, description: 'À vista' },
        { id: 2, description: 'Parcelado' }
    ]

    return (
        <>
            <ScreenModal title={modalReopen ? 'Reabrir lançamento' : 'Editar lançamento'}>
                {
                    modalReopen ?
                    <div className='p-4 flex flex-col text-sm items-center justify-center gap-2 w-[30rem]'>
                        {
                            cashFlow?.financialConciliationsTransactions_id &&
                            <p className="flex flex-row items-center justify-center gap-1 text-center text-orange-500 font-semibold">
                                Ao reabrir um lançamento já conciliado, todos os lançamentos/títulos vinculados também serão reabertos, 
                                ficando sob sua responsabilidade qualquer ajuste ou correção necessários.
                            </p>
                        }
                        <p>Deseja realmente reabrir esse lançamento?</p>
                        <div className='flex gap-4 mt-3'>                            
                            <Button shadow={true} onClick={() => handleOpenSend()}>Sim</Button>
                            <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                    :
                    <div className='p-4 flex flex-col text-sm items-center justify-center gap-2'>
                        <a className='mt-1 text-sm'>Deseja realmente editar o lançamento?</a>
                        {
                            cashFlow?.paymentStatus == 2 ?
                            <div className="flex flex-row items-center justify-center gap-2">
                                <InputCheckBox
                                    id={'deleteFutureSends'}
                                    onClick={() => setDeleteFutureSends(!deleteFutureSends)}
                                    value={deleteFutureSends}
                                />
                                {
                                    bill?.financialBills_id ?
                                    <p className="text-sm">Deseja apagar lançamentos futuros para a compra?</p>
                                    : bill?.financialBills_id ?
                                    <p className="text-sm">Deseja apagar lançamentos futuros para a venda?</p>
                                    :
                                    <p className="text-sm">Deseja apagar lançamentos futuros?</p>
                                }
                            </div>
                            :
                            <></>
                        }
                        {
                            deleteFutureSends ?
                            <div className="flex flex-col gap-2 mt-2">
                                <p className="text-red-600 font-medium flex flex-row items-center gap-1">
                                    <FiAlertTriangle className="text-base"/>
                                    Essa ação pode impactar diretamente no fluxo de caixa dos próximos meses.
                                </p>
                            </div>
                            :
                            <></>
                        }                    
                        <div className='flex gap-4 mt-3'>
                            <Button shadow={true} onClick={() => handleSubmit()}>Sim</Button>
                            <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar Lançamento'}/>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader/>
                        :
                        <form className='flex flex-col flex-wrap gap-2 items-start justify-start w-full pb-2'>
                            <div className='flex flex-col gap-2 items-start justify-start w-full mt-2 border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                <div className="flex w-full justify-between">
                                    <h3 className='text-primaryDefaultLight text-xl'>Dados do lançamento</h3>
                                    <div className="text-sm flex gap-4 text-inputPlaceholderLight items-center">
                                        {
                                            bill?.payments_id 
                                                ? <>
                                                    <p>{bill?.payments_applicationModules_name}</p>
                                                    <p>|</p>
                                                    <p>ID do pagamento: {bill?.payments_id}</p>
                                                </>
                                                : <p>Criada por: {bill.createdByUser_name}</p>
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-wrap gap-2 items-start justify-start w-full mt-2 mb-2'>
                                    <LabelInput text={bill?.incomeOrExpense == 'R' ? 'Cliente' : 'Fornecedor'}>
                                        <Input
                                            id={'financialClientsAndSuppliers_id'}
                                            value={
                                                bill.financialClientsAndSuppliers_id ?
                                                    usersAndClientsList?.find(item => {
                                                        return item?.linked == 'financialClientsAndSuppliers' && item?.linked_id == bill.financialClientsAndSuppliers_id
                                                    })?.name
                                                : bill.client_users_id ?
                                                    usersAndClientsList?.find(item => {
                                                        return item?.linked == 'users' && item?.linked_id == bill.client_users_id}
                                                    )?.name
                                                : ''
                                            }
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data da entrada'}>
                                        <Input 
                                            id='entryDate' 
                                            type={'date'} 
                                            value={bill?.entryDate} 
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Receita/Despesa'}>
                                        <Input 
                                            id='bill' 
                                            type={'text'} 
                                            value={bill?.bill == 'R' ? 'Receita' : 'Despesa'} 
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Parcela'}>
                                        <Input 
                                            id='installment' 
                                            type={'text'} 
                                            value={bill?.paymentCondition == 2 ? `${cashFlow?.installment} de ${bill?.numberOfInstallments}` : 'À vista'}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={bill?.paymentCondition == 1 ? 'Valor' : 'Valor da parcela'}>
                                        <Input 
                                            id='description' 
                                            type={'text'} 
                                            value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cashFlow?.value)}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    {
                                        bill?.payments_id && bill?.payments_gateway_externalCode &&
                                        <LabelInput text={`Código do pagamento (gateway)`}>
                                            <Input 
                                                id='payments_gateway_externalCode' 
                                                type={'text'} 
                                                value={bill?.payments_gateway_externalCode}
                                                disabled={true}
                                            />
                                        </LabelInput>
                                    }
                                    <LabelInput text={'Código Externo'}>
                                        <Input 
                                            id='description' 
                                            type={'text'} 
                                            value={bill?.externalCode ?? '-'}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Descrição'}>
                                        <Input 
                                            id='description' 
                                            type={'text'} 
                                            value={bill?.description ?? '-'}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Origem'}>
                                        <a className={`
                                                outline-none flex items-center
                                                text-sm sm:text-sm w-96
                                                focus:shadow-borderShadow
                                                transition-all duration-200
                                                dark:text-titleGrayTextDark h-8 underline text-primaryDefaultLight cursor-pointer
                                            `} onClick={() => navigate(`/financial/bills/edit/${bill?.id}`)}>
                                            {
                                                bill?.entryType == 'shopping' ? <>Compra: {bill?.id} <FaExternalLinkAlt className="flex flex-row ml-1 text-xs"/></>
                                                : bill?.entryType == 'sales' ? <>Venda: {bill?.id} <FaExternalLinkAlt className="flex flex-row ml-1 text-xs"/></>
                                                : <>Avulso: {bill?.id} <FaExternalLinkAlt className="flex flex-row ml-1 text-xs"/></>
                                            }
                                        </a>
                                    </LabelInput>
                                </div>
                            </div>
                            <hr className='my-6 border w-full' />
                            <div className='flex flex-col gap-2 items-start justify-start w-full mt-2 border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                <h3 className='text-primaryDefaultLight text-xl'>Informações de pagamento</h3>
                                <div className='flex flex-wrap gap-2 items-start justify-start w-full mt-4 mb-2'>
                                    <LabelInput text={'Condição de pagamento'}>
                                        <Input
                                            id={'paymentCondition'}
                                            value={paymentConditionList?.find(item => item?.id == bill?.paymentCondition)?.description}
                                            text='text'
                                            disabled={true}
                                        />                                    
                                    </LabelInput>
                                    <LabelInput text={'Status do pagamento'}>
                                        <InputAutoComplete
                                            data={paymentStatusList?.filter(item => item?.id != cashFlow?.paymentStatus)}
                                            id={'paymentStatus'}
                                            optionList={['description']}
                                            selectedLabel={'description'}
                                            preSelectedValue={paymentStatusList?.find(item => item?.id == cashFlow?.paymentStatus)?.description}
                                            value={paymentStatusList?.find(item => item?.id == cashFlow?.paymentStatus)?.description}
                                            onChange={(e) => setCashFlow(prev => ({ ...prev, paymentStatus: e?.id }))}
                                            disabled={!isEdit ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Vencimento *'}>
                                        <Input 
                                            id='dueDate' 
                                            type={'date'} 
                                            value={cashFlow?.dueDate}
                                            onChange={(e) => setCashFlow(prev => ({ ...prev, dueDate: e.target?.value }))}
                                            disabled={!isEdit ? true : cashFlow?.paymentStatus == 2 ? true : false}
                                        />
                                    </LabelInput>
                                    {
                                        cashFlow?.paymentStatus == 1 ?
                                        <>
                                            <LabelInput text={'Data do pagamento: *'}>
                                                <Input
                                                    id={'paymentDate'}
                                                    type={'date'}
                                                    value={cashFlow?.paymentDate ?? ''}
                                                    onChange={(e) => setCashFlow(prev => ({ ...prev, paymentDate: e.target?.value }))}
                                                    disabled={!isEdit ? true : false}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Banco: *'}>
                                                <InputAutoComplete
                                                    data={banks}
                                                    id={'bankAccount'}
                                                    preSelectedValue={banks.find(bank => bank.id == cashFlow?.financialAccounts_id)?.valueInput}
                                                    onChange={(e) => setCashFlow(prev => ({ ...prev, financialAccounts_id: e?.id }))}
                                                    selectedLabel={'valueInput'}
                                                    optionList={['valueInput']}
                                                    disabled={!isEdit ? true : false}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Forma de pagamento: *'}>
                                                <InputAutoComplete
                                                    data={paymentMethodList}
                                                    id={'paymentMethod'}
                                                    preSelectedValue={paymentMethodList.find(method => method.id == cashFlow?.financialPaymentMethod_id)?.description}
                                                    onChange={(e) => setCashFlow(prev => ({ ...prev, financialPaymentMethod_id: e?.id }))}
                                                    selectedLabel={'description'}
                                                    optionList={['description']}
                                                    disabled={!isEdit ? true : false}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Juros: '}>
                                                <CurrencyInput
                                                    id="fees"
                                                    className={`pl-2 border border-gray-400 border-opacity-75 
                                                        outline-none
                                                        sm:w-96 w-full
                                                        text-sm sm:text-sm
                                                        transition-all duration-200
                                                        focus:shadow-borderShadow
                                                        h-10 sm:h-8
                                                        disabled:bg-gray-300 placeholder:text-black
                                                        ${!isEdit ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
                                                    `}
                                                    onChange={(e) => {
                                                        setCashFlow(prev => ({ ...prev, fees: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                    }}
                                                    disabled={!isEdit ? true : false}
                                                    placeHolder={cashFlow?.fees ? new Intl.NumberFormat('PT-br', { style: 'currency', currency: 'BRL' }).format(cashFlow?.fees): 'R$ 0,00'}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Desconto: '}>
                                                <CurrencyInput
                                                    id="discount"
                                                    className={`pl-2 border border-gray-400 border-opacity-75 
                                                        outline-none
                                                        sm:w-96 w-full
                                                        text-sm sm:text-sm
                                                        transition-all duration-200
                                                        focus:shadow-borderShadow
                                                        h-10 sm:h-8
                                                        disabled:bg-gray-300 placeholder:text-black
                                                        ${!isEdit ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
                                                    `}
                                                    onChange={(e) => {
                                                        setCashFlow(prev => ({ ...prev, discount: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                    }}
                                                    disabled={!isEdit ? true : false}
                                                    placeHolder={cashFlow?.discount ? new Intl.NumberFormat('PT-br', { style: 'currency', currency: 'BRL' }).format(cashFlow?.discount): 'R$ 0,00'}
                                                />
                                            </LabelInput>
                                            {
                                                cashFlow.paymentDate && !isEdit ?
                                                <LabelInput text={'Valor final '}>
                                                    <CurrencyInput
                                                        id="finalValue"
                                                        className={`pl-2 border border-gray-400 border-opacity-75 
                                                            outline-none
                                                            sm:w-96 w-full
                                                            text-sm sm:text-sm
                                                            transition-all duration-200
                                                            focus:shadow-borderShadow
                                                            h-10 sm:h-8
                                                            disabled:bg-gray-300 placeholder:text-black
                                                            ${!isEdit ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}
                                                        `}
                                                        disabled={true}
                                                        placeHolder={cashFlow?.finalValue ? new Intl.NumberFormat('PT-br', { style: 'currency', currency: 'BRL' }).format(cashFlow?.finalValue): 'R$ 0,00'}
                                                    />
                                                </LabelInput>
                                                :
                                                <></>
                                            }
                                        </>
                                        : 
                                        <></>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Voltar</Button>
                        <div className='flex flex-row gap-2 items-center justify-center'>
                            {
                                cashFlow?.paymentDate && !isEdit ?
                                <>
                                    {
                                     cashFlow?.financialConciliationsTransactions_id &&  
                                        <div className="flex flex-row items-center justify-center gap-1 mr-2 text-green-600">
                                            <p>Conciliado</p>
                                            <FaCheck/>
                                        </div>
                                    }
                                    <Button shadow={true} approval={true} onClick={() => {setModalReopen(true); setShowUniversalModal(true)}}>Voltar para aberto</Button>
                                </>
                                : !isEdit ?
                                <>
                                    <Button shadow={true} approval={true} onClick={() => {setIsEdit(!isEdit)}}>Editar</Button>
                                </>
                                :
                                <>
                                    <Button shadow={true} approval={false} onClick={() => {setCashFlow(cashFlowHistory); setIsEdit(false)}}>Cancelar</Button>
                                    <Button shadow={true} approval={true} onClick={() => setShowUniversalModal(true)}>Aplicar</Button>
                                </>
                            }
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
} 
