//hooks
import React, { useEffect, useState } from "react";
//components
import { useNavigate, useParams } from "react-router-dom";
import { InputCheckBox } from "../../../components/input/input.checkBox";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Button } from "../../../components/buttons/button.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
//services
import { Footer } from "../../../components/footer/Footer";
import { useApiContext } from "../../../contexts/ApiInterceptorContext";

export function UserProfileEdit() {


    const { defaultCallsAuth, api_auth, api } = useApiContext()
    const navegate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const { userData } = useAuth()
    const [apiData, setApiData] = useState([])
    const permissionGroupId = useParams()
    const [permissionsGroup, setPermissionGroup] = useState([])

    const [selectedPermissionName, setSelectedPermissionName] = useState('')
    const [selectedPermissionDescription, setSelectedPermissionDescription] = useState('')

    useEffect(() => {

        async function getData() {
            try {

                const permissionGroupData = await defaultCallsAuth.getPermissionGroupEspecific(userData[0].token, permissionGroupId.profileData)
                setPermissionGroup(permissionGroupData.data[0].permissions)
                setSelectedPermissionName(permissionGroupData.data[0]?.name)
                setSelectedPermissionDescription(permissionGroupData.data[0]?.description)

                const permissionsData = await api_auth.get('/api/v1/auth/permissions', {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setApiData(permissionsData.data.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (responseError(error).length > 0) {
                    setShowNotificationModal(true)
                    setShowNotificationModalText(responseError(error))
                } else {
                    setShowNotificationModal(true)
                    setShowNotificationModalText(responseError(error))
                }
            }
        }

        getData()

    }, [])

    function checkAll(insert, permissionType) {
        let newPermissions = []

        apiData.map((feature) => {
            if (feature.feature === permissionType) {
                feature.permissions.map((permission) => newPermissions.push(permission))
            }
        })

        if (insert) {
            newPermissions.forEach((newPermission) => {
                if (permissionsGroup.includes(newPermission.value) === false) {
                    permissionsGroup.push(newPermission.value)
                }
            })
            setPermissionGroup([...permissionsGroup])
        }
        else {
            newPermissions.forEach((newPermission) => {
                if (permissionsGroup.includes(newPermission.value) === true) {
                    const position = (permissionsGroup.indexOf(newPermission.value))
                    permissionsGroup.splice(position, 1)
                }
            })
        }
        setPermissionGroup([...permissionsGroup])
    }

    function changePermission(permission) {
        if (permissionsGroup.includes(permission)) {
            const position = (permissionsGroup.indexOf(permission))
            permissionsGroup.splice(position, 1)
            setPermissionGroup([...permissionsGroup])
        }
        else {
            setPermissionGroup([...permissionsGroup, permission])
        }
    }

    async function handleSubmit() {

        const fields = [
            { "name": 'selectedPermissionName', "value": selectedPermissionName, "required": true, "type": 'string' },
            { "name": 'selectedPermissionDescription', "value": selectedPermissionDescription, "required": true, "type": 'string' }
        ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return
        }
        if (permissionsGroup.length === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Selecione ao menos uma permissão')
        }
        setLoading(true)
        
        try {

            await api_auth.put(`/api/v1/auth/permissionGroups/${permissionGroupId.profileData}`, {
                "name": selectedPermissionName,
                "description": selectedPermissionDescription,
                "permissions": permissionsGroup
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setLoading(false)
            navegate('/usersProfile')

        } catch (error) {
            setShowNotificationModal(true)
            if (responseError(error).length > 0) {
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalText('Erro inesperado')
            }
            setLoading(false)
            setShowNotificationModalSuccess(false)
        }
    }

    async function handleDeleteGroup() {

        setLoading(true)
        try {

            const deleteGroup = await api_auth.delete(`/api/v1/auth/permissionGroups/${permissionGroupId.profileData}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro removido com sucesso!')
            navegate('/usersProfile')

        } catch (error) {
            setShowNotificationModal(true)
            if (responseError(error).length > 0) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(true)

            } else {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Erro inesperado')
                setLoading(true)
            }
            setLoading(false)
            setShowNotificationModalSuccess(false)
        }
    }

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o grupo de permissão?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => { handleDeleteGroup(); setShowUniversalModal(false) }}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={`Editar grupo`}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    <div className='flex flex-col items-center md:items-start w-full gap-6'>
                        <LabelInput required={true} text='Nome'>
                            <Input id='selectedPermissionName' value={selectedPermissionName} onChange={(e) => setSelectedPermissionName(e.target.value)} type={'text'}></Input>
                        </LabelInput>
                        <LabelInput required={true} text='Descrição'>
                            <Input id='selectedPermissionDescription' value={selectedPermissionDescription} onChange={(e) => setSelectedPermissionDescription(e.target.value)} type={'text'}></Input>
                        </LabelInput>
                        <h1 className='text-titleBlackTextLight dark:text-titleGrayTextDark'>Selecione as permissões do grupo:</h1>
                        {
                            loading ?
                                <div className='flex flex-col w-full h-full items-center justify-center'>
                                    <DefaultLoader />
                                </div>
                                :
                                <div className='flex flex-row gap-4 md:justify-start justify-center items-center md:items-start flex-wrap'>
                                    {
                                        apiData.map((typePermission) => {
                                            return <div className='flex flex-col w-80 h-80 border border-gray-300 dark:border-primaryBorderDark p-6 boxShadow rounded-lg'>
                                                <div className='flex flex-row gap-6 items-center justify-start mb-8'>
                                                    <InputCheckBox onClick={(e) => checkAll(e, typePermission.feature)} value={
                                                        typePermission.permissions?.filter((permissionApi) => {
                                                            if (permissionsGroup?.includes(permissionApi?.value)) {
                                                                return permissionApi
                                                            }
                                                        }).length === typePermission.permissions.length ?
                                                            true : false
                                                    } />
                                                    <h2 className='border-b border-primaryDefaultLight text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>{typePermission?.feature}</h2>
                                                </div>
                                                <div className=' flex flex-col gap-4 items-start justify-center'>
                                                    {
                                                        typePermission.permissions.map((permission) => {
                                                            return <div className='w-full items-start justify-start flex-row flex gap-4 ' >
                                                                <InputCheckBox
                                                                    value={permissionsGroup?.includes(`${permission.value}`) ? true : false}
                                                                    onClick={(e) => changePermission(`${permission.value}`)}></InputCheckBox>
                                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{permission?.name}</a>

                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                        }
                    </div>
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            approval={false}
                            shadow={true}
                            onClick={() => navegate('/usersProfile')}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button
                                approval={false}
                                shadow={true}
                                onClick={() => setShowUniversalModal(true)}
                                module={"permissionsGroup:delete"}
                                userData={userData}
                            >Excluir
                            </Button>
                            <Button
                                module={"permissionsGroups:update"}
                                userData={userData}
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Confirmar
                            </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>


    )

}