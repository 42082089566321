export function responseError(error) {

    // console.log(error)
    let errors = []
    if (error?.response?.data !== undefined) {
        error.response?.data?.message !== undefined ? errors.push(error.response.data.message.concat(' ')) : error?.message !== undefined ? errors.push(error?.message?.concat(' ')) : errors.push('Erro inesperado ')
        error.response?.data?.action !== undefined ? errors.push(' '.concat(error.response.data.action)) : errors.push('')
        error.response?.data?.details?.length > 0 ? error.response.data.details.forEach((error)=> {
            errors.push(' '.concat(error))
        }) : ''
        error.response.data.errorId !== undefined ? errors.push(' Id de erro: '.concat(error.response.data.errorId)) : errors.push('')
    }

    let errorMsg = ''
    errors.forEach((error) => {
        errorMsg = errorMsg.concat(error)
    })
    console.log(errorMsg)
    return errorMsg
}