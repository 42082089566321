//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { Footer } from '../../../components/footer/Footer'
import { validateFields } from '../../../utils/form.validator'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function AssociatiosEdit() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { api_auth, defaultCallsAuth } = useApiContext()
    const associationId = useParams()
    const [loading, setLoading] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [associationStatus, setAssociationsStatus] = useState(false)
    const [associationStatusHistory, setAssociationsStatusHistory] = useState(false)
    const [agreementsAccess, setAgreementsAccess] = useState(true)
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)

    const {
        setShowNotificationModal, setShowNotificationModalSuccess,
        setShowNotificationModalText, showUniversalModal,
        setShowUniversalModal
    } = useThemeContext()

    //selected variables
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isMonthlyPayer, setIsMonthlyPayer] = useState(false)
    const [monthlyPaymentType, setMonthlyPaymentType] = useState({ name: 'Porcentagem sob salário', id: 2, value: 2 },)
    const [monthlyPaymentValue, setMonthlyPaymentValue] = useState(0)
    const [paymentTypeList, setPaymentTypeList] = useState([
        { name: 'Fixo/valor', id: 1, value: 1 },
        { name: 'Porcentagem sob salário', id: 2, value: 2 },
    ])

    useEffect(() => {

        setLoading(true)
        setShowUniversalModal(false)
        async function getData() {

            const associationsGet = await defaultCallsAuth.getAssociationsById(userData[0]?.token, associationId?.id)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModal(associationsGet.data)
                return
            }
            setName(associationsGet?.data[0]?.name)
            setDescription(associationsGet?.data[0]?.description)
            setAgreementsAccess(associationsGet?.data[0]?.agreementsAccess)
            setRequireRegistrationCode(associationsGet?.data[0]?.requireRegistrationCode)
            setIsMonthlyPayer(associationsGet.data[0]?.isMonthlyPayer)
            setMonthlyPaymentType(associationsGet.data[0]?.monthlyPaymentType === 1 ? { name: 'Fixo/valor', id: 1, value: 1 } : { name: 'Porcentagem sob salário', id: 2, value: 2 })
            setMonthlyPaymentValue(associationsGet.data[0]?.monthlyPaymentValue ? associationsGet.data[0]?.monthlyPaymentValue : 0)
            //inverter com a API
            setAssociationsStatus(associationsGet?.data[0]?.active ? true : false)
            setAssociationsStatusHistory(structuredClone(associationsGet?.data[0]?.active ? true : false))
            setLoading(false)
        }

        getData()

    }, [])

    async function handleSubmit() {

        setLoading(true)

        let requiredFields = [
            { "name": 'name', "value": name, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
        ]

        try {
            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            if (associationStatus !== associationStatusHistory) {
                await api_auth.put(`/api/v1/auth/associations/${associationId.id}/active/${associationStatus ? 1 : 0}`, {

                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }

            await api_auth.put(`/api/v1/auth/associations/${associationId.id}`, {
                name,
                description,
                agreementsAccess,
                requireRegistrationCode
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Associação editada com sucesso!')
            navigate(-1)

        } catch (error) {
            console.log(error?.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {

        try {

            await api_auth.delete(`/api/v1/auth/associations/${associationId.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            navigate('/associations')
            setShowNotificationModalText('Associação removida com sucesso!')
            setLoading(false)
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleConfirmAssociation(status) {

        if (status) {
            setShowUniversalModal(true)
            setShowConfirmModal(true)
            setShowDelete(false)

        } else {
            setShowConfirmModal(false)
            setAssociationsStatus(true)
        }
    }

    function handleUpdateAssociationStatus() {

        setAssociationsStatus(false)
        setShowConfirmModal(false)
        setShowUniversalModal(false)
    }

    return (
        <>
            <ScreenModal>
                {
                    showDelete ?
                        <div className='w-full flex flex-col items-center justify-start p-6'>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente deletar a associação?</p>
                            <div className='flex flex-row gap-2 w-full items-center justify-center'>
                                <Button shadow={true} approval={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={false} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                        :
                        showConfirmModal &&
                        <div className='w-full flex flex-col items-center justify-start p-6'>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente desativar a associação?</p>
                            <p className='mb-6 text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Todos os usuários da associação não irão poder mais se logar no sistema até que seja reativada</p>
                            <div className='flex flex-row gap-2 w-full items-center justify-center'>
                                <Button shadow={true} approval={true} onClick={() => handleUpdateAssociationStatus()}>Sim</Button>
                                <Button shadow={false} approval={false} onClick={() => { setShowUniversalModal(false); setAssociationsStatus(associationStatusHistory) }}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar associação'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                <form className='flex flex-col gap-2 sm:items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome:'}>
                                        <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição:'}>
                                        <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)}></Input>
                                    </LabelInput>
                                    <Tippy content={'Mantenha ativado caso esta associação possa ver os convênios disponíveis no sistema'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <LabelInput text={'Acesso a convênios'}>
                                                <Toogle value={agreementsAccess} status={agreementsAccess} onClick={() => setAgreementsAccess(!agreementsAccess)} />
                                            </LabelInput>
                                        </div>
                                    </Tippy>
                                    <Tippy content={'Ative caso esta associação exija que no cadastro do usuário haja matrícula'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <LabelInput text={'Exige matrícula'}>
                                                <Toogle value={requireRegistrationCode} status={requireRegistrationCode} onClick={() => setRequireRegistrationCode(!requireRegistrationCode)} />
                                            </LabelInput>
                                        </div>
                                    </Tippy>
                                    <div className='items-start justify-start w-full flex flex-row gap-4 flex-wrap cursor-pointer' onClick={() => handleConfirmAssociation(associationStatus)}>
                                        <LabelInput text={'Ativo'}>
                                            <Toogle disabled={false} onClick={(e) => handleConfirmAssociation(e)} status={associationStatus}></Toogle>
                                        </LabelInput>
                                    </div>
                                </form>

                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button module={"associations:delete"} userData={userData} shadow={true} approval={false} onClick={() => { setShowDelete(true); setShowUniversalModal(true) }}>Excluir</Button>
                            <Button module={"associations:update"} userData={userData} shadow={true} approval={true} onClick={() => handleSubmit()}>Editar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}