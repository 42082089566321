import { useEffect } from "react";

export function FreeTrialExpiredModal(){

    useEffect(() => {

        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (

        <div className="modal absolute w-[100vw] h-[100vh] z-[9998] flex items-center justify-center">
        <div className="w-[85vw] h-[85vh] modal-content p-6 rounded-md bg-white relative shadow-lg z-[9999] flex items-center flex-col justify-center gap-6">
            <h2 className='font-semibold text-red-700 text-xl'>Plano teste Expirado!</h2>
            <p className='text-zinc-700'>Seu plano está <strong>expirado</strong>. Por favor, <strong>renove</strong> para continuar utilizando os serviços do Agenda rápido.</p>
            <div className='w-full h-full overflow-auto'>
                <stripe-pricing-table pricing-table-id="prctbl_1QFFkzP4Edl2IoTZyHirO4Hj"
                    publishable-key="pk_test_51Q8P9jP4Edl2IoTZemfMX7ApXqzMnLY2PXV5rwEXFaJjp6kQGj15gFxdzLqK4iJ3WRQBfrCSbbe8VpSNVWRRxpjg005LlcjpMK">
                </stripe-pricing-table>
            </div>
        </div>
        <div className='bg-zinc-700 opacity-80 absolute z-[9998] h-full w-full'>

        </div>
    </div>
    )
}