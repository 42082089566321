//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RiProfileLine } from 'react-icons/ri'
import { FaCamera, FaEye } from 'react-icons/fa'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Toogle } from '../../../components/toogle/Toogle'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
import CopyButton from '../../../components/buttons/copyButton'
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'

//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { PictureEdit } from './customer.pictureEdit'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskCep } from '../../../utils/mask/maskCep'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
//gif
import classicSchedules from '../../../assets/default_assets/classic_schedules.gif'
import modernSchedules from '../../../assets/default_assets/modern_schedules.gif'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function Customer() {

    const { api_auth, defaultCallsSchedules, defaultCallsAuth } = useApiContext()
    const [loading, setLoading] = useState(true)
    const [showToken, setShowToken] = useState(false)
    const date = Date.now()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { userData, setUserData } = useAuth()
    const [customerData, setCustomerData] = useState('')
    const [companyPicture, setCompanyPicture] = useState('')
    const [imageSrc, setImageSrc] = useState(null)
    const [picPreview, setPicturePreview] = useState('')
    const [timeZoneList, setTimeZoneList] = useState([])
    const companyTypes = [
        { name: 'CPF', id: "PF" },
        { name: 'CNPJ', id: "PJ" },
    ]
    const [selectedCompanyName, setSelectedCompanyName] = useState('')
    const [selectedCompanyEmail, setSelectedCompanyEmail] = useState('')
    const [selectedCompanyDDD, setSelectedCompanyDDD] = useState('')
    const [selectedCompanyPhone, setSelectedCompanyPhone] = useState('')
    const [selectedCompanyCnpj, setSelectedCompanyCnpj] = useState('')
    const [selectedCommunityStatus, setSelectedCommunityStatus] = useState(false)
    const [selectedCompanyRoadAddress, setSelectedCompanyRoadAddress] = useState('')
    const [requireCpf, setRequireCpf] = useState('')
    const [selectedCompanyType, setSelectedCompanyType] = useState(companyTypes[1])
    const [selectedTimeZoneDesciption, setSelectedTimeZoneDescription] = useState('')
    const [selectedUf, setSelectedUf] = useState('')
    const [selectedZipCode, setSelectedZipCode] = useState('')
    const [selectedAddress, setSelectedAddress] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedTimeZone, setSelectedTimeZone] = useState('')
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [secretKey, setSecretKey] = useState(0)
    const [selectedSchedulesViewType, setSelectedSchedulesViewType] = useState(0)
    const [showAvailableHoursWithoutLogin, setShowAvailableHoursWithoutLogin] = useState(false)
    const [useQrCodeGateControl, setUseQrCodeGateControl] = useState(false)

    const [imgSrc, setImgSrc] = useState('');

    const [ufList, setUfList] = useState()
    const [city, setCity] = useState('')

    useEffect(() => {

        setShowUniversalModal(false)
        setLoading(true)
        async function getData() {

            let img = userData[0].customersData.photo
            if (img) {
                setCompanyPicture(img)
                setImgSrc(img);
            }

            try {
                const customersData = await defaultCallsAuth.getCustomers(userData[0].token)

                const timeZones = await defaultCallsSchedules.getTimeZones(userData[0].token)
                if (customersData.error) {
                    setLoading(false)
                    setShowNotificationModalText(customersData.data)
                    return
                }

                //busca dados do ibge
                await fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
                    .then(response => response.json())
                    .then(data => {
                        setUfList(data.sort(function (a, b) {
                            return a.sigla < b.sigla ? -1 : a.sigla > b.sigla ? 1 : 0
                        }))
                    })
                    .catch(error => {
                        console.error(error);
                        setShowNotificationModal(true)
                        setShowNotificationModalSuccess(false)
                        setShowNotificationModalText('Erro ao buscar localidades do IBGE')
                        return setLoading(false)
                    });
                setCustomerData(customersData?.data)
                setSecretKey(customersData?.data.secret_key)
                setSelectedCompanyName(customersData?.data.name)
                setSelectedCompanyDDD(customersData?.data?.phoneArea)
                setSelectedCompanyPhone(customersData?.data?.phoneNumber)
                setSelectedCompanyRoadAddress(customersData?.data?.addrresRoad)
                setSelectedCompanyCnpj(customersData?.data?.cnpjcpf)
                setSelectedCommunityStatus(customersData?.data?.openToCommunity)
                setRequireCpf(customersData?.data?.requireCpf)
                setSelectedCompanyEmail(customersData?.data?.email)
                setSelectedTimeZoneDescription(customersData?.data?.timezoneDescription)
                setSelectedTimeZone(customersData?.data?.timezone)
                setSelectedAddress(customersData?.data?.address)
                setSelectedCity(customersData?.data?.city)
                setSelectedZipCode(customersData?.data?.zipCode)
                setSelectedUf({ sigla: customersData?.data?.state })
                setSelectedSchedulesViewType(customersData?.data?.schedulesViewType)
                setShowAvailableHoursWithoutLogin(customerData?.data?.showAvailableHoursWithoutLogin)
                setUseQrCodeGateControl(customersData.data?.useQrCodeGateControl)
                setTimeZoneList(timeZones.data)
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
        getData()

    }, [userData])

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const handleCommunityStatus = () => {
        setSelectedCommunityStatus(!customerData?.openToCommunity)
    }

    const handleRequireCPF = () => {
        setRequireCpf(!customerData?.requireCpf)
    }

    const handleShowAvailableHoursWithoutLogin = () => {
        setShowAvailableHoursWithoutLogin(!customerData?.showAvailableHoursWithoutLogin)
    }
    
    const handleUseQrCodeGateControl = () => {
        setUseQrCodeGateControl(!useQrCodeGateControl)
    }

    function handlePicture() {

        const input = document.getElementById('companyPicture')
        input.click()
    }

    async function showPreview(e) {
        const validImage = permittedFiles(e?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validImage?.response)
        }
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setPicturePreview(URL.createObjectURL(file))
    }

    async function handleCustomerEdit() {

        const requiredFields = [
            { name: 'selectedCompanyName', value: selectedCompanyName, required: true, type: 'string' },
            { name: 'selectedCompanyEmail', value: selectedCompanyEmail, required: true, type: 'string' },
            { name: 'selectedCompanyDDD', value: selectedCompanyDDD, required: true, type: 'string' },
            { name: 'selectedCompanyPhone', value: selectedCompanyPhone, required: true, type: 'string' },
            { name: 'selectedAddress', value: selectedAddress, required: true, type: 'string' },
            { name: 'selectedCity', value: selectedCity, required: true, type: 'string' },
            { name: 'selectedZipCode', value: selectedZipCode, required: true, type: 'string' },
            { name: 'selectedUf', value: selectedUf, required: true, type: 'object' },
        ]
        if (validateFields(requiredFields).length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos')
        }
        try {
            setLoading(true)
            const updateCustomer = await api_auth.put(`/api/v1/auth/customers`, {
                name: selectedCompanyName ? selectedCompanyName : undefined,
                cnpjcpf: selectedCompanyCnpj ? removeEspecialChar(selectedCompanyCnpj) : undefined,
                type: selectedCompanyType ? selectedCompanyType.id : undefined,
                openToCommunity: selectedCommunityStatus,
                requireCpf,
                blockColor: customerData?.blockColor,
                address: selectedAddress,
                primaryColor: customerData?.primaryColor,
                secundaryColor: customerData?.secundaryColor,
                phoneArea: selectedCompanyDDD > 0 ? selectedCompanyDDD : undefined,
                phoneNumber: !!selectedCompanyPhone ? removeEspecialChar(selectedCompanyPhone) : undefined,
                email: selectedCompanyEmail?.length > 0 ? selectedCompanyEmail : undefined,
                timezone: selectedTimeZone,
                timezoneDescription: selectedTimeZoneDesciption,
                zipCode: selectedZipCode,
                city: selectedCity,
                state: selectedUf?.sigla,
                schedulesViewType: selectedSchedulesViewType,
                showAvailableHoursWithoutLogin,
                useQrCodeGateControl
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            if (updateCustomer?.data?.status === 200) {
                setCustomerData((prev) => ({
                    ...prev,
                    openToCommunity: selectedCommunityStatus,
                    requireCpf: requireCpf
                }))
                let newUserData = userData[0]
                newUserData.customersData = { ...newUserData.customersData, requireCpf: requireCpf }
                setUserData([newUserData])
                setLoading(false)
                setShowNotificationModalSuccess(true)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Dados atualizados com sucesso!')
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setLoading(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleNewSecretKey() {

        setLoading(true)
        try {

            const newSecret = await api_auth.put(`/api/v1/auth/customers/regenerateSecretKey`, {
                actualSecretKey: secretKey
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setSecretKey(newSecret.data?.data[0]?.secret_key)
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Novo token gerado com sucesso!')
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setLoading(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='flex flex-col p-4 gap-4 items-center justify-center w-full'>
                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja gerar novo token?</a>
                    <div className='flex flex-row gap-4'>
                        <Button shadow={true} onClick={() => handleNewSecretKey()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex flex-row w-full justify-between'>
                        <Title text={'Perfil'}></Title>
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            imageSrc ?
                                <PictureEdit onChange={(e) => setImageSrc(e)} imgSrc={imageSrc} picPreview={picPreview} />
                                :
                                <div className='flex flex-col gap-8 items-start justify-start w-full'>
                                    <div className='w-full sm:h-24 flex flex-col sm:flex-row gap-4 mb-10 items-start'>
                                        <div className='flex flex-row sm:w-40 h-full'>
                                            <div onClick={() => handlePicture()} className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark flex flex-col items-center justify-center border dark:border-primaryBorderDark border-zinc-300 w-full h-hull '>
                                                {
                                                    companyPicture?.length > 0 ?
                                                        <div id={`customerPhoto`} className='p-4 cursor-pointer sm:max-h-20 sm:max-w-20 overflow-hidden text-titleBlackTextLight dark:text-titleGrayTextDark text-7xl flex flex-col items-center justify-center w-full h-hull '>
                                                            <img src={`${companyPicture}?${date}}`}></img>
                                                        </div>
                                                        :
                                                        <Tippy content={'Alterar logotipo'}
                                                            animation="shift-away"
                                                            arrow={true}
                                                            placement="bottom"
                                                            delay={100}>
                                                            <div className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark text-7xl flex flex-col items-center justify-center w-full h-hull '>
                                                                <RiProfileLine />
                                                            </div>
                                                        </Tippy>
                                                }
                                            </div>

                                        </div>
                                        <div className='flex flex-col items-start justify-start'>
                                            <h2 className='text-lg font-bold text-primaryDefaultLight'>{selectedCompanyName}</h2>
                                            <div onClick={() => handlePicture()} className='flex flex-row w-full cursor-pointer gap-2 hover:brightness-150 transition-all duration-300 hover:underline'>
                                                <FaCamera className='text-lg dark:text-titleGrayTextDark text-titleBlackTextLight' />
                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>Editar foto da empresa</a>
                                                <input accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" id='companyPicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-4 mt-1'>
                                            <h2 className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Token para integração</h2>
                                            {

                                                showToken ?
                                                    <>
                                                        <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-xt'>{secretKey}</a>
                                                        <CopyButton textToCopy={secretKey} />
                                                        <Tippy content={'Clique para gerar novo Token, lembre-se se gerar novo token as integracoes que utilziam o token antigo irão parar de funcionar até que seja informado o novo token gerado'}
                                                            animation="shift-away"
                                                            arrow={true}
                                                            placement="bottom"
                                                            delay={100}>
                                                            <a onClick={() => setShowUniversalModal(true)} className='text-sm text-primaryDefaultLight cursor-pointer'>Gerar novo Token</a>
                                                        </Tippy>
                                                    </>
                                                    :
                                                    <FaEye onClick={() => setShowToken(true)} className='cursor-pointer text-primaryDefaultLight text-xl' />
                                            }
                                        </div>

                                    </div>
                                    {
                                        !loading &&
                                        <form className='grid grid-cols-1 lg:grid-cols-2 gap-2 '>
                                            <LabelInput text={'Nome da empresa'}>
                                                <Input id='selectedCompanyName' value={selectedCompanyName} charLimit={255} onChange={(e) => setSelectedCompanyName(e.target.value)} type={'text'} inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true}></Input>
                                            </LabelInput>
                                            <LabelInput error={invalidEmail} text={invalidEmail ? 'e-mail inválido' : 'E-mail'}>
                                                <Input id="selectedCompanyEmail" charLimit={255} preSelectedValue={selectedCompanyEmail} inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true} type={'text'} value={selectedCompanyEmail} onChange={(e) => setSelectedCompanyEmail(e.target.value)} onBlur={() => { if (selectedCompanyEmail?.length > 0) { setInvalidEmail(!validateEmail(selectedCompanyEmail)) } else { setInvalidEmail(false) } }}></Input>
                                            </LabelInput>
                                            <div className='flex flex-col sm:flex-row gap-1 sm:gap-4 w-full'>
                                                <LabelInput text={'DDD'}>
                                                    <Input id="selectedCompanyDDD" preSelectedValue={selectedCompanyDDD} charLimit={2} value={selectedCompanyDDD} onChange={(e) => setSelectedCompanyDDD(justNumber(e.target.value))} inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true} type={'text'} width={'12'}></Input>
                                                </LabelInput>
                                                <LabelInput text={'Telefone'}>
                                                    <Input 
                                                        id="selectedCompanyPhone" 
                                                        preSelectedValue={selectedCompanyPhone} 
                                                        charLimit={255} 
                                                        onChange={(e) => setSelectedCompanyPhone(e.target.value)} 
                                                        value={selectedCompanyPhone ? maskPhone(selectedCompanyPhone) : ''} 
                                                        inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true} 
                                                        type={'text'} 
                                                        width={'80'}
                                                    ></Input>
                                                </LabelInput>
                                            </div>
                                            <LabelInput text={'Tipo de cadastro'}>
                                                <InputAutoComplete
                                                    preSelectedValue={companyTypes[1]?.name}
                                                    data={companyTypes}
                                                    selectedLabel={'name'}
                                                    optionList={['name']}
                                                    placeHolder=' - '
                                                    onChange={e => { setSelectedCompanyType(e); setSelectedCompanyCnpj('') }}
                                                    id='companyType'
                                                />
                                            </LabelInput>
                                            <LabelInput text={'GMT'}>
                                                <InputAutoComplete
                                                    preSelectedValue={selectedTimeZoneDesciption}
                                                    data={timeZoneList}
                                                    selectedLabel={'text'}
                                                    optionList={['text']}
                                                    placeHolder=' - '
                                                    onChange={e => { setSelectedTimeZone(e.offset); setSelectedTimeZoneDescription(e.text) }}
                                                    id='timeZone'
                                                />
                                            </LabelInput>

                                            {
                                                selectedCompanyType.id === 'PF' ?
                                                    <LabelInput text={'CPF'}>
                                                        <Input charLimit={14} onChange={(e) => setSelectedCompanyCnpj(e.target.value)} value={mCPF(selectedCompanyCnpj)} inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true} type={'text'}  ></Input>
                                                    </LabelInput>
                                                    :
                                                    <LabelInput text={'CNPJ'}>
                                                        <Input onChange={(e) => setSelectedCompanyCnpj(e.target.value)} value={cnpjMask(selectedCompanyCnpj)} inactive={userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id < 3 ? false : true} type={'text'}  ></Input>
                                                    </LabelInput>
                                            }

                                            <div>
                                                <LabelInput text={'CEP *'}>
                                                    <Input id='selectedZipCode' value={selectedZipCode ? maskCep(selectedZipCode) : ''} charLimit={8} onChange={(e) => setSelectedZipCode(justNumber(e.target.value))} />
                                                </LabelInput>
                                            </div>
                                            <div>
                                                <LabelInput text={'Logradouro *'}>
                                                    <Input id='selectedAddress' charLimit={255} value={selectedAddress} onChange={(e) => setSelectedAddress(e.target.value)} />
                                                </LabelInput>
                                            </div>
                                            <div>
                                                <LabelInput text={'Estado *'}>
                                                    <InputAutoComplete preSelectedValue={selectedUf?.sigla} id='selectedUf' data={ufList?.length > 0 ? ufList : [{ id: 0, description: 'Erro ao buscar estados' }]} optionList={['sigla']} selectedLabel={'sigla'} onChange={(e) => setSelectedUf(e)} />
                                                </LabelInput>
                                            </div>
                                            <div>
                                                <LabelInput text={'Cidade *'}>
                                                    <Input id='selectedCity' value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)} />
                                                </LabelInput>
                                            </div>

                                            <Tippy content={'Informa se sua associcão permite acesso de comunidade.'}
                                                animation="shift-away"
                                                arrow={true}
                                                placement="bottom"
                                                delay={100}>
                                                <div className='mt-4'>
                                                    <LabelInput text={'Aberto para comunidade'}>
                                                        <InputAutoComplete disabled={true} data={[{ id: 0, description: 'Não' }, { id: 1, description: 'Sim' },]} preSelectedValue={customerData?.openToCommunity ? 'Sim' : 'Não'} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                            <Tippy content={'Ative para que os clientes externos sejam obrigados a fornecer o CPF no cadastro'}
                                                animation="shift-away"
                                                arrow={true}
                                                placement="bottom"
                                                delay={100}>
                                                <div className='mt-4'>
                                                    <LabelInput text={'CPF obrigatório'}>
                                                        <Toogle onClick={handleRequireCPF} status={customerData?.requireCpf} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>

                                            <Tippy content={'Informa se seus clientes poderão ver os horários já reservados, sem necessidade de logar em sistema, para efetuar a reserva ainda será necessário logar-se'}
                                                animation="shift-away"
                                                arrow={true}
                                                placement="bottom"
                                                delay={100}>
                                                <div className='mt-4'>
                                                <LabelInput text={'Reservas públicas'}>
                                                        <Toogle onClick={handleShowAvailableHoursWithoutLogin} status={customerData?.showAvailableHoursWithoutLogin} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>

                                            <Tippy content={'Informa se será utilizado equipamentos de liberação eletrônica para entrada nas quadras, para este parâmetro funcionar será necessário cadastrar o DEVICE ID dos leitores de QRCode da marca ControlId em cada espaço que utilizará o sistema.'}
                                                animation="shift-away"
                                                arrow={true}
                                                placement="bottom"
                                                delay={100}>
                                                <div className='mt-4'>
                                                <LabelInput text={'Utiliza liberação eletrônica (QrCode)'}>
                                                        <Toogle onClick={handleUseQrCodeGateControl} status={customerData?.useQrCodeGateControl} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>

                                        </form>

                                    }

                                    <div className=' flex gap-8 flex-col'>
                                        <a className='text-xl text-primaryDefaultLight'>Layout padrão para reservas</a>
                                        <div className='p-4 bg-zinc-50 w-auto border border-zinc-100 rounded-lg dark:bg-thirdDefaultDark dark:border-secondaryBorderDark text-sm flex items-start justify-start'>
                                            <p className='text-titleBlackTextLight dark:text-titleGrayTextLight'>
                                                Selecione qual o modo deve ser exibido aos seus clientes no momento da reserva, <strong>este será o modelo padrão ao entrar na tela</strong>, porém o cliente terá a opção de alterar a visão dele
                                            </p>
                                        </div>
                                        <div className="flex flex-row w-full items-start justify-start gap-4">
                                            <div onClick={() => setSelectedSchedulesViewType(0)} className="cursor-pointer flex items-center gap-1 flex-col">
                                                <a className="text-sm text-zinc-600">Clássico</a>
                                                <InputCheckBox
                                                    onClick={() => setSelectedSchedulesViewType(0)}
                                                    value={selectedSchedulesViewType === 0 ? true : false}
                                                />
                                                <div className="shadow-lg">
                                                    <img width={500} src={classicSchedules} />
                                                </div>
                                            </div>
                                            <div onClick={() => setSelectedSchedulesViewType(1)} className="cursor-pointer  flex items-center gap-1 flex-col">
                                                <a className="text-sm text-zinc-600">Moderno</a>
                                                <InputCheckBox
                                                    onClick={() => setSelectedSchedulesViewType(1)}
                                                    value={selectedSchedulesViewType === 1 ? true : false}
                                                />
                                                <div className="shadow-lg">
                                                    <img width={500} src={modernSchedules} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/configuration')}>Voltar</Button>
                        {
                            <Button module={"customers:update"} userData={userData} shadow={true} approval={true} onClick={() => handleCustomerEdit()}>Confirmar</Button>
                        }
                    </div>
                </Footer>
            </Container >
        </>

    )
}
